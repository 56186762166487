import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase, withConnectionCheck } from '../lib/supabase';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { 
  Eye, 
  ArrowLeft, 
  Loader2, 
  Package, 
  Truck, 
  Clock, 
  CheckCircle, 
  XCircle, 
  AlertCircle,
  CreditCard,
  MapPin,
  User,
 
  FileText,
  Calendar,
  DollarSign,
  Save,

} from 'lucide-react';
import { sendOrderStatusUpdateEmail, sendOrderConfirmationEmail, EmailResponse } from '../lib/emailService';


interface OrderItem {
  product_id: string;
  quantity: number;
  price: number;
  product: {
    name: string;
    main_image: string;
    stock?: number;
  };
  dispatch_quantity?: number;
}

interface Order {
  id: string;
  created_at: string;
  total_amount: number;
  status: 'pending' | 'confirmed' | 'pending_payment' | 'paid' | 'processing' | 'shipped' | 'delivered' | 'cancelled';
  payment_status: 'pending' | 'paid' | 'failed' | 'refunded';
  payment_method: 'card' | 'cash' | 'twoCards' | 'bankTransfer';
  delivery_method: 'delivery' | 'pickup';
  shipping_address?: {
    first_name: string;
    last_name: string;
    company: string;
    nit: string;
    nit_type: 'NIT' | 'CF';
    address: string;
    address_details?: string;
    city: string;
    region: string;
    postal_code?: string;
    phone: string;
    email: string;
  };
  customer_name?: string;
  customer_email?: string;
  transfer_receipt_url?: string;
  user: {
    email: string;
    profile: {
      name: string;
      company: string;
      phone: string;
    };
  };
  items: OrderItem[];
}

const statusColors = {
  pending: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  confirmed: 'bg-blue-100 text-blue-800 border-blue-200',
  pending_payment: 'bg-orange-100 text-orange-800 border-orange-200',
  paid: 'bg-green-100 text-green-800 border-green-200',
  processing: 'bg-blue-100 text-blue-800 border-blue-200',
  shipped: 'bg-purple-100 text-purple-800 border-purple-200',
  delivered: 'bg-green-100 text-green-800 border-green-200',
  cancelled: 'bg-red-100 text-red-800 border-red-200'
};

const paymentStatusColors = {
  pending: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  paid: 'bg-green-100 text-green-800 border-green-200',
  failed: 'bg-red-100 text-red-800 border-red-200',
  refunded: 'bg-gray-100 text-gray-800 border-gray-200'
};

const statusLabels = {
  pending: 'Pendiente Revisión',
  confirmed: 'Confirmado',
  pending_payment: 'Pendiente Pago',
  paid: 'Pagado',
  processing: 'En proceso',
  shipped: 'Enviado',
  delivered: 'Entregado',
  cancelled: 'Cancelado'
};

const paymentStatusLabels = {
  pending: 'Pendiente',
  paid: 'Pagado',
  failed: 'Fallido',
  refunded: 'Reembolsado'
};

const statusIcons = {
  pending: Clock,
  confirmed: CheckCircle,
  pending_payment: AlertCircle,
  paid: CheckCircle,
  processing: Package,
  shipped: Truck,
  delivered: CheckCircle,
  cancelled: XCircle
};

interface ViewOrderProps {
  isClientView?: boolean;
}

export default function ViewOrder({ isClientView = false }: ViewOrderProps) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showFullImage, setShowFullImage] = useState(false);
  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    fetchOrder();
  }, [id]);

  useEffect(() => {
    if (order) {
      setOrderItems(order.items.map(item => ({
        ...item,
        dispatch_quantity: item.quantity
      })));
    }
  }, [order]);

  const handleDispatchQuantityChange = (productId: string, quantity: number) => {
    setOrderItems(prevItems => 
      prevItems.map(item => 
        item.product_id === productId 
          ? { ...item, dispatch_quantity: Math.min(quantity, item.product.stock || 0) }
          : item
      )
    );
  };

  const calculateDispatchTotal = () => {
    return orderItems.reduce((total, item) => {
      const dispatchQty = item.dispatch_quantity || 0;
      return total + (dispatchQty * item.price);
    }, 0);
  };

  const handleOrderConfirmation = async () => {
    try {
      setIsConfirming(true);
      
      // Verificar que todas las cantidades sean válidas
      const allQuantitiesValid = orderItems.every(item => {
        const dispatchQty = item.dispatch_quantity || 0;
        return dispatchQty > 0 && dispatchQty <= (item.product.stock || 0);
      });
      
      if (!allQuantitiesValid) {
        alert('Por favor verifica las cantidades a despachar');
        return;
      }

      console.log('Verificando conexión antes de despachar el pedido...');
      
      // Usar withConnectionCheck para verificar la conexión
      const result = await withConnectionCheck(async () => {
        const totalAmount = calculateDispatchTotal();
        
        // Actualizar el estado del pedido a "pending_payment" y el total
        const { error: updateError } = await supabase
          .from('orders')
          .update({
            status: 'pending_payment' as Order['status'],
            total_amount: totalAmount
          })
          .eq('id', id);

        if (updateError) {
          console.error('Error al actualizar el pedido:', updateError);
          throw new Error(`Error al actualizar el pedido: ${updateError.message}`);
        }

        console.log('Pedido actualizado correctamente');
        console.log('Actualizando items del pedido...');

        // Primero eliminar los items existentes para evitar duplicados
        const { error: deleteError } = await supabase
          .from('order_items')
          .delete()
          .eq('order_id', id);
          
        if (deleteError) {
          console.error('Error al eliminar items existentes:', deleteError);
          throw new Error(`Error al eliminar items existentes: ${deleteError.message}`);
        }

        // Preparar los datos de los items para actualizar
        const itemsToUpdate = orderItems.map(item => ({
          order_id: id,
          product_id: item.product_id,
          quantity: item.dispatch_quantity || 0,
          price: item.price
        }));

        console.log('Items a actualizar:', itemsToUpdate);

        // Insertar los nuevos items uno por uno
        for (const item of itemsToUpdate) {
          console.log('Insertando item:', item);
          const { error } = await supabase
            .from('order_items')
            .insert(item)
            .select();

          if (error) {
            console.error('Error al insertar item:', error, item);
            throw new Error(`Error al insertar item: ${error.message}`);
          }
        }

        console.log('Items actualizados correctamente');
        
        return totalAmount;
      }, () => {
        // Esta función se ejecuta cuando no hay conexión
        alert('No hay conexión a internet. Verifica tu conexión y vuelve a intentarlo.');
        return undefined;
      });
      
      if (result === undefined) {
        // Si no hay conexión, terminamos aquí
        return;
      }
      
      const totalAmount = result;
      
      // Actualizar la orden en la UI
      await fetchOrder();
      
      // Enviar correo de confirmación al cliente
      if (order) {
        const orderItemsForEmail = orderItems.map(item => ({
          name: item.product.name,
          quantity: item.dispatch_quantity || 0,
          price: item.price
        }));
        
        const formattedDate = format(new Date(order.created_at), "d 'de' MMMM, yyyy", { locale: es });
        
        // Verificar que el email sea válido
        if (!order.user.email || order.user.email === 'No disponible') {
          console.warn('No se encontró una dirección de correo válida para enviar la confirmación');
          alert('No se pudo enviar el correo de confirmación porque no se encontró una dirección de correo válida.');
        } else {
          console.log('Intentando enviar correo a:', order.user.email);
          
          try {
            // Utiliza withConnectionCheck para verificar la conexión antes de enviar el correo
            const emailResult = await withConnectionCheck<EmailResponse>(async () => {
              return await sendOrderConfirmationEmail({
                orderId: order.id,
                customerName: order.shipping_address?.first_name || order.user.profile.name,
                customerEmail: order.user.email,
                totalAmount,
                orderDate: formattedDate,
                items: orderItemsForEmail,
                deliveryMethod: order.delivery_method,
                ...(order.delivery_method === 'delivery' && order.shipping_address && {
                  shippingAddress: {
                    address: order.shipping_address.address,
                    city: order.shipping_address.city,
                    region: order.shipping_address.region
                  }
                })
              });
            }, () => {
              // Función que se ejecuta cuando no hay conexión
              console.error('No hay conexión a internet para enviar el correo');
              alert('No se pudo enviar el correo de confirmación porque no hay conexión a internet.');
            });
            
            // Verificar si emailResult está definido
            if (emailResult) {
              if (!emailResult.success && emailResult.error) {
                console.error('Error al enviar el correo de confirmación:', emailResult.error);
                
                // En modo desarrollo, no bloquear el flujo por errores de correo
                if (import.meta.env.DEV) {
                  console.warn('Continuando a pesar del error de correo (modo desarrollo)');
                } else {
                  // En producción, mostrar mensaje informativo pero continuar con el flujo
                  console.warn('Ocurrió un error al enviar el correo, pero el pedido se procesó correctamente');
                  alert('El pedido se procesó correctamente, pero hubo un problema al enviar el correo de confirmación. El cliente recibirá el correo más tarde cuando el sistema lo reintente automáticamente.');
                }
              } else if (emailResult.success) {
                console.log('Correo de confirmación enviado correctamente');
              }
            } else {
              console.warn('No se pudo verificar la conexión o hubo un error en la verificación');
            }
          } catch (emailSendError) {
            console.error('Error al intentar enviar el correo:', emailSendError);
            
            // En modo desarrollo, no bloquear el flujo por errores de correo
            if (import.meta.env.DEV) {
              console.warn('Continuando a pesar del error de correo (modo desarrollo)');
            } else {
              // En producción, mostrar mensaje informativo pero continuar con el flujo
              console.warn('Ocurrió un error al enviar el correo, pero el pedido se procesó correctamente');
              alert('El pedido se procesó correctamente, pero hubo un problema al enviar el correo de confirmación. El cliente recibirá el correo más tarde cuando el sistema lo reintente automáticamente.');
            }
          }
        }
      }
      
      alert('Pedido despachado exitosamente. El cliente ahora puede proceder al pago.');
    } catch (err) {
      console.error('Error completo al despachar el pedido:', err);
      
      if (err instanceof Error) {
        setError(`Error al despachar el pedido: ${err.message}`);
        alert(`Error al despachar el pedido: ${err.message}`);
      } else {
        setError('Error desconocido al despachar el pedido');
        alert('Error desconocido al despachar el pedido. Verifica tu conexión a internet.');
      }
    } finally {
      setIsConfirming(false);
    }
  };

  const fetchOrder = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      console.log('Obteniendo pedido con ID:', id);
      
      // Consulta básica para obtener el pedido
      const { data: ordersData, error: ordersError } = await supabase
        .from('orders')
        .select(`
          *,
          items:order_items (
            *,
            product:products (*)
          )
        `)
        .eq('id', id)
        .single();

      if (ordersError) {
        console.error('Error al obtener pedido:', ordersError);
        throw ordersError;
      }

      if (!ordersData) {
        throw new Error('No se encontró el pedido');
      }

      // Obtener el perfil del usuario del pedido
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', ordersData.user_id)
        .single();

      if (profileError) {
        console.error('Error al obtener perfil:', profileError);
        throw profileError;
      }
      
      // Determinar el email a utilizar
      let userEmail = '';
      
      // Si el pedido tiene un campo email, usarlo directamente
      if (ordersData.email) {
        userEmail = ordersData.email;
        console.log('Usando email almacenado en la orden:', userEmail);
      }
      // Si el pedido pertenece al usuario actual, usar su email
      else if (ordersData.user_id === user.id) {
        userEmail = user.email || '';
        console.log('Usando email del usuario actual:', userEmail);
      } else {
        // 1. Intentar obtener email directamente de auth.users con el mismo ID
        const { data, error } = await supabase
          .from('auth.users')
          .select('email')
          .eq('id', ordersData.user_id)
          .single();
          
        if (!error && data && data.email) {
          userEmail = data.email;
          console.log('Email obtenido directamente de auth.users:', userEmail);
        } else {
          console.log('No se pudo obtener email directamente de auth.users, intentando con admin API');
          
          // 2. Intentar con admin API como respaldo
          try {
            const { data: authUser } = await supabase.auth.admin.getUserById(ordersData.user_id);
            if (authUser?.user?.email) {
              userEmail = authUser.user.email;
              console.log('Email obtenido mediante admin API:', userEmail);
            }
          } catch (adminError) {
            console.error('No se pudo obtener el email mediante admin API:', adminError);
          }
          
          // 3. Si aún no tenemos email, buscar en shipping_address como respaldo
          if (!userEmail && ordersData.shipping_address) {
            const shippingAddress = typeof ordersData.shipping_address === 'string'
              ? JSON.parse(ordersData.shipping_address)
              : ordersData.shipping_address;
              
            if (shippingAddress.email) {
              userEmail = shippingAddress.email;
              console.log('Email obtenido del shipping_address:', userEmail);
            }
          }
        }
      }
      
      // Si sigue sin haber email, usar valor por defecto
      if (!userEmail) {
        userEmail = 'No disponible';
        console.warn('No se pudo obtener un email válido para este usuario');
      }

      console.log('Email final del cliente para este pedido:', userEmail);

      // Transformar los datos
      const transformedOrder: Order = {
        id: ordersData.id,
        created_at: ordersData.created_at,
        total_amount: ordersData.total_amount,
        status: ordersData.status,
        payment_status: ordersData.payment_status,
        payment_method: ordersData.payment_method || 'card',
        delivery_method: ordersData.delivery_method || 'delivery',
        shipping_address: typeof ordersData.shipping_address === 'string' 
          ? JSON.parse(ordersData.shipping_address)
          : ordersData.shipping_address,
        transfer_receipt_url: ordersData.transfer_receipt_url,
        user: {
          email: userEmail,
          profile: {
            name: profileData.name || 'No disponible',
            company: profileData.company || '',
            phone: profileData.phone || ''
          }
        },
        items: ordersData.items.map((item: any) => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          product: {
            name: item.product?.name || 'Producto no disponible',
            main_image: item.product?.main_image || '',
            stock: item.product?.stock || 0
          }
        }))
      };

      setOrder(transformedOrder);
    } catch (err) {
      console.error('Error al cargar el pedido:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar el pedido');
    } finally {
      setLoading(false);
    }
  };

  const updateOrderStatus = async (status: Order['status']) => {
    try {
      setIsUpdating(true);
      
      // Usar withConnectionCheck para verificar la conexión
      const success = await withConnectionCheck(async () => {
        const { error } = await supabase
          .from('orders')
          .update({ status })
          .eq('id', id);

        if (error) {
          throw error;
        }
        
        await fetchOrder();
        return true;
      }, () => {
        // Esta función se ejecuta cuando no hay conexión
        alert('No hay conexión a internet. Verifica tu conexión y vuelve a intentarlo.');
        return false;
      });
      
      if (!success) {
        // Si no hay conexión o hubo un error, terminamos aquí
        setIsUpdating(false);
        return;
      }
      
      // Si el email está disponible, enviar notificación de actualización
      if (order?.user.email && order.user.email !== 'No disponible') {
        try {
          console.log('Enviando correo de actualización de estado...');
          
          // Obtener mensajes según el status
          const statusMessages: Record<Order['status'], string> = {
            pending: 'Tu pedido ha sido registrado y está pendiente de confirmación.',
            confirmed: 'Tu pedido ha sido confirmado y está siendo preparado.',
            pending_payment: 'Tu pedido está pendiente de pago.',
            paid: 'Tu pago ha sido recibido correctamente.',
            processing: 'Tu pedido está siendo procesado.',
            shipped: 'Tu pedido ha sido enviado.',
            delivered: 'Tu pedido ha sido entregado.',
            cancelled: 'Tu pedido ha sido cancelado.'
          };
          
          const statusMessage = statusMessages[status] || `Tu pedido ha sido actualizado al estado: ${status}`;
          
          // Verificar la conexión antes de enviar el correo
          const emailResult = await withConnectionCheck<EmailResponse>(async () => {
            // Enviar correo de notificación
            return await sendOrderStatusUpdateEmail({
              orderId: id || '',
              customerName: order.shipping_address?.first_name || order.user.profile.name || 'Cliente',
              customerEmail: order.user.email || '',
              newStatus: status,
              statusMessage: statusMessage,
            });
          }, () => {
            // Función que se ejecuta cuando no hay conexión
            console.error('No hay conexión a internet para enviar el correo de actualización');
            alert('No se pudo enviar la notificación por correo porque no hay conexión a internet.');
          });
          
          // Verificar si emailResult está definido
          if (emailResult) {
            if (!emailResult.success) {
              console.error('Error al enviar correo:', emailResult.error);
              alert(`Error al enviar correo de notificación: ${emailResult.error}`);
            } else {
              alert('Estado del pedido actualizado y notificación enviada');
            }
          } else {
            console.warn('No se pudo verificar la conexión o hubo un error en la verificación');
            alert('Estado del pedido actualizado, pero no se pudo enviar la notificación');
          }
        } catch (error) {
          console.error('Error al enviar correo de notificación:', error);
          alert('Error al enviar correo de notificación');
        }
      } else {
        // No hay email disponible
        alert('Estado del pedido actualizado. No se envió notificación por correo porque no hay dirección de email registrada.');
      }
      
      setIsUpdating(false);
    } catch (error) {
      console.error('Error al actualizar estado del pedido:', error);
      alert('Error al actualizar estado');
      setIsUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="flex flex-col items-center">
          <Loader2 className="w-12 h-12 text-blue-600 animate-spin" />
          <p className="mt-4 text-gray-600">Cargando detalles del pedido...</p>
        </div>
      </div>
    );
  }

  if (error || !order) {
    return (
      <div className="p-6">
        <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
          <p className="text-red-700 font-medium">Error</p>
          <p className="text-red-600">{error || 'No se encontró el pedido'}</p>
          <button
            onClick={() => navigate(-1)}
            className="mt-4 text-red-600 hover:text-red-800 font-medium flex items-center gap-2"
          >
            <ArrowLeft className="w-4 h-4" />
            Volver
          </button>
        </div>
      </div>
    );
  }

  const StatusIcon = statusIcons[order.status];

  return (
    <div className="p-6">
      {/* Imagen a pantalla completa */}
      {showFullImage && order.transfer_receipt_url && (
        <div 
          className="fixed inset-0 bg-black/80 z-50 flex items-center justify-center p-4"
          onClick={() => setShowFullImage(false)}
        >
          <div className="relative max-w-4xl w-full">
            <button 
              className="absolute top-4 right-4 bg-white/20 hover:bg-white/40 rounded-full p-2 text-white"
              onClick={() => setShowFullImage(false)}
            >
              <XCircle className="w-6 h-6" />
            </button>
            <img 
              src={order.transfer_receipt_url} 
              alt="Comprobante de transferencia" 
              className="w-full h-auto max-h-[90vh] object-contain rounded-lg"
            />
          </div>
        </div>
      )}

      {/* Encabezado con botón de volver */}
      <div className="flex items-center gap-4 mb-6">
        <button
          onClick={() => navigate(-1)}
          className="text-gray-600 hover:text-gray-800 font-medium flex items-center gap-2"
        >
          <ArrowLeft className="w-5 h-5" />
          Volver a pedidos
        </button>
        <h1 className="text-xl font-semibold text-gray-900">
          Detalles del Pedido
        </h1>
      </div>

      {/* Encabezado del pedido */}
      <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-6">
        <div className="p-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4 mb-4">
            <div>
              <div className="flex items-center gap-3">
                <h2 className="text-2xl font-bold text-gray-900">
                  Pedido #{order.id.slice(0, 8)}
                </h2>
                <div className={`flex items-center gap-1.5 px-3 py-1 rounded-full text-sm font-medium border ${statusColors[order.status]}`}>
                  <StatusIcon className="w-4 h-4" />
                  {statusLabels[order.status]}
                </div>
              </div>
              <div className="flex flex-wrap gap-x-6 gap-y-2 mt-2 text-sm text-gray-500">
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-1.5" />
                  {format(new Date(order.created_at), "d 'de' MMMM, yyyy - HH:mm", { locale: es })}
                </div>
                <div className="flex items-center">
                  <DollarSign className="w-4 h-4 mr-1.5" />
                  Total: Q{order.total_amount.toFixed(2)}
                </div>
              </div>
            </div>
            {!isClientView && (
              <div className="flex items-center gap-3">
                {order.status !== 'pending' ? (
                  <select
                    value={order.status}
                    onChange={(e) => updateOrderStatus(e.target.value as Order['status'])}
                    disabled={isUpdating}
                    className="border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm"
                  >
                    {Object.entries(statusLabels).map(([value, label]) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </select>
                ) : (
                  <button
                    onClick={handleOrderConfirmation}
                    disabled={isConfirming}
                    className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center gap-2 text-sm font-medium transition-colors disabled:opacity-50"
                  >
                    {isConfirming ? (
                      <>
                        <Loader2 className="w-4 h-4 animate-spin" />
                        Procesando...
                      </>
                    ) : (
                      <>
                        <Save className="w-4 h-4" />
                        Confirmar Despacho
                      </>
                    )}
                  </button>
                )}
                {isUpdating && (
                  <Loader2 className="w-5 h-5 text-blue-600 animate-spin" />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Columna izquierda: Información del cliente y envío */}
        <div className="lg:col-span-1 space-y-6">
          {/* Información del cliente */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="border-b border-gray-100 px-6 py-4">
              <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                <User className="w-5 h-5 text-gray-500" />
                Información del Cliente
              </h3>
            </div>
            <div className="p-6 space-y-4">
              <div>
                <p className="text-sm text-gray-500">Nombre</p>
                <p className="font-medium">{order.user.profile.name}</p>
              </div>
              {order.user.profile.company && (
                <div>
                  <p className="text-sm text-gray-500">Empresa</p>
                  <p className="font-medium">{order.user.profile.company}</p>
                </div>
              )}
              <div>
                <p className="text-sm text-gray-500">Email</p>
                <p className="font-medium">{order.user.email}</p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Teléfono</p>
                <p className="font-medium">{order.user.profile.phone || 'No especificado'}</p>
              </div>
            </div>
          </div>

          {/* Información de envío */}
          {order.shipping_address && (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="border-b border-gray-100 px-6 py-4">
                <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                  <MapPin className="w-5 h-5 text-gray-500" />
                  Información de Envío
                </h3>
              </div>
              <div className="p-6 space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Contacto</p>
                    <p className="font-medium">{order.shipping_address.first_name || 'No especificado'}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Empresa</p>
                    <p className="font-medium">{order.shipping_address.company || 'No especificada'}</p>
                  </div>
                </div>
                
                <div>
                  <p className="text-sm text-gray-500">NIT ({order.shipping_address.nit_type})</p>
                  <p className="font-medium">{order.shipping_address.nit || 'No especificado'}</p>
                </div>

                <div>
                  <p className="text-sm text-gray-500">Dirección</p>
                  <p className="font-medium">{order.shipping_address.address || 'No especificada'}</p>
                  {order.shipping_address.address_details && (
                    <p className="text-sm text-gray-500">{order.shipping_address.address_details}</p>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Ciudad</p>
                    <p className="font-medium">{order.shipping_address.city || 'No especificada'}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Departamento</p>
                    <p className="font-medium">{order.shipping_address.region || 'No especificado'}</p>
                  </div>
                </div>

                {order.shipping_address.postal_code && (
                  <div>
                    <p className="text-sm text-gray-500">Código Postal</p>
                    <p className="font-medium">{order.shipping_address.postal_code}</p>
                  </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500">Teléfono</p>
                    <p className="font-medium">{order.shipping_address.phone || 'No especificado'}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Email</p>
                    <p className="font-medium">{order.shipping_address.email || 'No especificado'}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Información de pago */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="border-b border-gray-100 px-6 py-4">
              <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                <CreditCard className="w-5 h-5 text-gray-500" />
                Información de Pago
              </h3>
            </div>
            <div className="p-6 space-y-4">
              <div>
                <p className="text-sm text-gray-500">Método de Pago</p>
                <p className="font-medium">
                  {order.payment_method === 'card' && 'Tarjeta de Crédito/Débito'}
                  {order.payment_method === 'bankTransfer' && 'Transferencia Bancaria'}
                  {order.payment_method === 'cash' && 'Efectivo'}
                  {order.payment_method === 'twoCards' && 'Dos Tarjetas'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">Estado del Pago</p>
                <div className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium border ${paymentStatusColors[order.payment_status]}`}>
                  {paymentStatusLabels[order.payment_status]}
                </div>
              </div>
            </div>
          </div>

          {/* Comprobante de transferencia */}
          {order.payment_method === 'bankTransfer' && order.transfer_receipt_url && (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="border-b border-gray-100 px-6 py-4">
                <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                  <FileText className="w-5 h-5 text-gray-500" />
                  Comprobante de Transferencia
                </h3>
              </div>
              <div className="p-6">
                <div className="relative bg-white border rounded-lg overflow-hidden">
                  <img 
                    src={order.transfer_receipt_url} 
                    alt="Comprobante de transferencia"
                    className="w-full h-auto max-h-[300px] object-contain"
                    onError={(e) => {
                      console.error('Error al cargar la imagen:', e);
                      e.currentTarget.src = '/placeholder-image.jpg';
                      e.currentTarget.classList.add('opacity-50');
                    }}
                  />
                  <button 
                    onClick={() => setShowFullImage(true)}
                    className="absolute bottom-4 right-4 bg-white/90 hover:bg-white px-4 py-2 rounded-lg text-sm text-blue-600 hover:text-blue-800 transition-colors shadow-sm flex items-center gap-2"
                  >
                    <Eye className="w-4 h-4" />
                    Ver imagen completa
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Columna derecha: Productos y resumen */}
        <div className="lg:col-span-2">
          <div className="bg-white rounded-xl shadow-sm overflow-hidden">
            <div className="border-b border-gray-100 px-6 py-4">
              <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                <Package className="w-5 h-5 text-gray-500" />
                Productos ({order.items.length})
              </h3>
            </div>
            <div className="p-6">
              <div className="space-y-6">
                {orderItems.map((item) => (
                  <div key={item.product_id} className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg">
                    <div className="w-20 h-20 flex-shrink-0">
                      <img
                        src={item.product.main_image}
                        alt={item.product.name}
                        className="w-full h-full object-cover rounded-md border"
                        onError={(e) => e.currentTarget.src = '/placeholder-image.jpg'}
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <h4 className="font-medium text-gray-900">{item.product.name}</h4>
                      <div className="flex flex-wrap gap-x-4 gap-y-1 mt-2 text-sm">
                        <p className="text-gray-600">
                          Solicitado: <span className="font-medium">{item.quantity}</span>
                        </p>
                        {order.status === 'pending' && !isClientView ? (
                          <div className="flex items-center gap-2">
                            <label className="text-gray-600">Despachar:</label>
                            <input
                              type="number"
                              min="0"
                              max={item.product.stock || item.quantity}
                              value={item.dispatch_quantity || 0}
                              onChange={(e) => handleDispatchQuantityChange(item.product_id, parseInt(e.target.value) || 0)}
                              className="w-20 px-2 py-1 border rounded focus:ring-2 focus:ring-blue-500 text-center"
                            />
                          </div>
                        ) : (
                          <p className="text-gray-600">
                            Despachado: <span className="font-medium">{item.quantity}</span>
                          </p>
                        )}
                        {!isClientView && (
                          <div className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${
                            (item.product.stock || 0) >= item.quantity
                              ? 'bg-green-100 text-green-800 border border-green-200'
                              : 'bg-red-100 text-red-800 border border-red-200'
                          }`}>
                            Stock: {item.product.stock || 0}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-right">
                      <p className="font-medium text-gray-900">
                        Q{(item.quantity * item.price).toFixed(2)}
                      </p>
                      {order.status === 'pending' && !isClientView && (
                        <p className="text-sm text-gray-500 mt-1">
                          Despacho: Q{((item.dispatch_quantity || 0) * item.price).toFixed(2)}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-8 border-t pt-6">
                <div className="space-y-2">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Subtotal</span>
                    <span className="font-medium">Q{order.total_amount.toFixed(2)}</span>
                  </div>
                  {order.status === 'pending' && !isClientView && (
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-600">Subtotal a despachar</span>
                      <span className="font-medium">Q{calculateDispatchTotal().toFixed(2)}</span>
                    </div>
                  )}
                  {order.shipping_address && (
                    <div className="flex justify-between text-sm">
                      <span className="text-gray-600">Envío</span>
                      <span className="font-medium">Q0.00</span>
                    </div>
                  )}
                  <div className="flex justify-between text-lg font-bold pt-2 border-t">
                    <span>Total</span>
                    <span>
                      {order.status === 'pending' && !isClientView
                        ? `Q${calculateDispatchTotal().toFixed(2)}`
                        : `Q${order.total_amount.toFixed(2)}`
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Historial de estados (opcional) */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden mt-6">
            <div className="border-b border-gray-100 px-6 py-4">
              <h3 className="font-semibold text-gray-900 flex items-center gap-2">
                <Clock className="w-5 h-5 text-gray-500" />
                Historial del Pedido
              </h3>
            </div>
            <div className="p-6">
              <div className="relative">
                <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200"></div>
                <div className="space-y-6">
                  <div className="relative flex items-start gap-4">
                    <div className="absolute left-0 top-0 flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 border-2 border-white z-10">
                      <Package className="w-4 h-4 text-blue-600" />
                    </div>
                    <div className="ml-12">
                      <h4 className="font-medium text-gray-900">Pedido creado</h4>
                      <p className="text-sm text-gray-500">
                        {format(new Date(order.created_at), "d 'de' MMMM, yyyy - HH:mm", { locale: es })}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start gap-4">
                    <div className="absolute left-0 top-0 flex items-center justify-center w-8 h-8 rounded-full bg-green-100 border-2 border-white z-10">
                      <CreditCard className="w-4 h-4 text-green-600" />
                    </div>
                    <div className="ml-12">
                      <h4 className="font-medium text-gray-900">Estado de pago: {paymentStatusLabels[order.payment_status]}</h4>
                      <p className="text-sm text-gray-500">
                        Método: {order.payment_method === 'card' ? 'Tarjeta' : 
                                order.payment_method === 'bankTransfer' ? 'Transferencia Bancaria' : 
                                order.payment_method === 'cash' ? 'Efectivo' : 'Dos Tarjetas'}
                      </p>
                    </div>
                  </div>
                  <div className="relative flex items-start gap-4">
                    <div className="absolute left-0 top-0 flex items-center justify-center w-8 h-8 rounded-full bg-purple-100 border-2 border-white z-10">
                      <StatusIcon className="w-4 h-4 text-purple-600" />
                    </div>
                    <div className="ml-12">
                      <h4 className="font-medium text-gray-900">Estado actual: {statusLabels[order.status]}</h4>
                      <p className="text-sm text-gray-500">
                        Última actualización
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 