import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Facebook, Phone, Mail, MapPin, ExternalLink } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export default function About() {
  return (
    <div className="bg-white min-h-screen">
      <Helmet>
        <title>Contáctanos | Disolgua</title>
        <meta name="description" content="Comunícate con Distribuidora del Sol Guatemala, tu distribuidor de confianza. Encuentra nuestras redes sociales, dirección, teléfonos y más." />
      </Helmet>

      {/* Banner Principal */}
      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-10">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold mb-2 text-center">Contáctanos</h1>
          <p className="text-lg text-center max-w-3xl mx-auto">
            Estamos aquí para ayudarte. Comunícate con nosotros.
          </p>
        </div>
      </div>

      {/* Contenido Principal Combinado */}
      <div className="py-8">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex flex-col lg:flex-row gap-8">
            {/* Columna 1: Contacto + Redes Sociales */}
            <div className="w-full lg:w-1/2 space-y-6">
              {/* Redes Sociales */}
              <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Síguenos</h2>
                <div className="flex justify-center gap-6 mt-4">
                  <a 
                    href="https://www.facebook.com/disolgua" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex flex-col items-center gap-2 group"
                  >
                    <div className="bg-blue-100 p-4 rounded-full text-blue-600 transform transition-all duration-300 group-hover:scale-110 group-hover:bg-blue-600 group-hover:text-white">
                      <Facebook className="w-8 h-8" />
                    </div>
                    <span className="font-medium text-gray-800">Facebook</span>
                  </a>
                  
                  <a 
                    href="https://wa.me/50247391242" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex flex-col items-center gap-2 group"
                  >
                    <div className="bg-green-100 p-4 rounded-full text-green-600 transform transition-all duration-300 group-hover:scale-110 group-hover:bg-green-600 group-hover:text-white">
                      <FontAwesomeIcon icon={faWhatsapp} className="w-8 h-8" />
                    </div>
                    <span className="font-medium text-gray-800">WhatsApp</span>
                  </a>
                </div>
              </div>

              {/* Tarjetas de Contacto */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100 transition-all duration-300 hover:shadow-md hover:border-blue-200">
                  <div className="flex items-center gap-3">
                    <div className="bg-blue-50 p-3 rounded-full flex-shrink-0">
                      <Phone className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold text-gray-900">Teléfono</h3>
                      <p className="text-gray-700">(502) 2238-0714</p>
                      <a 
                        href="tel:+5022238-0714" 
                        className="inline-flex items-center text-blue-600 mt-1 text-sm font-medium hover:underline"
                      >
                        Llamar ahora
                        <ExternalLink className="w-3 h-3 ml-1" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100 transition-all duration-300 hover:shadow-md hover:border-green-200">
                  <div className="flex items-center gap-3">
                    <div className="bg-green-50 p-3 rounded-full flex-shrink-0">
                      <FontAwesomeIcon icon={faWhatsapp} className="w-6 h-6 text-green-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold text-gray-900">WhatsApp</h3>
                      <p className="text-gray-700">(502) 4739-1242</p>
                      <a 
                        href="https://wa.me/50247391242" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-green-600 mt-1 text-sm font-medium hover:underline"
                      >
                        Enviar mensaje
                        <ExternalLink className="w-3 h-3 ml-1" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100 transition-all duration-300 hover:shadow-md hover:border-blue-200">
                  <div className="flex items-center gap-3">
                    <div className="bg-blue-50 p-3 rounded-full flex-shrink-0">
                      <Mail className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold text-gray-900">Correo</h3>
                      <p className="text-gray-700">info@disolgua.com</p>
                      <a 
                        href="mailto:info@disolgua.com" 
                        className="inline-flex items-center text-blue-600 mt-1 text-sm font-medium hover:underline"
                      >
                        Enviar correo
                        <ExternalLink className="w-3 h-3 ml-1" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100 transition-all duration-300 hover:shadow-md hover:border-blue-200">
                  <div className="flex items-center gap-3">
                    <div className="bg-blue-50 p-3 rounded-full flex-shrink-0">
                      <MapPin className="w-6 h-6 text-blue-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-bold text-gray-900">Dirección</h3>
                      <p className="text-gray-700 text-sm">19 calle 0-47, Zona 1, Guatemala</p>
                      <a 
                        href="https://maps.app.goo.gl/YW1V3WQSPTzb8vxJ8" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-blue-600 mt-1 text-sm font-medium hover:underline"
                      >
                        Ver en Google Maps
                        <ExternalLink className="w-3 h-3 ml-1" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* CTA Botones */}
              <div className="bg-blue-600 rounded-xl p-6 text-white">
                <h2 className="text-xl font-bold mb-3">¿Listo para comenzar?</h2>
                <p className="text-sm mb-4">
                  Somos tu distribuidor de confianza en Guatemala con los mejores precios del mercado.
                </p>
                <div className="flex flex-col sm:flex-row gap-3">
                  <a 
                    href="https://wa.me/50247391242" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="px-4 py-2 bg-white text-blue-600 rounded-lg font-medium hover:bg-blue-50 transition-colors inline-flex items-center justify-center gap-2 flex-1"
                  >
                    <FontAwesomeIcon icon={faWhatsapp} className="w-4 h-4" />
                    Contáctanos por WhatsApp
                  </a>
                  <a 
                    href="tel:+5022238-0714" 
                    className="px-4 py-2 bg-blue-700 text-white rounded-lg font-medium hover:bg-blue-800 transition-colors inline-flex items-center justify-center gap-2 flex-1"
                  >
                    <Phone className="w-4 h-4" />
                    Llámanos ahora
                  </a>
                </div>
              </div>
            </div>

            {/* Columna 2: Mapa */}
            <div className="w-full lg:w-1/2">
              <div className="bg-white p-4 rounded-xl shadow-sm border border-gray-100 h-full">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Nuestra Ubicación</h2>
                <div className="h-[calc(100%-4rem)] rounded-lg overflow-hidden">
                  <iframe
                    title="Ubicación de Disolgua"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.4339747763984!2d-90.5247976605387!3d14.63128967638358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a2216a6a3a47%3A0xea5d34810cb184e4!2sDisolgua!5e0!3m2!1ses!2sgt!4v1740079890742!5m2!1ses!2sgt"
                    style={{ border: 0 }}
                    className="w-full h-full min-h-[400px]"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 