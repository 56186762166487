import { Building2, UserPlus, Eye, EyeOff, CheckCircle } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface Municipio {
  id: string;
  nombre: string;
  departamento: string;
}

export default function Register() {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [departamentos, setDepartamentos] = useState<string[]>([]);
  const [municipios, setMunicipios] = useState<string[]>([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);

  useEffect(() => {
    fetchDepartamentos();
    checkProfilesTable();
    testProfileInsert();
  }, []);

  useEffect(() => {
    if (selectedDepartamento) {
      fetchMunicipios(selectedDepartamento);
    }
  }, [selectedDepartamento]);

  useEffect(() => {
    // Verificar si las contraseñas coinciden
    if (confirmPassword) {
      setPasswordMatch(password === confirmPassword);
    } else {
      setPasswordMatch(true);
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    // Evaluar la fortaleza de la contraseña
    if (!password) {
      setPasswordStrength(0);
      return;
    }

    let strength = 0;
    // Longitud mínima
    if (password.length >= 8) strength += 1;
    // Contiene números
    if (/\d/.test(password)) strength += 1;
    // Contiene letras minúsculas
    if (/[a-z]/.test(password)) strength += 1;
    // Contiene letras mayúsculas
    if (/[A-Z]/.test(password)) strength += 1;
    // Contiene caracteres especiales
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;

    setPasswordStrength(strength);
  }, [password]);

  const fetchDepartamentos = async () => {
    try {
      console.log('Iniciando consulta de departamentos...');
      const { data, error } = await supabase
        .rpc('get_departamentos');

      console.log('Respuesta de get_departamentos:', { data, error });

      if (error) throw error;

      setDepartamentos(data || []);
      if (data?.length > 0) {
        setSelectedDepartamento(data[0]);
        console.log('Departamento seleccionado por defecto:', data[0]);
      } else {
        console.warn('No se recibieron departamentos del servidor');
      }
    } catch (err) {
      console.error('Error al cargar departamentos:', err);
      setError('Error al cargar los departamentos');
    }
  };

  const fetchMunicipios = async (departamento: string) => {
    try {
      console.log('Iniciando consulta de municipios para departamento:', departamento);
      const { data, error } = await supabase
        .rpc('get_municipios', {
          p_departamento: departamento
        });

      console.log('Respuesta de get_municipios:', { data, error });

      if (error) throw error;

      setMunicipios(data || []);
      if (!data || data.length === 0) {
        console.warn('No se recibieron municipios para el departamento:', departamento);
      }
    } catch (err) {
      console.error('Error al cargar municipios:', err);
      setError('Error al cargar los municipios');
    }
  };

  const handleDepartamentoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    console.log('Departamento seleccionado:', value);
    setSelectedDepartamento(value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    const name = formData.get('name') as string;
    const company = formData.get('company') as string;
    const phone = formData.get('phone') as string;
    const departamento = formData.get('departamento') as string;
    const municipio = formData.get('municipio') as string;

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      setLoading(false);
      return;
    }

    try {
      console.log('Iniciando registro con email:', email);
      
      // Primero creamos el usuario en Auth
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (authError) {
        console.error('Error al registrar usuario en Auth:', authError);
        throw authError;
      }

      console.log('Usuario registrado en Auth con éxito:', authData);

      if (authData.user) {
        const userId = authData.user.id;
        console.log('Insertando perfil para usuario con ID:', userId);
        
        // Creando objeto de perfil simplificado para resolver el problema
        const profileData = {
          id: userId,
          name: name,
          company: company,
          phone: phone,
          department: departamento,
          municipio: municipio,
          is_admin: false,
          // Asignando "contado" como price_type predeterminado
          price_type: "contado",
          // Campos opcionales
          address: null,
          address_details: null,
          postal_code: null,
          nit: null,
          nit_type: null,
          admin_permissions: null
        };
        
        console.log('Objeto de perfil simplificado a insertar:', profileData);
        
        // Intento de inserción con manejo de errores
        let insertSuccess = false;
        let insertError = null;
        
        // Estrategia 1: Inserción directa
        try {
          console.log('Estrategia 1: Inserción directa');
          const { error } = await supabase
            .from('profiles')
            .insert([profileData]);
            
          if (error) {
            console.error('Error en estrategia 1:', error);
            insertError = error;
            
            // Verificar si es un error de RLS
            if (error.code === 'PGRST301' || error.message?.includes('permission denied') || error.message?.includes('policy')) {
              console.error('Error de política RLS detectado:', error);
              setError('Error de permisos: No tiene autorización para crear un perfil. Contacte al administrador.');
              setLoading(false);
              return;
            }
          } else {
            console.log('Estrategia 1 exitosa');
            insertSuccess = true;
          }
        } catch (err) {
          console.error('Excepción en estrategia 1:', err);
        }
        
        // Estrategia 2: Usar upsert si la inserción falló
        if (!insertSuccess) {
          try {
            console.log('Estrategia 2: Usando upsert');
            const { error } = await supabase
              .from('profiles')
              .upsert([profileData]);
              
            if (error) {
              console.error('Error en estrategia 2:', error);
              insertError = error;
            } else {
              console.log('Estrategia 2 exitosa');
              insertSuccess = true;
            }
          } catch (err) {
            console.error('Excepción en estrategia 2:', err);
          }
        }
        
        // Estrategia 3: Usar RPC si todo lo demás falló (requiere función RPC en Supabase)
        if (!insertSuccess) {
          try {
            console.log('Estrategia 3: Verificando si hay errores de RLS');
            console.error('Error específico que evita la inserción:', insertError);
            setError(`No se pudo crear el perfil: ${insertError?.message || 'Error desconocido'}`);
            return;
          } catch (err) {
            console.error('Error final:', err);
            setError('No se pudo crear el perfil debido a un error del servidor');
            return;
          }
        }
        
        // Verificamos que el perfil se haya creado correctamente
        try {
          const { data: checkData, error: checkError } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', userId)
            .single();
            
          if (checkError) {
            console.error('Error al verificar la creación del perfil:', checkError);
          } else {
            console.log('Verificación del perfil creado:', checkData);
          }
        } catch (err) {
          console.warn('Error al verificar el perfil creado:', err);
        }
        
        console.log('Registro completo, redirigiendo a /login');
        navigate('/login');
      } else {
        console.error('No se recibió datos de usuario después del registro');
        setError('Error al crear la cuenta. No se recibió información del usuario.');
      }
    } catch (err) {
      console.error('Error completo durante el registro:', err);
      setError(err instanceof Error ? err.message : 'Error al crear la cuenta');
    } finally {
      setLoading(false);
    }
  };

  const getPasswordStrengthColor = () => {
    if (passwordStrength <= 1) return 'bg-red-500';
    if (passwordStrength <= 3) return 'bg-yellow-500';
    return 'bg-green-500';
  };

  const getPasswordStrengthText = () => {
    if (!password) return '';
    if (passwordStrength <= 1) return 'Débil';
    if (passwordStrength <= 3) return 'Media';
    return 'Fuerte';
  };

  // Función para verificar la estructura de la tabla profiles
  const checkProfilesTable = async () => {
    try {
      console.log('Verificando la estructura de la tabla profiles...');
      
      // Intento 1: Obtener los metadatos de la tabla
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .limit(1);
        
        if (error) {
          console.error('Error al consultar la tabla profiles:', error);
        } else {
          console.log('Ejemplo de registro en profiles:', data);
          // Mostrar las columnas disponibles basadas en el primer registro
          if (data && data.length > 0) {
            console.log('Columnas disponibles:', Object.keys(data[0]));
          }
        }
      } catch (err) {
        console.error('Error al verificar la tabla profiles (Intento 1):', err);
      }
      
      // Intento 2: Verificar directamente la funcionalidad de insert
      try {
        console.log('Verificando permisos INSERT en profiles...');
        const testId = 'test-' + Date.now();
        const { error } = await supabase
          .from('profiles')
          .insert([
            {
              id: testId,
              name: 'Test User',
              is_admin: false,
              department: 'test_department',
              price_type: 'contado'
            }
          ])
          .select();
          
        if (error) {
          console.error('Error al insertar un registro de prueba:', error);
          
          if (error.code === 'P0001') {
            console.warn('Error RLS probable. Verifique las políticas de seguridad en Supabase.');
          }
        } else {
          console.log('Inserción de prueba exitosa.');
          
          // Eliminamos el registro de prueba
          await supabase
            .from('profiles')
            .delete()
            .eq('id', testId);
        }
      } catch (err) {
        console.error('Error al verificar permisos INSERT (Intento 2):', err);
      }
    } catch (err) {
      console.error('Error general al verificar la tabla profiles:', err);
    }
  };
  
  // Función para probar los permisos de inserción
  const testProfileInsert = async () => {
    try {
      console.log('Prueba de inserción temporal para verificar permisos RLS...');
      
      // Primero verificamos si podemos hacer select en la tabla profiles
      const { data: selectData, error: selectError } = await supabase
        .from('profiles')
        .select('id')
        .limit(1);
        
      console.log('Prueba de SELECT:', { selectData, selectError });
      
      if (selectError) {
        console.error('No se puede realizar SELECT en profiles. Error RLS probable:', selectError);
      }
      
      // No realizamos una inserción de prueba real para evitar datos basura
      console.log('Nota: No se realiza inserción de prueba real para evitar datos basura');
    } catch (err) {
      console.error('Error en la prueba de permisos:', err);
    }
  };

  return (
    <div className="min-h-[calc(100vh-4rem)] flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-blue-50 to-white">
      <div className="max-w-2xl w-full space-y-8 bg-white p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-xl">
        <div className="text-center">
          <div className="flex justify-center">
            <div className="bg-blue-100 p-4 rounded-full">
              <Building2 className="h-12 w-12 text-blue-600" />
            </div>
          </div>
          <h2 className="mt-6 text-3xl font-bold text-gray-900 bg-gradient-to-r from-blue-600 to-blue-800 bg-clip-text text-transparent">
            Crear cuenta empresarial
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            ¿Ya tienes una cuenta?{' '}
            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500 transition-colors duration-300 underline decoration-2 decoration-blue-500 underline-offset-2">
              Inicia sesión aquí
            </Link>
          </p>
        </div>
        
        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 text-red-600 px-4 py-3 rounded-lg text-sm animate-pulse">
              {error}
            </div>
          )}

          <div className="rounded-md space-y-5">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="group">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                  Nombre completo
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 bg-white"
                  placeholder="José García Ramírez"
                />
              </div>

              <div className="group">
                <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                  Nombre de la empresa
                </label>
                <input
                  id="company"
                  name="company"
                  type="text"
                  required
                  className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 bg-white"
                  placeholder="Distribuidora García, S.A."
                />
              </div>
            </div>

            <div className="group">
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                Teléfono
              </label>
              <input
                id="phone"
                name="phone"
                type="tel"
                required
                className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 bg-white"
                placeholder="2222-2222"
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
              <div className="group">
                <label htmlFor="departamento" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                  Departamento
                </label>
                <select
                  id="departamento"
                  name="departamento"
                  required
                  className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 text-gray-900"
                  value={selectedDepartamento}
                  onChange={handleDepartamentoChange}
                >
                  <option value="">Seleccione un departamento</option>
                  {departamentos.map(dep => (
                    <option key={dep} value={dep}>{dep}</option>
                  ))}
                </select>
              </div>

              <div className="group">
                <label htmlFor="municipio" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                  Municipio
                </label>
                <select
                  id="municipio"
                  name="municipio"
                  required
                  className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 text-gray-900 disabled:bg-gray-100 disabled:text-gray-500"
                  disabled={!selectedDepartamento}
                >
                  <option value="">Seleccione un municipio</option>
                  {municipios.map(mun => (
                    <option key={mun} value={mun}>{mun}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="group">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                Correo electrónico empresarial
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 bg-white"
                placeholder="jose.garcia@empresa.com.gt"
              />
            </div>
            
            <div className="group">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                Contraseña
              </label>
              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  className="appearance-none relative block w-full px-4 py-3 border border-gray-300 rounded-xl placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300 pr-12 bg-white"
                  placeholder="••••••••"
                />
                <button 
                  type="button" 
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 transition-colors"
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
              </div>
              {password && (
                <div className="mt-2">
                  <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                    <div 
                      className={`h-full ${getPasswordStrengthColor()} transition-all duration-300`} 
                      style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                  </div>
                  <p className="text-xs mt-1 text-gray-600">
                    Fortaleza: <span className="font-medium">{getPasswordStrengthText()}</span>
                  </p>
                </div>
              )}
            </div>

            <div className="group">
              <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 mb-1 group-focus-within:text-blue-600 transition-colors duration-200">
                Confirmar contraseña
              </label>
              <div className="relative">
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  className={`appearance-none relative block w-full px-4 py-3 border rounded-xl placeholder-gray-400 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 pr-12 bg-white ${
                    confirmPassword && !passwordMatch 
                      ? 'border-red-500 focus:border-red-500' 
                      : confirmPassword && passwordMatch 
                        ? 'border-green-500 focus:border-green-500' 
                        : 'border-gray-300 focus:border-blue-500'
                  }`}
                  placeholder="••••••••"
                />
                <button 
                  type="button" 
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 transition-colors"
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-5 w-5" />
                  ) : (
                    <Eye className="h-5 w-5" />
                  )}
                </button>
                {confirmPassword && passwordMatch && (
                  <CheckCircle className="absolute right-12 top-1/2 transform -translate-y-1/2 h-5 w-5 text-green-500" />
                )}
              </div>
              {confirmPassword && !passwordMatch && (
                <p className="text-xs mt-1 text-red-500">
                  Las contraseñas no coinciden
                </p>
              )}
            </div>
          </div>

          <div className="flex items-center">
            <input
              id="terms"
              name="terms"
              type="checkbox"
              required
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="terms" className="ml-2 block text-sm text-gray-700">
              Acepto los <Link to="/terms" className="text-blue-600 hover:text-blue-500 transition-colors duration-300">términos y condiciones</Link>
            </label>
          </div>

          <button
            type="submit"
            className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-md hover:shadow-lg"
            disabled={loading || (confirmPassword !== '' && !passwordMatch)}
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <UserPlus className="h-5 w-5 text-blue-300 group-hover:text-blue-200 transition-colors" />
            </span>
            {loading ? (
              <div className="flex items-center">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Creando cuenta...
              </div>
            ) : 'Crear cuenta'}
          </button>
        </form>
      </div>
    </div>
  );
}