import { useState, FormEvent, useEffect } from 'react';
import { CreditCard, Truck, Store, Lock, Loader2 } from 'lucide-react';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../lib/supabase';

// Interfaces para departamentos y municipios
interface Departamento {
  id: string;
  nombre: string;
  created_at?: string;
}

interface Municipio {
  id: string;
  nombre: string;
  departamento: string;
  created_at?: string;
}

interface OrderItem {
  product_id: string;
  quantity: number;
  price: number;
  product: {
    name: string;
    main_image: string;
  };
}

interface Order {
  id: string;
  status: 'pending' | 'confirmed' | 'pending_payment' | 'paid' | 'processing' | 'shipped' | 'delivered' | 'cancelled';
  payment_status: 'pending' | 'paid' | 'failed' | 'refunded';
  total: number;
  items: any[];
  created_at: string;
  shipping_address?: {
    first_name: string;
    last_name: string;
    company: string;
    nit: string;
    nit_type: 'NIT' | 'CF';
    address: string;
    address_details?: string;
    city: string;
    region: string;
    postal_code?: string;
    phone: string;
    email: string;
  };
  transfer_receipt_url?: string;
}

interface FormData {
  email: string;
  companyName: string;
  contactPerson: string;
  nit: string;
  nitType: 'NIT' | 'CF';
  address: string;
  addressDetails: string;
  department: string;
  municipality: string;
  postalCode: string;
  phone: string;
  cardNumber?: string;
  cardExpiry?: string;
  cardCvc?: string;
  cardHolder?: string;
  bankTransferReceipt?: File;
  selectedBank?: string;
}

export default function Checkout() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState<'card' | 'cash' | 'twoCards' | 'bankTransfer'>('card');
  const [deliveryMethod, setDeliveryMethod] = useState<'delivery' | 'pickup'>('delivery');
  const [loading, setLoading] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [order, setOrder] = useState<Order | null>(null);
  const [loadingOrder, setLoadingOrder] = useState(true);
  const [departamentos, setDepartamentos] = useState<Departamento[]>([]);
  const [municipiosFiltrados, setMunicipiosFiltrados] = useState<Municipio[]>([]);
  const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const [userProfile, setUserProfile] = useState<{
    name: string;
    company: string;
    phone: string;
    email?: string;
    address?: string;
    address_details?: string;
    department?: string;
    municipio?: string;
    postal_code?: string;
    nit?: string;
    nit_type?: 'NIT' | 'CF';
  } | null>(null);
  const [formData, setFormData] = useState<FormData>({
    email: '',
    companyName: '',
    contactPerson: '',
    nit: '',
    nitType: 'NIT',
    address: '',
    addressDetails: '',
    department: '',
    municipality: '',
    postalCode: '',
    phone: '',
    selectedBank: '',
  });

  useEffect(() => {
    if (id) {
      fetchOrder();
    } else {
      setError('ID de pedido no válido');
      setLoadingOrder(false);
    }
  }, [id]);

  useEffect(() => {
    fetchUserProfile();
    fetchDepartamentos();
  }, []);

  const fetchUserProfile = async () => {
    try {
      setLoadingProfile(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      console.log('Obteniendo perfil del usuario:', user.id);

      // Obtener el perfil del usuario con todos los campos
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error al obtener el perfil:', error);
        return;
      }

      console.log('Perfil completo obtenido:', profile);

      if (profile) {
        setUserProfile(profile);
        // Actualizar el formulario con los datos del perfil
        setFormData(prev => {
          const updatedData = {
            ...prev,
            email: profile.email || user.email || '',
            companyName: profile.company || '',
            contactPerson: profile.name || '',
            phone: profile.phone || '',
            nit: profile.nit || '',
            nitType: profile.nit_type || 'NIT',
            address: profile.address || '',
            addressDetails: profile.address_details || '',
            department: profile.department || '',
            municipality: profile.municipio || '',
            postalCode: profile.postal_code || ''
          };
          
          console.log('Actualizando formulario con datos del perfil:', updatedData);

          // Si el perfil tiene un departamento, cargar los municipios correspondientes
          if (profile.department) {
            fetchMunicipiosByDepartamento(profile.department);
          }

          return updatedData;
        });
      } else {
        console.error('No se encontró el perfil del usuario');
      }
    } catch (error) {
      console.error('Error al obtener el perfil del usuario:', error);
    } finally {
      setLoadingProfile(false);
    }
  };

  const fetchOrder = async () => {
    try {
      setLoadingOrder(true);
      setError(null);
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      console.log('Obteniendo pedido con ID:', id);
      
      // Obtener la orden existente
      const { data, error } = await supabase
        .from('orders')
        .select(`
          id,
          created_at,
          total_amount,
          status,
          payment_status,
          payment_method,
          delivery_method,
          shipping_address,
          transfer_receipt_url,
          items:order_items(
            id,
            product_id,
            quantity,
            price,
            product:products(
              id,
              name,
              main_image
            )
          )
        `)
        .eq('id', id)
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error al obtener el pedido:', error);
        throw new Error(`Error al obtener el pedido: ${error.message || JSON.stringify(error)}`);
      }
      
      if (!data) {
        throw new Error('No se encontró el pedido');
      }
      
      console.log('Datos del pedido obtenidos:', data);
      
      // Verificar que los items tengan el formato correcto
      if (!data.items || !Array.isArray(data.items)) {
        console.error('Formato de items incorrecto:', data.items);
        throw new Error('Formato de items incorrecto');
      }
      
      // Transformar los datos para que coincidan con la interfaz
      const transformedOrder: Order = {
        id: data.id,
        created_at: data.created_at,
        total: data.total_amount,
        status: data.status,
        payment_status: data.payment_status,
        shipping_address: data.shipping_address,
        transfer_receipt_url: data.transfer_receipt_url,
        items: data.items.map((item: any) => {
          if (!item.product || typeof item.product !== 'object') {
            console.error('Item con formato incorrecto:', item);
            return {
              product_id: item.product_id,
              quantity: item.quantity,
              price: item.price,
              product: {
                name: 'Producto no disponible',
                main_image: ''
              }
            };
          }
          
          return {
            product_id: item.product_id,
            quantity: item.quantity,
            price: item.price,
            product: {
              name: item.product.name || 'Producto sin nombre',
              main_image: item.product.main_image || ''
            }
          };
        })
      };

      console.log('Datos transformados:', transformedOrder);
      setOrder(transformedOrder);
    } catch (error) {
      console.error('Error al cargar el pedido:', error);
      setError(error instanceof Error ? error.message : 'Error al cargar el pedido');
    } finally {
      setLoadingOrder(false);
    }
  };

  const getOrderTotal = () => {
    if (!order) return 0;
    return order.items.reduce((sum, item) => 
      sum + (item.price * item.quantity), 0
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Si cambia el departamento, obtener los municipios correspondientes
    if (name === 'department') {
      if (value) {
        fetchMunicipiosByDepartamento(value);
      } else {
        setMunicipiosFiltrados([]);
      }
      
      // Resetear el municipio seleccionado
      setFormData(prev => ({
        ...prev,
        municipality: ''
      }));
    }
  };

  // Función para obtener municipios por departamento
  const fetchMunicipiosByDepartamento = async (departamento: string) => {
    try {
      setLoadingMunicipios(true);
      
      // Usar la función RPC get_municipios
      const { data, error } = await supabase
        .rpc('get_municipios', { p_departamento: departamento });

      if (error) {
        console.error('Error al obtener municipios:', error);
        setMunicipiosFiltrados([]);
        return;
      }

      console.log('Municipios obtenidos para', departamento, ':', data);
      
      // Transformar el array de strings a objetos con id y nombre
      const municipiosFormateados = data.map((nombre: string, index: number) => ({
        id: index.toString(),
        nombre: nombre,
        departamento: departamento
      }));
      
      setMunicipiosFiltrados(municipiosFormateados);
    } catch (error) {
      console.error('Error al obtener municipios:', error);
      setMunicipiosFiltrados([]);
    } finally {
      setLoadingMunicipios(false);
    }
  };

  // Función para manejar la carga del comprobante de transferencia
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      
      // Verificar que sea una imagen
      if (!file.type.startsWith('image/')) {
        setError('Por favor, sube una imagen como comprobante');
        return;
      }
      
      // Verificar tamaño (máximo 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setError('La imagen no debe superar los 5MB');
        return;
      }
      
      setFormData(prev => ({
        ...prev,
        bankTransferReceipt: file
      }));
      
      setError(null);
    }
  };

  const validateForm = (): boolean => {
    if (!formData.email) {
      setError('El correo electrónico es requerido');
      return false;
    }

    if (deliveryMethod === 'delivery') {
      if (!formData.companyName || !formData.contactPerson) {
        setError('El nombre de la empresa y el contacto son requeridos');
        return false;
      }
      if (!formData.nit) {
        setError('El NIT/CF es requerido');
        return false;
      }
      if (!formData.address) {
        setError('La dirección es requerida');
        return false;
      }
      if (!formData.department || !formData.municipality) {
        setError('El departamento y municipio son requeridos');
        return false;
      }
      if (!formData.phone) {
        setError('El teléfono es requerido');
        return false;
      }
    }

    if (paymentMethod === 'card') {
      // No se requieren validaciones adicionales para el pago con tarjeta
    }

    if (paymentMethod === 'bankTransfer') {
      if (!formData.selectedBank) {
        setError('Por favor, selecciona un banco para la transferencia');
        return false;
      }
      if (!formData.bankTransferReceipt) {
        setError('Por favor, sube el comprobante de la transferencia');
        return false;
      }
    }

    // No se requieren validaciones adicionales para el pago en efectivo
    
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!order) {
      setError('No se encontró el pedido. Por favor, intenta de nuevo.');
      return;
    }
    
    // Validar el formulario
    const isValid = validateForm();
    if (!isValid) return;
    
    try {
      setLoading(true);
      setError('');
      setSuccess(null);
      
      // Preparar la dirección de envío según el método de entrega
      const shippingAddress = deliveryMethod === 'pickup' ? null : {
        first_name: formData.contactPerson,
        last_name: '',
        company: formData.companyName,
        nit: formData.nit,
        nit_type: formData.nitType,
        address: formData.address,
        address_details: formData.addressDetails,
        city: formData.municipality,
        region: formData.department,
        postal_code: formData.postalCode,
        phone: formData.phone,
        email: formData.email
      };
      
      // Procesar el pago según el método seleccionado
      if (paymentMethod === 'card') {
        console.log('Procesando pago con tarjeta...');
        
        // Generar enlace de WhatsApp
        const whatsappLink = generateWhatsAppMessage();
        
        // Actualizar el pedido con el estado pendiente de pago
        const { error: updateError } = await supabase
          .from('orders')
          .update({
            payment_method: paymentMethod,
            payment_status: 'pending',
            status: 'pending_payment',
            shipping_address: shippingAddress ? JSON.stringify(shippingAddress) : null,
            delivery_method: deliveryMethod
          })
          .eq('id', order.id);
          
        if (updateError) {
          console.error('Error al actualizar el pedido:', updateError);
          setError(`Error al procesar el pago: ${updateError.message || 'Verifica los datos e intenta nuevamente'}`);
          setLoading(false);
          return;
        }
        
        // Mostrar mensaje de éxito
        console.log('Solicitando enlace de pago vía WhatsApp');
        setSuccess('¡Serás redirigido a WhatsApp para solicitar el enlace de pago!');
        
        // Esperar un momento antes de redirigir a WhatsApp
        setTimeout(() => {
          window.open(whatsappLink, '_blank');
          navigate(`/confirmation/${order.id}`);
        }, 2000);
      } else if (paymentMethod === 'bankTransfer') {
        console.log('Procesando pago con transferencia bancaria...');
        
        // Subir el comprobante de transferencia a Supabase Storage
        if (formData.bankTransferReceipt) {
          const fileName = `receipt_${order.id}_${Date.now()}.${formData.bankTransferReceipt.name.split('.').pop()}`;
          
          try {
            // Verificar si el bucket existe, si no, usar 'public'
            const { data: uploadData, error: uploadError } = await supabase.storage
              .from('public')
              .upload(`receipts/${fileName}`, formData.bankTransferReceipt, {
                cacheControl: '3600',
                upsert: true
              });
              
            if (uploadError) {
              console.error('Error al subir el comprobante:', uploadError);
              setError(`Error al subir el comprobante: ${uploadError.message}`);
              setLoading(false);
              return;
            }
            
            // Obtener la URL pública del comprobante
            const { data: publicUrlData } = supabase.storage
              .from('public')
              .getPublicUrl(`receipts/${fileName}`);
              
            const receiptUrl = publicUrlData?.publicUrl;
            
            // Actualizar el pedido con la URL del comprobante
            const { error: updateError } = await supabase
              .from('orders')
              .update({
                payment_method: paymentMethod,
                payment_status: 'paid',
                status: 'processing',
                shipping_address: shippingAddress ? JSON.stringify(shippingAddress) : null,
                delivery_method: deliveryMethod,
                transfer_receipt_url: receiptUrl
              })
              .eq('id', order.id);
              
            if (updateError) {
              console.error('Error al actualizar el pedido:', updateError);
              setError(`Error al procesar el pago: ${updateError.message || 'Verifica los datos e intenta nuevamente'}`);
              setLoading(false);
              return;
            }
            
            // Mostrar mensaje de éxito
            console.log('Pago con transferencia bancaria procesado correctamente');
            setSuccess('¡Pago con transferencia bancaria completado con éxito! Redirigiendo a la página de confirmación...');
            
            // Esperar un momento antes de redirigir
            setTimeout(() => {
              navigate(`/confirmation/${order.id}`);
            }, 2000);
          } catch (uploadError) {
            console.error('Error inesperado al subir el comprobante:', uploadError);
            setError('Error inesperado al subir el comprobante. Por favor, intenta nuevamente.');
            setLoading(false);
            return;
          }
        } else {
          setError('Por favor, sube un comprobante de transferencia.');
          setLoading(false);
          return;
        }
      } else if (paymentMethod === 'cash') {
        console.log('Procesando pago en efectivo...');
        
        // Actualizar el pedido con el método de pago en efectivo
        const { error: updateError } = await supabase
          .from('orders')
          .update({
            payment_method: paymentMethod,
            payment_status: 'paid',
            status: 'processing',
            shipping_address: shippingAddress ? JSON.stringify(shippingAddress) : null,
            delivery_method: deliveryMethod
          })
          .eq('id', order.id);
          
        if (updateError) {
          console.error('Error al actualizar el pedido:', updateError);
          setError(`Error al procesar el pago: ${updateError.message || 'Verifica los datos e intenta nuevamente'}`);
          setLoading(false);
          return;
        }
        
        // Mostrar mensaje de éxito
        console.log('Pago en efectivo procesado correctamente');
        setSuccess('¡Pago en efectivo completado con éxito! Redirigiendo a la página de confirmación...');
        
        // Esperar un momento antes de redirigir
        setTimeout(() => {
          navigate(`/confirmation/${order.id}`);
        }, 2000);
      }
    } catch (error) {
      console.error('Error al procesar el pago:', error);
      setError(`Error al procesar el pago: ${error instanceof Error ? error.message : 'Intenta nuevamente más tarde'}`);
      setLoading(false);
    }
  };

  // Función para obtener los departamentos
  const fetchDepartamentos = async () => {
    try {
      setLoadingDepartamentos(true);
      
      // Usar la función RPC get_departamentos
      const { data, error } = await supabase
        .rpc('get_departamentos');

      if (error) {
        console.error('Error al obtener departamentos:', error);
        return;
      }

      console.log('Departamentos obtenidos:', data);
      
      // Transformar el array de strings a objetos con id y nombre
      const departamentosFormateados = data.map((nombre: string, index: number) => ({
        id: index.toString(),
        nombre: nombre
      }));
      
      setDepartamentos(departamentosFormateados);
    } catch (error) {
      console.error('Error al obtener departamentos:', error);
    } finally {
      setLoadingDepartamentos(false);
    }
  };

  // Función para generar el mensaje de WhatsApp con los datos del pedido
  const generateWhatsAppMessage = () => {
    if (!order) return '';
    
    // Número de teléfono al que se enviará el mensaje (debe incluir el código de país sin el signo +)
    const phoneNumber = '50230000000'; // Reemplazar con el número real de servicio al cliente
    
    // Construir el resumen de productos
    const itemsList = order.items.map(item => 
      `• ${item.quantity} x ${item.product.name} - Q${(item.price * item.quantity).toFixed(2)}`
    ).join('\n');
    
    // Información de la orden formateada para WhatsApp
    const message = 
      `*SOLICITUD DE PAGO*\n\n` +
      `*Orden:* #${order.id}\n` +
      `*Cliente:* ${formData.contactPerson}\n` +
      `*Empresa:* ${formData.companyName}\n` +
      `*Correo:* ${formData.email}\n` +
      `*Teléfono:* ${formData.phone}\n\n` +
      `*Resumen del pedido:*\n${itemsList}\n\n` +
      `*TOTAL: Q${getOrderTotal().toFixed(2)}*\n\n` +
      `Por favor, envíeme un enlace de pago para realizar el pago con tarjeta. Gracias.`;
    
    // Generar la URL para WhatsApp
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  };

  if (loadingOrder) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Pedido no encontrado</h2>
          <p className="text-gray-600 mb-8">El pedido que estás intentando procesar no existe</p>
          <button
            onClick={() => navigate('/')}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
          >
            Ir a comprar
          </button>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-7xl mx-auto px-4 py-8">
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded-lg mb-6">
          {error}
        </div>
      )}
      
      {success && (
        <div className="bg-green-50 border border-green-200 text-green-700 px-4 py-3 rounded-lg mb-6">
          {success}
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Formulario Principal */}
        <div className="lg:col-span-2 space-y-8">
          {/* Contacto */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-4">Contacto</h2>
            <div className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Correo electrónico"
                  className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                  required
                />
              </div>
              <div className="flex items-center gap-2">
                <input type="checkbox" id="newsletter" className="rounded text-blue-600" />
                <label htmlFor="newsletter" className="text-sm text-gray-600">
                  Enviarme novedades y ofertas por correo electrónico
                </label>
              </div>
            </div>
          </div>

          {/* Entrega */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-4">Dirección de envío</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => setDeliveryMethod('delivery')}
                  className={`flex items-center gap-3 p-4 border rounded-lg ${
                    deliveryMethod === 'delivery' ? 'border-blue-500 bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                >
                  <Truck className="w-5 h-5 text-gray-600" />
                  <span>Envío a domicilio</span>
                </button>
                
              </div>

              {deliveryMethod === 'delivery' && (
                <div className="space-y-4">
                  {userProfile && (
                    <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
                      <h3 className="font-medium text-blue-800 mb-2">Dirección guardada en tu perfil</h3>
                      <p className="text-sm text-blue-700 mb-3">
                        {userProfile.address}, {userProfile.municipio}, {userProfile.department}
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          setFormData(prev => ({
                            ...prev,
                            companyName: userProfile.company || '',
                            contactPerson: userProfile.name || '',
                            nit: userProfile.nit || '',
                            nitType: userProfile.nit_type || 'NIT',
                            address: userProfile.address || '',
                            addressDetails: userProfile.address_details || '',
                            department: userProfile.department || '',
                            municipality: userProfile.municipio || '',
                            postalCode: userProfile.postal_code || '',
                            phone: userProfile.phone || ''
                          }));
                          if (userProfile.department) {
                            fetchMunicipiosByDepartamento(userProfile.department);
                          }
                        }}
                        className="text-sm text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Usar esta dirección
                      </button>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Empresa
                      </label>
                      <div className="relative">
                        <input
                          type="text"
                          name="companyName"
                          value={userProfile?.company || ''}
                          className="w-full px-4 py-2 border rounded-lg bg-gray-100 outline-none"
                          placeholder="Nombre de la empresa"
                          readOnly
                        />
                        {loadingProfile && (
                          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            <Loader2 className="w-5 h-5 text-blue-500 animate-spin" />
                          </div>
                        )}
                      </div>
                      {!loadingProfile && !userProfile?.company && (
                        <p className="text-xs text-red-500 mt-1">
                          No se ha configurado el nombre de la empresa en tu perfil
                        </p>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Persona de contacto
                      </label>
                      <input
                        type="text"
                        name="contactPerson"
                        value={formData.contactPerson}
                        onChange={handleInputChange}
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        placeholder="Nombre del contacto"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Tipo
                      </label>
                      <select
                        name="nitType"
                        value={formData.nitType}
                        onChange={handleInputChange}
                        className="w-24 px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        required
                      >
                        <option value="NIT">NIT</option>
                        <option value="CF">CF</option>
                      </select>
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Número
                      </label>
                      <input
                        type="text"
                        name="nit"
                        value={formData.nit}
                        onChange={handleInputChange}
                        placeholder="NIT/CF"
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Dirección
                    </label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      placeholder="Dirección"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Detalles de dirección
                    </label>
                    <input
                      type="text"
                      name="addressDetails"
                      value={formData.addressDetails}
                      onChange={handleInputChange}
                      placeholder="Casa, apartamento, etc. (opcional)"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                    />
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Departamento
                      </label>
                      <div className="relative">
                        <select
                          name="department"
                          value={formData.department}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                          required
                          disabled={loadingDepartamentos}
                        >
                          <option value="">Seleccione departamento</option>
                          {departamentos.map((depto) => (
                            <option key={depto.id} value={depto.nombre}>
                              {depto.nombre}
                            </option>
                          ))}
                        </select>
                        {loadingDepartamentos && (
                          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            <Loader2 className="w-5 h-5 text-blue-500 animate-spin" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Municipio
                      </label>
                      <div className="relative">
                        <select
                          name="municipality"
                          value={formData.municipality}
                          onChange={handleInputChange}
                          className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                          required
                          disabled={loadingMunicipios || municipiosFiltrados.length === 0}
                        >
                          <option value="">Seleccione municipio</option>
                          {municipiosFiltrados.map((municipio) => (
                            <option key={municipio.id} value={municipio.nombre}>
                              {municipio.nombre}
                            </option>
                          ))}
                        </select>
                        {loadingMunicipios && (
                          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            <Loader2 className="w-5 h-5 text-blue-500 animate-spin" />
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Código postal
                      </label>
                      <input
                        type="text"
                        name="postalCode"
                        value={formData.postalCode}
                        onChange={handleInputChange}
                        placeholder="Código postal"
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Teléfono
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Teléfono"
                      className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500 outline-none"
                      required
                    />
                  </div>
                  <div className="flex items-center gap-2">
                    <input type="checkbox" id="saveInfo" className="rounded text-blue-600" />
                    <label htmlFor="saveInfo" className="text-sm text-gray-600">
                      Guardar mi información y consultar más rápidamente la próxima vez
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Pago */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold mb-4">Método de pago</h2>
            <div className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => setPaymentMethod('card')}
                  className={`flex items-center gap-3 p-4 border rounded-lg ${
                    paymentMethod === 'card' ? 'border-blue-500 bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                >
                  <CreditCard className="w-5 h-5 text-gray-600" />
                  <span>Tarjeta de crédito/débito</span>
                </button>
               
                <button
                  type="button"
                  onClick={() => setPaymentMethod('bankTransfer')}
                  className={`flex items-center gap-3 p-4 border rounded-lg ${
                    paymentMethod === 'bankTransfer' ? 'border-blue-500 bg-blue-50' : 'hover:bg-gray-50'
                  }`}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-gray-600">
                    <rect x="2" y="6" width="20" height="12" rx="2"></rect>
                    <path d="M12 12h.01"></path>
                    <path d="M17 12h.01"></path>
                    <path d="M7 12h.01"></path>
                  </svg>
                  <span>Transferencia bancaria</span>
                </button>
                
               
              </div>

              {paymentMethod === 'card' && (
                <div className="space-y-4">
                  <div className="bg-blue-50 p-4 rounded-lg border border-blue-200">
                    <h3 className="font-medium text-blue-800 mb-2">Pago con Tarjeta</h3>
                    <p className="text-sm text-blue-700 mb-3">
                      Al seleccionar esta opción y hacer clic en "Pagar", serás redirigido a WhatsApp para solicitar un enlace de pago seguro.
                    </p>
                    <p className="text-sm text-blue-700">
                      Nuestro equipo te enviará el enlace donde podrás completar el pago con tu tarjeta de forma segura.
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="text-green-600">
                      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                    <span className="text-sm text-gray-600">Recibirás un enlace seguro para completar tu pago</span>
                  </div>
                </div>
              )}

              {paymentMethod === 'bankTransfer' && (
                <div className="space-y-4">
                  <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                    <h3 className="font-medium text-gray-800 mb-2">Cuentas bancarias disponibles</h3>
                    <div className="space-y-3">
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <input 
                            type="radio" 
                            id="bank-banrural" 
                            name="selectedBank" 
                            value="Banrural" 
                            checked={formData.selectedBank === 'Banrural'}
                            onChange={handleInputChange}
                            className="text-blue-600" 
                          />
                          <label htmlFor="bank-banrural" className="font-medium">Banrural</label>
                        </div>
                        <div className="ml-6 text-sm text-gray-600">
                          <p>Cuenta Monetaria: 3173027242</p>
                          <p>A nombre de: Distribuidora del sol Guatemala S.A.</p>
                        </div>
                      </div>
                      
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <input 
                            type="radio" 
                            id="bank-bi" 
                            name="selectedBank" 
                            value="BI" 
                            checked={formData.selectedBank === 'BI'}
                            onChange={handleInputChange}
                            className="text-blue-600" 
                          />
                          <label htmlFor="bank-bi" className="font-medium">Banco Industrial</label>
                        </div>
                        <div className="ml-6 text-sm text-gray-600">
                          <p>Cuenta Monetaria: 1100021030</p>
                          <p>A nombre de: Distribuidora del sol Guatemala S.A.</p>
                        </div>
                      </div>
                      
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2">
                          <input 
                            type="radio" 
                            id="bank-bam" 
                            name="selectedBank" 
                            value="BAM" 
                            checked={formData.selectedBank === 'BAM'}
                            onChange={handleInputChange}
                            className="text-blue-600" 
                          />
                          <label htmlFor="bank-bam" className="font-medium">BAM</label>
                        </div>
                        <div className="ml-6 text-sm text-gray-600">
                          <p>Cuenta Monetaria: 3040076937</p>
                          <p>A nombre de: Distribuidora del sol Guatemala S.A.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Comprobante de transferencia
                    </label>
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center">
                      <input
                        type="file"
                        id="bankTransferReceipt"
                        accept="image/*"
                        onChange={handleFileUpload}
                        className="hidden"
                      />
                      <label
                        htmlFor="bankTransferReceipt"
                        className="cursor-pointer flex flex-col items-center justify-center"
                      >
                        {formData.bankTransferReceipt ? (
                          <div className="space-y-2">
                            <div className="flex items-center justify-center bg-green-50 text-green-600 w-12 h-12 rounded-full mx-auto">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M20 6L9 17l-5-5"></path>
                              </svg>
                            </div>
                            <p className="text-sm font-medium text-gray-900">{formData.bankTransferReceipt.name}</p>
                            <p className="text-xs text-gray-500">
                              {(formData.bankTransferReceipt.size / 1024 / 1024).toFixed(2)} MB
                            </p>
                            <button
                              type="button"
                              className="text-sm text-blue-600 hover:text-blue-700"
                              onClick={() => setFormData(prev => ({ ...prev, bankTransferReceipt: undefined }))}
                            >
                              Cambiar archivo
                            </button>
                          </div>
                        ) : (
                          <div className="space-y-2">
                            <div className="flex items-center justify-center bg-gray-100 w-12 h-12 rounded-full mx-auto">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                <polyline points="17 8 12 3 7 8"></polyline>
                                <line x1="12" y1="3" x2="12" y2="15"></line>
                              </svg>
                            </div>
                            <p className="text-sm font-medium text-gray-900">Haz clic para subir</p>
                            <p className="text-xs text-gray-500">PNG, JPG o JPEG (máx. 5MB)</p>
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                  
                  <div className="bg-yellow-50 p-3 rounded-lg border border-yellow-200">
                    <p className="text-sm text-yellow-800">
                      <strong>Importante:</strong> Tu pedido quedará en estado pendiente hasta que verifiquemos la transferencia. 
                      Te notificaremos por correo electrónico cuando se confirme el pago.
                    </p>
                  </div>
                </div>
              )}

              
            </div>
          </div>
        </div>

        {/* Resumen de Compra */}
        <div className="lg:col-span-1">
          <div className="bg-white rounded-lg shadow-sm p-6 space-y-4 sticky top-4">
            <h2 className="text-lg font-semibold">Resumen de compra</h2>
            
            <div className="space-y-4 divide-y">
              {order.items.map((item) => (
                <div key={item.product_id} className="flex items-center gap-4 pt-4">
                  <img
                    src={item.product.main_image}
                    alt={item.product.name}
                    className="w-20 h-20 object-cover rounded-lg"
                  />
                  <div className="flex-1">
                    <h3 className="text-sm font-medium">{item.product.name}</h3>
                    <p className="text-sm text-gray-500">Cantidad: {item.quantity}</p>
                    <p className="text-sm font-medium">Q {(item.price * item.quantity).toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>

            

            <div className="space-y-2 text-sm">
              <div className="flex justify-between">
                <span>Subtotal</span>
                <span>Q {getOrderTotal().toFixed(2)}</span>
              </div>
              <div className="flex justify-between">
                <span>Envío</span>
                <span className='text-green-600'>Gratis</span>
              </div>
              <div className="flex justify-between pt-4 border-t text-base font-medium">
                <span>Total</span>
                <div className="text-right">
                  <div>Q {getOrderTotal().toFixed(2)}</div>
                  <div className="text-xs text-gray-500">
                    Incluye Q {(getOrderTotal() * 0.12).toFixed(2)} de impuestos
                  </div>
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {loading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Procesando...
                </>
              ) : (
                'Pagar'
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
} 