import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Package, Truck, Clock, Loader2, ArrowRight, CheckCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useCartContext } from '../contexts/CartContext';
import { sendOrderConfirmationEmail } from '../lib/emailService';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

interface CartItem {
  id: string;
  product_id: string;
  quantity: number;
  price_type?: 'contado' | 'credito' | 'mayorista' | 'publico';
  product: {
    name: string;
    base_price: number;
    prices?: {
      price_type?: 'contado' | 'credito' | 'mayorista' | 'publico';
      min_quantity: number;
      price: number;
    }[];
    main_image: string;
  };
}

export default function OrderCheckout() {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userPriceType, setUserPriceType] = useState<'contado' | 'credito' | 'mayorista' | 'publico' | null>(null);
  const { refreshCart } = useCartContext();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  useEffect(() => {
    fetchUserPriceType();
    fetchCartItems();
  }, []);

  const fetchUserPriceType = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: profile, error } = await supabase
        .from('profiles')
        .select('price_type')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      console.log('Tipo de precio del usuario cargado en checkout:', profile?.price_type);
      setUserPriceType(profile?.price_type || null);
    } catch (err) {
      console.error('Error fetching user price type:', err);
    }
  };

  const fetchCartItems = async () => {
    try {
      setLoading(true);
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      // Obtener el carrito del usuario
      const { data: cart, error: cartError } = await supabase
        .from('cart')
        .select('id')
        .eq('user_id', user.id)
        .single();

      if (cartError) {
        console.error('Error al obtener el carrito:', cartError);
        throw cartError;
      }

      if (!cart) {
        throw new Error('No se encontró el carrito');
      }

      // Obtener los items del carrito
      const { data: items, error: itemsError } = await supabase
        .from('cart_items')
        .select(`
          id,
          quantity,
          product_id,
          price_type,
          product:products(
            name,
            base_price,
            prices:product_prices(
              price_type,
              min_quantity,
              price
            ),
            main_image
          )
        `)
        .eq('cart_id', cart.id);

      if (itemsError) {
        console.error('Error al obtener los items del carrito:', itemsError);
        throw itemsError;
      }

      if (!items || items.length === 0) {
        navigate('/');
        return;
      }

      console.log('Items obtenidos del carrito:', JSON.stringify(items, null, 2));

      // Transformar los datos para que coincidan con la interfaz CartItem
      const transformedItems: CartItem[] = items.map(item => {
        // Verificar que item.product tenga el formato correcto
        if (!item.product || typeof item.product !== 'object') {
          console.error('Error: item.product no tiene el formato esperado', item);
          return {
            id: item.id,
            product_id: item.product_id,
            quantity: item.quantity,
            price_type: item.price_type,
            product: {
              name: 'Producto no disponible',
              base_price: 0,
              prices: [],
              main_image: ''
            }
          };
        }

        return {
          id: item.id,
          product_id: item.product_id,
          quantity: item.quantity,
          price_type: item.price_type,
          product: {
            name: (item.product as any).name,
            base_price: (item.product as any).base_price,
            prices: (item.product as any).prices,
            main_image: (item.product as any).main_image
          }
        };
      });

      setCartItems(transformedItems);
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setError('Error al cargar los productos del carrito');
    } finally {
      setLoading(false);
    }
  };

  const getProductPrice = (item: CartItem) => {
    console.log(`=== CÁLCULO DE PRECIO PARA ${item.product.name} ===`);
    console.log(`Cantidad en carrito: ${item.quantity}`);
    console.log(`Tipo de precio del usuario: ${userPriceType}`);
    console.log(`Tipo de precio del item: ${item.price_type}`);
    console.log(`Precio base del producto: ${item.product.base_price}`);
    
    // Verificar si los precios están disponibles y son un array
    if (!item.product.prices || !Array.isArray(item.product.prices) || item.product.prices.length === 0) {
      console.log(`No hay precios definidos, usando precio base: ${item.product.base_price}`);
      return item.product.base_price;
    }
    
    console.log(`Precios disponibles:`, JSON.stringify(item.product.prices, null, 2));
    
    // Usar el tipo de precio guardado en el item si existe
    const effectivePriceType = item.price_type || userPriceType || 'contado';
    
    try {
      // Si el tipo de precio es "mayorista"
      if (effectivePriceType === 'mayorista') {
        // Buscar precios de mayorista
        const mayoristaPrice = item.product.prices.find(p => p.price_type === 'mayorista');
        console.log(`Precio mayorista encontrado:`, mayoristaPrice);
        
        if (mayoristaPrice && item.quantity >= mayoristaPrice.min_quantity) {
          console.log(`Usando precio mayorista (${mayoristaPrice.min_quantity}+): ${mayoristaPrice.price}`);
          return mayoristaPrice.price;
        }
      }
      
      // Filtrar precios por tipo de usuario
      const userPrices = item.product.prices.filter(p => p.price_type === effectivePriceType);
      console.log(`Precios filtrados por tipo ${effectivePriceType}:`, JSON.stringify(userPrices, null, 2));
      
      if (userPrices.length === 0) {
        console.log(`No se encontraron precios para tipo ${effectivePriceType}, usando precio base: ${item.product.base_price}`);
        return item.product.base_price;
      }
      
      // Encontrar el precio aplicable según la cantidad
      const validPrices = userPrices
        .filter(price => item.quantity >= price.min_quantity)
        .sort((a, b) => b.min_quantity - a.min_quantity); // Ordenar de mayor a menor cantidad mínima
      
      console.log(`Precios válidos según cantidad (${item.quantity}):`, JSON.stringify(validPrices, null, 2));
      
      if (validPrices.length > 0) {
        console.log(`Usando precio para cantidad ${validPrices[0].min_quantity}+: ${validPrices[0].price}`);
        return validPrices[0].price;
      }
      
      // Si no hay precios válidos según la cantidad, usar el precio base
      console.log(`No hay precios válidos según la cantidad, usando precio base: ${item.product.base_price}`);
      return item.product.base_price;
    } catch (error) {
      console.error('Error al calcular el precio:', error);
      return item.product.base_price;
    }
  };

  const getCartTotal = () => {
    return cartItems.reduce((sum, item) => 
      sum + (getProductPrice(item) * item.quantity), 0
    );
  };

  const handleCreateOrder = async () => {
    try {
      setIsSubmitting(true);
      setSuccessMessage(null);
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        navigate('/login');
        return;
      }

      // Calcular el total
      const total = getCartTotal();

      console.log('Creando pedido para el usuario:', user.id);
      console.log('Total del pedido:', total);

      // Crear el pedido
      const { data: newOrder, error: orderError } = await supabase
        .from('orders')
        .insert([{
          user_id: user.id,
          total_amount: total,
          status: 'pending',
          payment_status: 'pending',
          payment_method: 'card',
          delivery_method: 'delivery',
          email: user.email
        }])
        .select('id')
        .single();
        
      if (orderError) {
        console.error('Error al crear pedido:', orderError);
        throw orderError;
      }
      
      console.log('Pedido creado exitosamente con ID:', newOrder.id);
      
      // Ahora insertamos los items en la tabla order_items
      const orderItems = cartItems.map(item => ({
        order_id: newOrder.id,
        product_id: item.product_id,
        quantity: item.quantity,
        price: getProductPrice(item)
      }));
      
      const { error: orderItemsError } = await supabase
        .from('order_items')
        .insert(orderItems);
        
      if (orderItemsError) {
        console.error('Error al insertar items del pedido:', orderItemsError);
        throw orderItemsError;
      }
      
      console.log('Items del pedido insertados correctamente');

      // Limpiar el carrito
      try {
        console.log('Intentando limpiar el carrito para el usuario:', user.id);
        
        // Primero obtenemos el ID del carrito del usuario
        const { data: cart, error: cartError } = await supabase
          .from('cart')
          .select('id')
          .eq('user_id', user.id)
          .single();
  
        if (cartError) {
          console.error('Error al obtener el carrito para limpieza:', cartError);
          throw cartError;
        }
  
        if (!cart) {
          console.warn('No se encontró el carrito para limpiar');
        } else {
          console.log('Carrito encontrado, ID:', cart.id);
          
          // Eliminamos todos los items del carrito
          const { error: deleteError, count } = await supabase
            .from('cart_items')
            .delete({ count: 'exact' })
            .eq('cart_id', cart.id);
  
          if (deleteError) {
            console.error('Error al limpiar los items del carrito:', deleteError);
            throw deleteError;
          }
          
          console.log(`Carrito limpiado correctamente. ${count || 0} items eliminados.`);
          
          // Actualizar el estado del carrito usando el contexto
          await refreshCart();
        }
      } catch (cleanError) {
        console.error('Error durante la limpieza del carrito:', cleanError);
        // No detenemos el flujo por un error en la limpieza
      }

      // Enviar correo de confirmación
      try {
        console.log('Enviando correo de confirmación del pedido...');
        
        // Obtener información del usuario
        const { data: userData, error: userError } = await supabase
          .from('profiles')
          .select('name, email, phone, company')
          .eq('id', user.id)
          .single();
          
        if (userError) {
          console.error('Error al obtener datos del usuario para el correo:', userError);
          throw userError;
        }
        
        // Obtener los detalles de los productos para el correo
        const orderItemsForEmail = cartItems.map(item => ({
          name: item.product.name,
          quantity: item.quantity,
          price: getProductPrice(item)
        }));
        
        const formattedDate = format(new Date(), "d 'de' MMMM, yyyy", { locale: es });
        
        // Obtenemos la información de envío si está disponible
        const { data: shippingData, error: shippingError } = await supabase
          .from('shipping_addresses')
          .select('address, city, region')
          .eq('user_id', user.id)
          .eq('is_default', true)
          .maybeSingle();
          
        if (shippingError) {
          console.error('Error al obtener dirección de envío:', shippingError);
        }
        
        // URL base para el botón de ver pedido
        const appUrl = window.location.origin;
        
        const { success, error: emailError } = await sendOrderConfirmationEmail({
          orderId: newOrder.id,
          customerName: userData?.name || 'Cliente',
          customerEmail: userData?.email || user.email || '',
          totalAmount: total,
          orderDate: formattedDate,
          items: orderItemsForEmail,
          deliveryMethod: 'delivery', // O como corresponda según tu sistema
          viewOrderUrl: `${appUrl}/order/${newOrder.id}`, // URL para ver el pedido
          isReviewPending: true, // Indicar que el pedido está pendiente de revisión
          ...(shippingData && {
            shippingAddress: {
              address: shippingData.address,
              city: shippingData.city,
              region: shippingData.region
            }
          })
        }) as { success: boolean; error?: any; data?: string };
        
        if (success) {
          console.log('Correo de confirmación enviado exitosamente');
        } else if (emailError) {
          console.error('Error al enviar correo de confirmación:', emailError);
        }
      } catch (emailError) {
        console.error('Error al procesar el envío del correo:', emailError);
        // No interrumpimos el flujo por un error en el envío del correo
      }

      // Después de limpiar el carrito y antes de la redirección
      setSuccessMessage('¡Pedido creado exitosamente! Redirigiendo...');
      
      // Añadir un pequeño retraso para mostrar el mensaje de éxito
      setTimeout(() => {
        // Redirigir a la página de confirmación
        navigate(`/order/${newOrder.id}/confirmation`);
      }, 1500);
      
    } catch (err) {
      console.error('Error creating order:', err);
      setError('Error al crear el pedido');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || cartItems.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Error</h2>
          <p className="text-gray-600 mb-8">{error || 'No hay productos en el carrito'}</p>
          <button
            onClick={() => navigate('/')}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
          >
            Volver al inicio
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      {successMessage && (
        <div className="fixed inset-x-0 top-4 flex justify-center z-50">
          <div className="bg-green-100 border border-green-200 text-green-800 px-4 py-3 rounded-lg shadow-md flex items-center gap-2 max-w-md">
            <CheckCircle className="h-5 w-5 text-green-500" />
            <span>{successMessage}</span>
          </div>
        </div>
      )}
      
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Resumen del pedido */}
          <div className="md:col-span-2 space-y-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Resumen del Pedido</h2>
              <div className="space-y-4">
                {cartItems.map((item) => (
                  <div key={item.id} className="flex items-center gap-4 py-4 border-b last:border-0">
                    <img
                      src={item.product.main_image}
                      alt={item.product.name}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    <div className="flex-1">
                      <h3 className="font-medium">{item.product.name}</h3>
                      <p className="text-sm text-gray-600">Cantidad: {item.quantity}</p>
                      <p className="text-sm font-medium">Q {(getProductPrice(item) * item.quantity).toFixed(2)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Información Importante</h2>
              <div className="space-y-4 text-sm text-gray-600">
                <div className="flex items-center gap-3">
                  <Package className="w-5 h-5 text-blue-600" />
                  <p>Nuestro equipo verificará la disponibilidad de los productos solicitados.</p>
                </div>
                <div className="flex items-center gap-3">
                  <Clock className="w-5 h-5 text-blue-600" />
                  <p>El tiempo de revisión es de aproximadamente 24 horas hábiles.</p>
                </div>
                <div className="flex items-center gap-3">
                  <Truck className="w-5 h-5 text-blue-600" />
                  <p>Los costos de envío se calcularán una vez aprobado el pedido.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Total y acciones */}
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Total del Pedido</h2>
              <div className="space-y-3">
                <div className="flex justify-between">
                  <span className="text-gray-600">Subtotal</span>
                  <span>Q {getCartTotal().toFixed(2)}</span>
                </div>
                <div className="flex justify-between pt-4 border-t text-base font-medium">
                  <span>Total</span>
                  <div className="text-right">
                    <div>Q {getCartTotal().toFixed(2)}</div>
                    <div className="text-xs text-gray-500">
                      Incluye Q {(getCartTotal() * 0.12).toFixed(2)} de impuestos
                    </div>
                  </div>
                </div>
              </div>

              <button
                onClick={handleCreateOrder}
                disabled={isSubmitting}
                className="w-full mt-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {isSubmitting ? (
                  <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Procesando...
                  </>
                ) : (
                  'Crear Pedido'
                )}
              </button>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <Link
                to="/"
                className="flex items-center justify-center gap-2 text-blue-600 hover:text-blue-700"
              >
                <span>Continuar comprando</span>
                <ArrowRight className="w-4 h-4" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 