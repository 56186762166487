import { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronRight, Package, ShoppingCart } from 'lucide-react';
import { supabase } from '../lib/supabase';
import SEO from '../components/SEO';
import { useAuth } from '../contexts/AuthContext';
import { usePriceType } from '../hooks/usePriceType';

// Tipos
interface Category {
  id: string;
  name: string;
  slug: string;
  description: string;
  image: string;
  parent_id?: string;
  subcategories?: Category[];
  parent?: Category;
}

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number;
  main_image: string;
  image?: string;
  stock: number;
  min_order: number;
  status: 'active' | 'inactive' | 'out_of_stock';
  prices?: {
    min_quantity: number;
    price: number;
    price_type?: string;
  }[];
}

// Componentes auxiliares
const Breadcrumbs = ({ category, slug, subSlug }: { category: Category; slug: string; subSlug?: string }) => (
  <nav className="flex items-center gap-2 text-sm text-gray-500 mb-8">
    <Link to="/" className="hover:text-gray-900">
      Inicio
    </Link>
    <ChevronRight className="w-4 h-4" />
    {subSlug ? (
      <>
        <Link to={`/category/${slug}`} className="hover:text-gray-900">
          {category.name}
        </Link>
        <ChevronRight className="w-4 h-4" />
        <span className="text-gray-900">{category.name}</span>
      </>
    ) : (
      <span className="text-gray-900">{category.name}</span>
    )}
  </nav>
);

const CategoryHeader = ({ category, getImageUrl }: { category: Category; getImageUrl: (path: string | null) => string }) => (
  <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-8">
    <div className="relative h-40 bg-gradient-to-r from-blue-500 to-indigo-600">
      {/* Banner decorativo */}
      <div className="absolute inset-0 opacity-20">
        <img 
          src={getImageUrl(category.image)}
          alt=""
          className="w-full h-full object-cover filter blur-sm"
        />
      </div>
    </div>
    
    <div className="flex flex-col md:flex-row px-6 py-4 -mt-16 relative">
      {/* Imagen de perfil */}
      <div className="w-32 h-32 md:w-40 md:h-40 rounded-lg border-4 border-white overflow-hidden shadow-md bg-white mx-auto md:mx-0">
        <img
          src={getImageUrl(category.image)}
          alt={category.name}
          className="w-full h-full object-cover"
          onError={(e) => {
            (e.target as HTMLImageElement).src = 'https://placehold.co/400x400?text=Categoría:+' + encodeURIComponent(category.name);
          }}
        />
      </div>
      
      {/* Información de la categoría */}
      <div className="md:ml-6 mt-4 md:mt-20 text-center md:text-left flex-1">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-1">
          {category.name}
        </h1>
        <p className="text-gray-600 mb-3 max-w-3xl">
          {category.description || `Explora nuestra colección de productos en la categoría ${category.name}`}
        </p>
        
        {/* Estadísticas */}
        <div className="flex flex-wrap justify-center md:justify-start gap-4 text-sm">
          <div className="px-3 py-1 bg-gray-100 rounded-full text-gray-600 flex items-center">
            <span className="font-medium">{category.subcategories?.length || 0}</span>
            <span className="ml-1">Subcategorías</span>
          </div>
          <div className="px-3 py-1 bg-blue-50 rounded-full text-blue-600 flex items-center">
            <ShoppingCart className="w-4 h-4 mr-1" />
            <span>Productos disponibles</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const SubcategoryGrid = ({ 
  subcategories, 
  slug, 
  getImageUrl 
}: { 
  subcategories: Category[]; 
  slug: string; 
  getImageUrl: (path: string | null) => string 
}) => (
  <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12">
    {subcategories.map((subcategory) => (
      <Link
        key={subcategory.id}
        to={`/category/${slug}/${subcategory.slug}`}
        className="group"
      >
        <div className="relative h-48 rounded-lg overflow-hidden mb-2">
          <img
            src={getImageUrl(subcategory.image)}
            alt={subcategory.name}
            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
            onError={(e) => {
              (e.target as HTMLImageElement).src = 'https://placehold.co/400x400?text=Subcategoría:+' + encodeURIComponent(subcategory.name);
            }}
          />
          <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
            <h3 className="text-xl font-semibold text-white">{subcategory.name}</h3>
          </div>
        </div>
        <p className="text-sm text-gray-600">{subcategory.description}</p>
      </Link>
    ))}
  </div>
);

const ProductCard = ({ 
  product, 
  user, 
  productPrice, 
  getImageUrl 
}: { 
  product: Product; 
  user: any;
  productPrice: number; 
  getImageUrl: (path: string | null) => string 
}) => (
  <Link
    key={product.id}
    to={`/product/${product.id}`}
    className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group"
  >
    <div className="relative bg-white">
      <div className="w-full h-48 flex items-center justify-center overflow-hidden">
        <img
          src={product.main_image || (product.image ? getImageUrl(product.image) : 'https://placehold.co/400x400?text=No+Image')}
          alt={product.name}
          className="max-w-full max-h-full object-contain p-2 group-hover:scale-105 transition-transform duration-300"
          onError={(e) => {
            (e.target as HTMLImageElement).src = 'https://placehold.co/400x400?text=No+Image';
          }}
        />
      </div>
    </div>
    <div className="p-4">
      <h3 className="text-sm text-gray-900 font-medium line-clamp-2 group-hover:text-blue-600 transition-colors">
        {product.name}
      </h3>
      <div className="mt-2 flex items-center justify-between">
        <div className="space-y-1">
          <div className="flex items-baseline gap-2">
            {user ? (
              <span className="text-lg font-semibold text-blue-600">
                Q {productPrice.toFixed(2)}
              </span>
            ) : (
              <span className="text-sm italic text-gray-600 bg-gray-100 px-2 py-1 rounded">
                Inicia sesión para ver precios
              </span>
            )}
          </div>
        </div>
      </div>
      {user ? (
        <button className="w-full mt-4 bg-blue-600 text-white px-3 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center justify-center gap-2">
          <ShoppingCart className="w-4 h-4" />
          Añadir al carrito
        </button>
      ) : (
        <Link
          to="/login"
          className="w-full mt-4 bg-gray-600 text-white px-3 py-2 rounded-lg text-sm font-medium hover:bg-gray-700 transition-colors flex items-center justify-center gap-2"
        >
          Iniciar sesión para comprar
        </Link>
      )}
    </div>
  </Link>
);

const EmptyProductsView = () => (
  <div className="text-center py-16">
    <div className="text-gray-400 mb-4">
      <Package className="w-16 h-16 mx-auto" />
    </div>
    <h3 className="text-xl font-medium text-gray-700 mb-2">No se encontraron productos</h3>
    <p className="text-gray-500 mb-6">
      No hay productos disponibles en esta categoría en este momento.
    </p>
    <Link
      to="/catalog"
      className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
    >
      Ver catálogo completo
    </Link>
  </div>
);

const LoadingSkeleton = () => (
  <div className="max-w-7xl mx-auto px-4 py-8">
    <div className="animate-pulse">
      <div className="h-64 bg-gray-200 rounded-lg mb-8"></div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
        {[...Array(8)].map((_, i) => (
          <div key={i}>
            <div className="bg-gray-200 h-48 rounded-lg mb-4"></div>
            <div className="space-y-3">
              <div className="h-4 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

// Componente principal
export default function Category() {
  const { slug, subSlug } = useParams<{ slug: string; subSlug?: string }>();
  const [category, setCategory] = useState<Category | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();
  const { priceType } = usePriceType();
  const [productPrices, setProductPrices] = useState<Record<string, number>>({});

  // Función para obtener la URL pública de una imagen
  const getImageUrl = (imagePath: string | null) => {
    if (!imagePath) {
      return 'https://placehold.co/400x400?text=No+Image';
    }
    
    if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
      return imagePath;
    }
    
    if (imagePath.startsWith('/')) {
      return window.location.origin + imagePath;
    }
    
    try {
      if (imagePath.includes('storage/v1/object/public/')) {
        return `${import.meta.env.VITE_SUPABASE_URL}${imagePath}`;
      }
      
      const { data } = supabase.storage.from('public').getPublicUrl(imagePath);
      return data?.publicUrl || 'https://placehold.co/400x400?text=No+Image';
    } catch (error) {
      console.error('Error al obtener URL pública:', error);
      return 'https://placehold.co/400x400?text=No+Image';
    }
  };

  const fetchProductPrices = async () => {
    if (!user || !priceType || !products.length) return;
    
    try {
      const productIds = products.map(p => p.id);
      
      const { data: prices, error: pricesError } = await supabase
        .from('product_prices')
        .select('product_id, price_type, min_quantity, price')
        .in('product_id', productIds)
        .eq('price_type', priceType);
      
      if (pricesError) throw pricesError;
      
      const pricesMap: Record<string, number> = {};
      
      if (prices && prices.length > 0) {
        // Agrupar precios por producto
        const pricesByProduct = prices.reduce((acc, price) => {
          if (!acc[price.product_id]) {
            acc[price.product_id] = [];
          }
          acc[price.product_id].push(price);
          return acc;
        }, {} as Record<string, any[]>);
        
        // Obtener el precio mínimo para cada producto
        Object.entries(pricesByProduct).forEach(([productId, productPrices]) => {
          const minPriceForType = productPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
          pricesMap[productId] = minPriceForType.price;
        });
      }
      
      setProductPrices(pricesMap);
    } catch (err) {
      console.error('Error fetching product prices:', err);
    }
  };

  const fetchCategoryAndProducts = async () => {
    try {
      // 1. Obtener la categoría
      const { data: categoryData, error: categoryError } = await supabase
        .from('categories')
        .select('*')
        .eq('slug', subSlug || slug)
        .single();

      if (categoryError) throw categoryError;
      
      // 2. Obtener subcategorías si existen
      if (categoryData) {
        const { data: subcategoriesData, error: subcategoriesError } = await supabase
          .from('categories')
          .select('id, name, slug, description, image')
          .eq('parent_id', categoryData.id);
          
        if (subcategoriesError) throw subcategoriesError;
        categoryData.subcategories = subcategoriesData || [];
      }
      
      setCategory(categoryData);

      // 3. Obtener productos
      if (categoryData) {
        try {
          const { data: productsData, error: productsError } = await supabase
            .from('products')
            .select(`
              id, name, description, base_price, main_image, image,
              stock, min_order, status,
              prices:product_prices(min_quantity, price, price_type)
            `)
            .eq('category_id', categoryData.id)
            .eq('status', 'active')
            .order('created_at', { ascending: false });

          if (productsError) throw productsError;
          
          if (productsData && productsData.length > 0) {
            const processedProducts = productsData.map(product => ({
              ...product,
              main_image: product.main_image || product.image || 'https://placehold.co/400x400?text=No+Image'
            }));
            
            setProducts(processedProducts);
          } else {
            setProducts([]);
          }
        } catch (error) {
          // Consulta alternativa más simple si la primera falla
          const { data: simpleProductsData, error: simpleError } = await supabase
            .from('products')
            .select('*')
            .eq('category_id', categoryData.id)
            .eq('status', 'active')
            .order('created_at', { ascending: false });
            
          if (simpleError) throw simpleError;
          
          if (simpleProductsData && simpleProductsData.length > 0) {
            // Obtener precios por separado
            const productIds = simpleProductsData.map(p => p.id);
            const { data: pricesData } = await supabase
              .from('product_prices')
              .select('*')
              .in('product_id', productIds);
              
            // Asignar precios a cada producto
            const productsWithPrices = simpleProductsData.map(product => ({
              ...product,
              main_image: product.main_image || product.image || 'https://placehold.co/400x400?text=No+Image',
              prices: pricesData?.filter(p => p.product_id === product.id) || []
            }));
            
            setProducts(productsWithPrices);
          } else {
            setProducts([]);
          }
        }
      }
    } catch (error) {
      console.error('Error general al obtener datos:', error);
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };

  // Efecto para cargar datos iniciales
  useEffect(() => {
    fetchCategoryAndProducts();
  }, [slug, subSlug]);

  // Efecto para actualizar precios cuando cambien las dependencias
  useEffect(() => {
    if (user && priceType && products.length > 0) {
      fetchProductPrices();
    }
  }, [user, priceType, products]);

  // Memorizar los precios de los productos para evitar cálculos innecesarios
  const productPricesWithDefault = useMemo(() => {
    return products.reduce((acc, product) => {
      acc[product.id] = productPrices[product.id] || product.base_price;
      return acc;
    }, {} as Record<string, number>);
  }, [products, productPrices]);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!category) {
    return null;
  }

  return (
    <>
      {category && (
        <SEO
          title={category.name}
          description={`${category.description || `Productos de ${category.name}`}. Encuentra los mejores precios al por mayor en Guatemala.`}
          keywords={[
            category.name,
            'mayorista',
            'Guatemala',
            'productos al por mayor',
            category.parent?.name || '',
            ...(category.subcategories?.map(sub => sub.name) || [])
          ].filter(Boolean)}
          url={`/category/${category.slug}`}
          type="website"
        />
      )}
      <div className="max-w-7xl mx-auto px-4 py-8">
        <Breadcrumbs category={category} slug={slug!} subSlug={subSlug} />
        <CategoryHeader category={category} getImageUrl={getImageUrl} />

        {/* Mostrar subcategorías si estamos en una categoría principal y tiene subcategorías */}
        {!subSlug && category.subcategories?.length ? (
          <SubcategoryGrid 
            subcategories={category.subcategories} 
            slug={slug!} 
            getImageUrl={getImageUrl} 
          />
        ) : null}

        {/* Mostrar productos o mensaje de categoría vacía */}
        {products.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                user={user}
                productPrice={productPricesWithDefault[product.id]}
                getImageUrl={getImageUrl}
              />
            ))}
          </div>
        ) : (
          <EmptyProductsView />
        )}
      </div>
    </>
  );
}