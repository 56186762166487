import { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { CheckCircle, Loader2, Clock, ArrowRight, Package, Truck } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Order {
  id: string;
  total_amount: number;
  status: 'pending' | 'processing' | 'shipped' | 'delivered' | 'cancelled';
  payment_status: 'pending' | 'paid' | 'failed' | 'refunded';
  payment_method: string;
  delivery_method: string;
  items: {
    product_id: string;
    quantity: number;
    price: number;
    product: {
      name: string;
      main_image: string;
    };
  }[];
  created_at: string;
}

export default function OrderConfirmation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchOrder();
  }, [id]);

  const fetchOrder = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/login');
        return;
      }

      const { data, error } = await supabase
        .from('orders')
        .select(`
          id,
          total_amount,
          status,
          payment_status,
          payment_method,
          delivery_method,
          created_at,
          items:order_items(
            product_id,
            quantity,
            price,
            product:products(name, main_image)
          )
        `)
        .eq('id', id)
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      
      // Transformar los datos para que coincidan con la interfaz
      const transformedData: Order = {
        ...data,
        items: data.items.map((item: any) => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          product: {
            name: item.product.name,
            main_image: item.product.main_image
          }
        }))
      };

      setOrder(transformedData);
    } catch (error) {
      console.error('Error fetching order:', error);
      setError('Error al cargar el pedido');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitOrder = async () => {
    try {
      setIsSubmitting(true);
      const { error } = await supabase
        .from('orders')
        .update({
          status: 'pending',
          payment_status: 'pending'
        })
        .eq('id', order?.id);

      if (error) throw error;

      // Recargar el pedido para mostrar el nuevo estado
      await fetchOrder();
    } catch (err) {
      console.error('Error submitting order:', err);
      setError('Error al colocar el pedido');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  if (error || !order) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Error</h2>
          <p className="text-gray-600 mb-8">{error || 'Pedido no encontrado'}</p>
          <button
            onClick={() => navigate('/')}
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700"
          >
            Volver al inicio
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Resumen del pedido */}
          <div className="md:col-span-2 space-y-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Resumen del Pedido</h2>
              <div className="space-y-4">
                {order.items.map((item) => (
                  <div key={item.product_id} className="flex items-center gap-4 py-4 border-b last:border-0">
                    <img
                      src={item.product.main_image}
                      alt={item.product.name}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                    <div className="flex-1">
                      <h3 className="font-medium">{item.product.name}</h3>
                      <p className="text-sm text-gray-600">Cantidad: {item.quantity}</p>
                      <p className="text-sm font-medium">Q {(item.price * item.quantity).toFixed(2)}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Información Importante</h2>
              <div className="space-y-4 text-sm text-gray-600">
                <div className="flex items-center gap-3">
                  <Package className="w-5 h-5 text-blue-600" />
                  <p>Nuestro equipo verificará la disponibilidad de los productos solicitados.</p>
                </div>
                <div className="flex items-center gap-3">
                  <Clock className="w-5 h-5 text-blue-600" />
                  <p>El tiempo de revisión es de aproximadamente 24 horas hábiles.</p>
                </div>
                <div className="flex items-center gap-3">
                  <Truck className="w-5 h-5 text-blue-600" />
                  <p>Los costos de envío se calcularán una vez aprobado el pedido.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Total y acciones */}
          <div className="space-y-6">
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold mb-4">Total del Pedido</h2>
              <div className="space-y-3">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Subtotal</span>
                  <span>Q {order.total_amount.toFixed(2)}</span>
                </div>
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Envío</span>
                  <span className="text-sm text-gray-600">Por calcular</span>
                </div>
                <div className="border-t pt-3 mt-3">
                  <div className="flex justify-between font-medium">
                    <span>Total Estimado</span>
                    <span>Q {order.total_amount.toFixed(2)}</span>
                  </div>
                  <p className="text-xs text-gray-500 mt-1">
                    *El total final puede variar según costos de envío
                  </p>
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmitOrder}
              disabled={isSubmitting || order.status === 'pending'}
              className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
            >
              {isSubmitting ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Procesando...
                </>
              ) : order.status === 'pending' ? (
                <>
                  <CheckCircle className="w-5 h-5" />
                  Pedido Enviado
                </>
              ) : (
                'Colocar Pedido'
              )}
            </button>

            {order.status === 'pending' && (
              <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 text-sm text-yellow-800">
                <p className="font-medium">Pedido en revisión</p>
                <p className="mt-1">
                  Tu pedido está siendo revisado por nuestro equipo. Te notificaremos cuando esté aprobado para proceder con el pago.
                </p>
              </div>
            )}

            <Link
              to="/"
              className="block w-full bg-gray-100 text-gray-700 px-6 py-3 rounded-lg text-center hover:bg-gray-200 font-medium"
            >
              Seguir Comprando
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
} 