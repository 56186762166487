import { Helmet } from 'react-helmet-async';

export default function Shipping() {
  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <Helmet>
        <title>Política de Envíos | Disolgua</title>
        <meta name="description" content="Política de envíos de Disolgua" />
      </Helmet>
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Política de Envíos</h1>
      
      <div className="prose prose-lg max-w-none">
        <p className="mb-4">
          En Disolgua nos comprometemos a entregar sus productos de manera eficiente y segura. 
          A continuación, encontrará información detallada sobre nuestros servicios de envío.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Áreas de cobertura</h2>
        <p className="mb-4">
          Actualmente realizamos envíos a todo Guatemala. Las áreas de entrega se dividen en:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Ciudad de Guatemala y áreas metropolitanas</li>
          <li>Cabeceras departamentales</li>
          <li>Municipios y áreas rurales</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Tiempos de entrega</h2>
        <p className="mb-4">
          Los tiempos de entrega estimados son:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Ciudad de Guatemala y áreas metropolitanas:</strong> 1-2 días hábiles</li>
          <li><strong>Cabeceras departamentales:</strong> 2-3 días hábiles</li>
          <li><strong>Municipios y áreas rurales:</strong> 3-5 días hábiles</li>
        </ul>
        <p className="mb-4">
          Estos tiempos son estimados y pueden variar según la disponibilidad del producto, 
          condiciones climáticas, o situaciones fuera de nuestro control.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Costos de envío</h2>
        <p className="mb-4">
          Los costos de envío se calculan en función de:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Ubicación de entrega</li>
          <li>Peso y dimensiones del paquete</li>
          <li>Valor total de la compra</li>
        </ul>
        <p className="mb-4">
          El costo exacto de envío se mostrará durante el proceso de compra antes de finalizar su pedido.
        </p>
        <p className="mb-4">
          <strong>¡Envío gratis!</strong> En pedidos superiores a Q500 dentro de la Ciudad de Guatemala y 
          áreas metropolitanas.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Seguimiento de pedidos</h2>
        <p className="mb-4">
          Una vez que su pedido haya sido enviado, recibirá un correo electrónico con la información de 
          seguimiento. También puede verificar el estado de su pedido iniciando sesión en su cuenta en 
          nuestro sitio web.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Recepción de pedidos</h2>
        <p className="mb-4">
          Al recibir su pedido, le recomendamos:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Verificar que el empaque esté en buenas condiciones</li>
          <li>Revisar que todos los productos estén incluidos y en buen estado</li>
          <li>Reportar cualquier anomalía dentro de las 24 horas siguientes a la recepción</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Pedidos no entregados</h2>
        <p className="mb-4">
          Si no hay nadie disponible para recibir el pedido, nuestro transportista dejará una notificación 
          y realizará un segundo intento de entrega. Después de dos intentos fallidos, el paquete será 
          devuelto a nuestras instalaciones y nos pondremos en contacto con usted para coordinar una nueva entrega.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Contacto</h2>
        <p className="mb-4">
          Si tiene alguna pregunta sobre nuestras políticas de envío, póngase en contacto con nuestro 
          equipo de atención al cliente:
        </p>
        <p className="mb-4">
          <strong>Correo electrónico:</strong> info@disolgua.com<br />
          <strong>Teléfono:</strong> 2238-0714
        </p>
      </div>
    </div>
  );
} 