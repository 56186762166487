import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, ArrowLeft } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { Helmet } from 'react-helmet-async';

interface Category {
  id: string;
  name: string;
  slug: string;
  description: string;
  image: string;
  parent_id: string | null;
  order: number;
  subcategories?: Category[];
}

export default function Categories() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      
      // Primero obtenemos todas las categorías
      const { data: allCategories, error } = await supabase
        .from('categories')
        .select('*')
        .order('order', { ascending: true });

      if (error) throw error;

      // Organizamos las categorías en una estructura jerárquica
      const mainCategories = allCategories
        ?.filter(cat => cat.parent_id === null)
        .map(mainCat => ({
          ...mainCat,
          subcategories: allCategories?.filter(subCat => subCat.parent_id === mainCat.id)
        })) || [];

      setCategories(mainCategories);
    } catch (err) {
      console.error('Error fetching categories:', err);
      setError(err instanceof Error ? err.message : 'Error al cargar las categorías');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 w-48 bg-gray-200 rounded mb-4"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="bg-white rounded-lg p-4 shadow-sm">
                <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
                <div className="space-y-2">
                  {[...Array(4)].map((_, j) => (
                    <div key={j} className="h-4 bg-gray-200 rounded w-full"></div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-red-600">
          {error}
        </div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Categorías | Disolgua</title>
        <meta name="description" content="Explora todas las categorías de productos disponibles en Disolgua" />
      </Helmet>
      
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="mb-6">
          <Link to="/" className="inline-flex items-center text-blue-600 hover:text-blue-800">
            <ArrowLeft className="w-4 h-4 mr-1" />
            Volver al inicio
          </Link>
          <h1 className="text-2xl font-bold text-gray-900 mt-4">Todas las Categorías</h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map((category) => (
            <div key={category.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="relative h-48">
                <img 
                  src={category.image} 
                  alt={category.name} 
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent flex items-end">
                  <h2 className="text-xl font-semibold text-white p-4">{category.name}</h2>
                </div>
              </div>
              
              <div className="p-4">
                {category.description && (
                  <p className="text-gray-600 text-sm mb-4">{category.description}</p>
                )}
                
                <Link 
                  to={`/category/${category.slug}`} 
                  className="text-blue-600 hover:text-blue-800 font-medium inline-flex items-center"
                >
                  Ver todos los productos
                  <ChevronRight className="w-4 h-4 ml-1" />
                </Link>
                
                {category.subcategories && category.subcategories.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Subcategorías:</h3>
                    <ul className="space-y-1">
                      {category.subcategories.map((subcat) => (
                        <li key={subcat.id}>
                          <Link 
                            to={`/category/${category.slug}/${subcat.slug}`}
                            className="text-gray-600 hover:text-blue-600 text-sm inline-flex items-center"
                          >
                            <ChevronRight className="w-3 h-3 mr-1" />
                            {subcat.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
} 