import { Resend } from 'resend';
import { withConnectionCheck } from './supabase';

// Crear cliente de Resend con la API key
const resendApiKey = import.meta.env.VITE_RESEND_API_KEY;

console.log('[DEBUG] Resend API Key disponible:', !!resendApiKey);

if (!resendApiKey) {
  console.error('Falta la variable de entorno VITE_RESEND_API_KEY');
}

// Determinar URL según el entorno
const isDev = import.meta.env.DEV;
const EDGE_FUNCTION_URL = isDev 
  ? 'http://localhost:54321/functions/v1/process-email-queue'
  : 'https://taheqwabfahvzniabmhh.supabase.co/functions/v1/process-email-queue';

console.log(`[DEBUG] Usando URL de función Edge: ${EDGE_FUNCTION_URL}, entorno: ${isDev ? 'desarrollo' : 'producción'}`);

// Verificar si la función Edge está disponible
let isEdgeFunctionAvailable = false;

// Verificar disponibilidad de la función Edge al cargar el servicio
async function checkEdgeFunctionAvailability() {
  try {
    console.log('[DEBUG] Verificando disponibilidad de la función Edge en', EDGE_FUNCTION_URL);
    
    // Obtener token de anon key para autenticación
    const anonKey = import.meta.env.VITE_SUPABASE_ANON_KEY || '';
    console.log('[DEBUG] Anon key disponible:', !!anonKey);
    
    const response = await fetch(EDGE_FUNCTION_URL, {
      method: 'OPTIONS',
      headers: {
        'Content-Type': 'application/json',
        // Añadir encabezados específicos de Supabase para autenticación
        'apikey': anonKey,
        'Authorization': `Bearer ${anonKey}`,
      },
      // Usar el modo adecuado según el entorno
      mode: isDev ? 'no-cors' : 'cors',
      credentials: isDev ? 'omit' : 'same-origin'
    });
    
    // En modo 'no-cors' (desarrollo o producción), asumimos disponibilidad
    if (isDev) {
      console.log('[DEBUG] Solicitud enviada en modo no-cors (desarrollo), asumiendo disponibilidad');
    } else {
      console.log('[DEBUG] Solicitud enviada en modo no-cors (producción), asumiendo disponibilidad');
    }
    isEdgeFunctionAvailable = true;
    return true;
  } catch (error) {
    console.error('[ERROR] Error al verificar disponibilidad de la función Edge:', error);
    
    // Asumir que la función está disponible si es un error en desarrollo
    if (isDev) {
      console.log('[INFO] En modo desarrollo, asumiendo que la función Edge está disponible a pesar del error');
      isEdgeFunctionAvailable = true;
      return true;
    }
    
    isEdgeFunctionAvailable = false;
    return false;
  }
}

// Verificar al inicio
checkEdgeFunctionAvailability().catch(console.error);

// En lugar de usar el cliente de Resend directamente, implementamos una versión
// que use fetch con nuestro proxy
const resendClient = {
  emails: {
    async send(options: any) {
      try {
        console.log('[DEBUG] Enviando correo a través del Edge Function:', options);
        
        // En modo desarrollo, simular el envío de correo
        if (isDev) {
          console.log('[INFO] Modo desarrollo detectado - simulando envío de correo');
          console.log('[INFO] En un entorno de producción, este correo sería enviado a:', options.to);
          console.log('[INFO] Asunto:', options.subject);
          console.log('[INFO] Contenido HTML:', options.html ? 'Contenido HTML incluido' : 'Sin contenido HTML');
          
          // Mostrar instrucciones para probar correos en desarrollo
          console.log('[INFO] Para probar el envío real de correos:');
          console.log('[INFO] 1. Ejecuta la función Edge localmente: supabase functions serve process-email-queue --no-verify-jwt');
          console.log('[INFO] 2. Asegúrate de que la API key de Resend esté configurada en .env.local');
          
          // Simular éxito en desarrollo
          return { data: { id: 'dev-simulated-id' }, error: null };
        }
        
        // En modo desarrollo, asumir que la función Edge está disponible incluso si hay errores CORS
        if (isDev) {
          console.log('[INFO] Modo desarrollo detectado - asumiendo que la función Edge está disponible');
          isEdgeFunctionAvailable = true;
        }
        // Verificar que la función Edge esté disponible
        else if (!isEdgeFunctionAvailable) {
          const isAvailable = await checkEdgeFunctionAvailability();
          if (!isAvailable) {
            console.error('[ERROR] La función Edge no está disponible');
            return { 
              data: null, 
              error: new Error('La función Edge no está disponible. Verifique que esté ejecutándose correctamente.') 
            };
          }
        }
        
        try {
          // Obtener token de anon key para autenticación si estamos en producción
          const anonKey = isDev ? '' : (import.meta.env.VITE_SUPABASE_ANON_KEY || '');
          
          if (!isDev && !anonKey) {
            console.error('[ERROR] No se encontró la clave anónima de Supabase en producción');
          }
          
          const response = await fetch(EDGE_FUNCTION_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Añadir encabezados específicos de Supabase para autenticación en producción
              ...(isDev ? {} : {
                'apikey': anonKey,
                'Authorization': `Bearer ${anonKey}`,
              })
            },
            // Usar el modo adecuado según el entorno
            mode: isDev ? 'no-cors' : 'cors',
            credentials: isDev ? 'omit' : 'same-origin',
            body: JSON.stringify(options),
          });
          
          // En modo no-cors, no podemos verificar el status ni leer el cuerpo de la respuesta
          // Asumimos éxito si la promesa se resuelve
          if (isDev) {
            console.log('[DEBUG] Solicitud en modo no-cors completada. Asumiendo éxito en entorno de desarrollo.');
            return { data: { id: 'dev-mode-id' }, error: null };
          }
          
          // En producción intentamos procesar la respuesta normalmente
          if (!response.ok) {
            let errorData;
            try {
              errorData = await response.json();
            } catch (jsonError) {
              errorData = { message: `Error al procesar la respuesta: ${response.statusText || 'Respuesta vacía'}` };
            }
            
            console.error('[DEBUG] Error en la respuesta del Edge Function:', errorData);
            return { data: null, error: errorData };
          }
          
          let data;
          try {
            data = await response.json();
          } catch (jsonError) {
            console.error('[ERROR] Error al analizar la respuesta JSON:', jsonError);
            data = { message: 'Correo enviado pero la respuesta no pudo ser procesada' };
          }
          
          console.log('[DEBUG] Respuesta exitosa del Edge Function:', data);
          return { data, error: null };
        } catch (fetchError) {
          // Capturar cualquier error específico de la operación fetch
          console.error('[ERROR] Error durante la operación fetch:', fetchError);
          
          // En desarrollo, asumimos que el correo se envió incluso si hay error de fetch
          if (isDev) {
            console.log('[INFO] En modo desarrollo, continuando a pesar del error de fetch');
            return { data: { id: 'dev-mode-id' }, error: null };
          }
          
          return { 
            data: null, 
            error: fetchError instanceof Error ? fetchError : new Error('Error de red al enviar correo')
          };
        }
      } catch (error) {
        console.error('[DEBUG] Error al enviar correo a través del Edge Function:', error);
        return { 
          data: null, 
          error: error instanceof Error ? error : new Error('Error desconocido') 
        };
      }
    }
  }
};

// Dirección de correo desde la que se enviarán los correos
const fromEmail = import.meta.env.VITE_FROM_EMAIL || 'no-reply@ejemplo.com';
console.log('[DEBUG] From Email:', fromEmail);

/**
 * Interfaz para el retorno de las funciones de correo
 */
export interface EmailResponse {
  success: boolean;
  error?: any;
  data?: string;
}

/**
 * Servicio para enviar correos electrónicos a través del proxy de Next.js
 * Este servicio evita llamadas directas a Supabase desde el cliente,
 * resolviendo problemas de CORS
 */

// Interfaces para los tipos de correos
interface OrderConfirmationEmailProps {
  orderId: string;
  customerName: string;
  customerEmail: string;
  totalAmount: number;
  orderDate: string;
  items: Array<{
    name: string;
    quantity: number;
    price: number;
  }>;
  shippingAddress?: {
    address: string;
    city: string;
    region: string;
  };
  deliveryMethod: 'delivery' | 'pickup';
  viewOrderUrl?: string; // URL para el botón de ver pedido
  isReviewPending?: boolean; // Indica si el pedido está pendiente de revisión
}

interface OrderStatusUpdateEmailProps {
  orderId: string;
  customerName: string;
  customerEmail: string;
  newStatus: string;
  statusMessage: string;
  trackingInfo?: {
    carrier: string;
    trackingNumber: string;
    trackingUrl: string;
  };
}

/**
 * Valida que una dirección de correo electrónico tenga un formato válido
 * @param email Dirección de correo a validar
 * @returns true si es válido, false si no
 */
function isValidEmail(email: string | null | undefined): boolean {
  if (!email) return false;
  
  // Expresión regular básica para validar formato de email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
}

/**
 * Envía un correo de confirmación cuando se crea un nuevo pedido
 * Utiliza directamente la función Edge de Supabase
 */
export async function sendOrderConfirmationEmail(props: OrderConfirmationEmailProps): Promise<EmailResponse> {
  try {
    console.log('[DEBUG] Enviando correo de confirmación a través de Edge Function');
    
    // Validar que el email sea válido
    if (!isValidEmail(props.customerEmail)) {
      console.error('[ERROR] Dirección de correo no válida:', props.customerEmail);
      return { 
        success: false, 
        error: 'La dirección de correo electrónico no es válida' 
      };
    }
    
    // En modo desarrollo, asumir que la función Edge está disponible incluso si hay errores CORS
    if (isDev) {
      console.log('[INFO] Modo desarrollo detectado - simulando envío de correo');
      console.log('[INFO] En un entorno de producción, este correo sería enviado a:', props.customerEmail);
      console.log('[INFO] Asunto: Confirmación de Pedido #' + props.orderId);
      console.log('[INFO] Datos del correo:', JSON.stringify(props, null, 2));
      
      // Mostrar instrucciones para probar correos en desarrollo
      console.log('[INFO] Para probar el envío real de correos:');
      console.log('[INFO] 1. Ejecuta la función Edge localmente: supabase functions serve process-email-queue --no-verify-jwt');
      console.log('[INFO] 2. Asegúrate de que la API key de Resend esté configurada en .env.local');
      
      // Simular éxito en desarrollo
      return { success: true, data: "Correo simulado en modo desarrollo" };
    }
    
    // Verificar que la función Edge esté disponible
    else if (!isEdgeFunctionAvailable) {
      const isAvailable = await checkEdgeFunctionAvailability();
      if (!isAvailable) {
        console.error('[ERROR] La función Edge no está disponible');
        return { 
          success: false, 
          error: 'La función Edge no está disponible. Verifique que esté ejecutándose correctamente.' 
        };
      }
    }
    
    // Verificar la conexión antes de intentar enviar el correo
    return await withConnectionCheck(
      async () => {
        try {
          // Obtener token de anon key para autenticación si estamos en producción
          const anonKey = isDev ? '' : (import.meta.env.VITE_SUPABASE_ANON_KEY || '');
          
          if (!isDev && !anonKey) {
            console.error('[ERROR] No se encontró la clave anónima de Supabase en producción');
          }
          
          // Llamada al Edge Function directamente
          const response = await fetch(EDGE_FUNCTION_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Añadir encabezados específicos de Supabase para autenticación en producción
              ...(isDev ? {} : {
                'apikey': anonKey,
                'Authorization': `Bearer ${anonKey}`,
              })
            },
            // Usar el modo adecuado según el entorno
            mode: isDev ? 'no-cors' : 'cors',
            credentials: isDev ? 'omit' : 'same-origin',
            body: JSON.stringify({
              type: 'order_confirmation',
              recipient: props.customerEmail,
              subject: `Confirmación de Pedido #${props.orderId}`,
              data: props
            })
          });
          
          // En modo no-cors, no podemos verificar el status ni leer el cuerpo de la respuesta
          // Asumimos éxito si la promesa se resuelve
          if (isDev) {
            console.log('[DEBUG] Solicitud en modo no-cors completada. Asumiendo éxito en entorno de desarrollo.');
            return { success: true, data: "Correo de confirmación enviado correctamente (modo no-cors)" };
          }
          
          // En producción intentamos procesar la respuesta normalmente
          if (!response.ok) {
            let errorData;
            try {
              errorData = await response.json();
            } catch (jsonError) {
              errorData = { message: `Error al procesar la respuesta: ${response.statusText || 'Respuesta vacía'}` };
            }
            
            console.error('Error al enviar correo de confirmación:', errorData);
            return { success: false, error: errorData.message || 'Error al enviar correo' };
          }
          
          let result;
          try {
            result = await response.json();
          } catch (jsonError) {
            console.warn('[WARN] Respuesta vacía o no válida del servidor:', jsonError);
            result = { message: 'Correo enviado pero la respuesta no pudo ser procesada' };
          }
          
          console.log('[DEBUG] Correo encolado para envío:', result);
          return { success: true, data: "Correo de confirmación enviado correctamente" };
        } catch (fetchError) {
          // Capturar cualquier error específico de la operación fetch
          console.error('[ERROR] Error durante la operación fetch:', fetchError);
          
          // En desarrollo, asumimos que el correo se envió incluso si hay error de fetch
          if (isDev) {
            console.log('[INFO] En modo desarrollo, continuando a pesar del error de fetch');
            return { success: true, data: "Correo procesado en modo desarrollo (posibles errores ignorados)" };
          }
          
          return { 
            success: false, 
            error: fetchError instanceof Error ? fetchError.message : 'Error de red al enviar correo' 
          };
        }
      },
      () => {
        console.error('[ERROR] No hay conexión a internet o el servidor Edge Function no está disponible');
        return { 
          success: false, 
          error: 'No se puede conectar con el servidor de correo. Verifique su conexión a internet o que la función Edge esté en ejecución.' 
        };
      }
    ) || { success: false, error: 'Falló la verificación de conexión' };
  } catch (error) {
    console.error('Error al encolar el correo:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : 'Error desconocido' 
    };
  }
}

/**
 * Envía un correo cuando se actualiza el estado de un pedido
 * Utiliza directamente la función Edge de Supabase
 */
export async function sendOrderStatusUpdateEmail(props: OrderStatusUpdateEmailProps): Promise<EmailResponse> {
  try {
    console.log('[DEBUG] Enviando correo de actualización a través de Edge Function');
    
    // Validar que el email sea válido
    if (!isValidEmail(props.customerEmail)) {
      console.error('[ERROR] Dirección de correo no válida:', props.customerEmail);
      return { 
        success: false, 
        error: 'La dirección de correo electrónico no es válida' 
      };
    }
    
    // En modo desarrollo, simular el envío de correo
    if (isDev) {
      console.log('[INFO] Modo desarrollo detectado - simulando envío de correo');
      console.log('[INFO] En un entorno de producción, este correo sería enviado a:', props.customerEmail);
      console.log('[INFO] Asunto: Actualización de Estado de Pedido #' + props.orderId);
      console.log('[INFO] Nuevo estado:', props.newStatus);
      console.log('[INFO] Mensaje:', props.statusMessage);
      
      // Mostrar instrucciones para probar correos en desarrollo
      console.log('[INFO] Para probar el envío real de correos:');
      console.log('[INFO] 1. Ejecuta la función Edge localmente: supabase functions serve process-email-queue --no-verify-jwt');
      console.log('[INFO] 2. Asegúrate de que la API key de Resend esté configurada en .env.local');
      
      // Simular éxito en desarrollo
      return { success: true, data: "Correo simulado en modo desarrollo" };
    }
    
    // Verificar que la función Edge esté disponible
    else if (!isEdgeFunctionAvailable) {
      const isAvailable = await checkEdgeFunctionAvailability();
      if (!isAvailable) {
        console.error('[ERROR] La función Edge no está disponible');
        return { 
          success: false, 
          error: 'La función Edge no está disponible. Verifique que esté ejecutándose correctamente.' 
        };
      }
    }
    
    // Verificar la conexión antes de intentar enviar el correo
    return await withConnectionCheck(
      async () => {
        try {
          // Obtener token de anon key para autenticación si estamos en producción
          const anonKey = isDev ? '' : (import.meta.env.VITE_SUPABASE_ANON_KEY || '');
          
          if (!isDev && !anonKey) {
            console.error('[ERROR] No se encontró la clave anónima de Supabase en producción');
          }
          
          // Llamada al Edge Function directamente
          const response = await fetch(EDGE_FUNCTION_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // Añadir encabezados específicos de Supabase para autenticación en producción
              ...(isDev ? {} : {
                'apikey': anonKey,
                'Authorization': `Bearer ${anonKey}`,
              })
            },
            // Usar el modo adecuado según el entorno
            mode: isDev ? 'no-cors' : 'cors',
            credentials: isDev ? 'omit' : 'same-origin',
            body: JSON.stringify({
              type: 'order_status_update',
              recipient: props.customerEmail,
              subject: `Actualización de Estado de Pedido #${props.orderId}`,
              data: props
            })
          });
          
          // En modo no-cors, no podemos verificar el status ni leer el cuerpo de la respuesta
          // Asumimos éxito si la promesa se resuelve
          if (isDev) {
            console.log('[DEBUG] Solicitud en modo no-cors completada. Asumiendo éxito en entorno de desarrollo.');
            return { success: true, data: "Correo de actualización enviado correctamente (modo no-cors)" };
          }
          
          // En producción intentamos procesar la respuesta normalmente
          if (!response.ok) {
            let errorData;
            try {
              errorData = await response.json();
            } catch (jsonError) {
              errorData = { message: `Error al procesar la respuesta: ${response.statusText || 'Respuesta vacía'}` };
            }
            
            console.error('Error al enviar correo de actualización:', errorData);
            return { success: false, error: errorData.message || 'Error al enviar correo' };
          }
          
          let result;
          try {
            result = await response.json();
          } catch (jsonError) {
            console.warn('[WARN] Respuesta vacía o no válida del servidor:', jsonError);
            result = { message: 'Correo enviado pero la respuesta no pudo ser procesada' };
          }
          
          console.log('[DEBUG] Correo de actualización encolado para envío:', result);
          return { success: true, data: "Correo de actualización enviado correctamente" };
        } catch (fetchError) {
          // Capturar cualquier error específico de la operación fetch
          console.error('[ERROR] Error durante la operación fetch:', fetchError);
          
          // En desarrollo, asumimos que el correo se envió incluso si hay error de fetch
          if (isDev) {
            console.log('[INFO] En modo desarrollo, continuando a pesar del error de fetch');
            return { success: true, data: "Correo procesado en modo desarrollo (posibles errores ignorados)" };
          }
          
          return { 
            success: false, 
            error: fetchError instanceof Error ? fetchError.message : 'Error de red al enviar correo' 
          };
        }
      },
      () => {
        console.error('[ERROR] No hay conexión a internet o el servidor no está disponible');
        return { 
          success: false, 
          error: 'No se puede conectar con el servidor de correo. Verifique su conexión a internet o que la función Edge esté en ejecución.' 
        };
      }
    ) || { success: false, error: 'Falló la verificación de conexión' };
  } catch (error) {
    console.error('Error al encolar el correo de actualización:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : 'Error desconocido' 
    };
  }
} 