import { useState, useEffect, useRef } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { Search, Sliders, ChevronDown, ShoppingCart, Filter, X, Grid, List, Heart, ArrowUpDown, Tag } from 'lucide-react';
import { supabase } from '../lib/supabase';
import CategoryMenu from '../components/CategoryMenu';
import SEO from '../components/SEO';
import SearchBar from '../components/SearchBar';
import { useAuth } from '../contexts/AuthContext';
import { usePriceType } from '../hooks/usePriceType';

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number;
  main_image: string;
  stock: number;
  min_order: number;
  status: 'active' | 'inactive' | 'out_of_stock';
  category: {
    name: string;
    slug: string;
  };
  prices?: {
    min_quantity: number;
    price: number;
  }[];
}

const sortOptions = [
  { label: 'Más relevantes', value: 'relevance' },
  { label: 'Menor precio', value: 'price_asc' },
  { label: 'Mayor precio', value: 'price_desc' },
  { label: 'Más vendidos', value: 'sales' },
  { label: 'Más nuevos', value: 'newest' },
];

const priceRanges = [
  { label: 'Menos de Q100', min: 0, max: 100 },
  { label: 'Q100 - Q500', min: 100, max: 500 },
  { label: 'Q500 - Q1000', min: 500, max: 1000 },
  { label: 'Más de Q1000', min: 1000, max: null },
];

export default function Catalog() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const [showFilters, setShowFilters] = useState(false);
  const [showSortOptions, setShowSortOptions] = useState(false);
  const sortRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { priceType, loading: priceTypeLoading } = usePriceType();
  const [productPrices, setProductPrices] = useState<Record<string, number>>({});

  const currentSort = searchParams.get('sort') || 'relevance';
  const currentPriceRange = searchParams.get('price_range');
  const searchQuery = searchParams.get('q') || '';
  const categorySlug = searchParams.get('category') || '';
  const inStockOnly = searchParams.get('in_stock') === 'true';

  useEffect(() => {
    fetchProducts();
  }, [currentSort, currentPriceRange, searchQuery, categorySlug, inStockOnly]);

  useEffect(() => {
    if (user && priceType && products.length > 0) {
      fetchProductPrices();
    }
  }, [user, priceType, products]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sortRef.current && !sortRef.current.contains(event.target as Node)) {
        setShowSortOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      let query = supabase
        .from('products')
        .select(`
          id,
          name,
          description,
          base_price,
          main_image,
          stock,
          min_order,
          status,
          category:categories(name, slug),
          prices:product_prices(min_quantity, price)
        `)
        .eq('status', 'active');

      if (categorySlug) {
        query = query.eq('category.slug', categorySlug);
      }

      if (searchQuery) {
        query = query.ilike('name', `%${searchQuery}%`);
      }

      if (currentPriceRange) {
        const [min, max] = currentPriceRange.split('-').map(Number);
        query = query.gte('base_price', min);
        if (max) query = query.lt('base_price', max);
      }

      if (inStockOnly) {
        query = query.gt('stock', 0);
      }

      switch (currentSort) {
        case 'price_asc':
          query = query.order('base_price', { ascending: true });
          break;
        case 'price_desc':
          query = query.order('base_price', { ascending: false });
          break;
        case 'newest':
          query = query.order('created_at', { ascending: false });
          break;
        default:
          query = query.order('created_at', { ascending: false });
      }

      const { data, error } = await query;

      if (error) throw error;
      setProducts(data?.map(item => ({
        ...item,
        category: item.category?.[0] || { name: '', slug: '' }
      })) || []);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProductPrices = async () => {
    if (!user || !priceType || !products.length) return;
    
    try {
      const productIds = products.map(p => p.id);
      
      const { data: prices, error: pricesError } = await supabase
        .from('product_prices')
        .select('product_id, price_type, min_quantity, price')
        .in('product_id', productIds)
        .eq('price_type', priceType);
      
      if (pricesError) throw pricesError;
      
      const pricesMap: Record<string, number> = {};
      
      if (prices && prices.length > 0) {
        const pricesByProduct: Record<string, any[]> = {};
        
        prices.forEach(price => {
          if (!pricesByProduct[price.product_id]) {
            pricesByProduct[price.product_id] = [];
          }
          pricesByProduct[price.product_id].push(price);
        });
        
        Object.keys(pricesByProduct).forEach(productId => {
          const productPrices = pricesByProduct[productId];
          const minPriceForType = productPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
          pricesMap[productId] = minPriceForType.price;
        });
      }
      
      setProductPrices(pricesMap);
    } catch (err) {
      console.error('Error fetching product prices:', err);
    }
  };

  const updateSort = (value: string) => {
    searchParams.set('sort', value);
    setSearchParams(searchParams);
    setShowSortOptions(false);
  };

  const updatePriceRange = (range: string) => {
    searchParams.set('price_range', range);
    setSearchParams(searchParams);
  };

  const toggleInStock = () => {
    if (inStockOnly) {
      searchParams.delete('in_stock');
    } else {
      searchParams.set('in_stock', 'true');
    }
    setSearchParams(searchParams);
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    if (searchQuery) newParams.set('q', searchQuery);
    if (categorySlug) newParams.set('category', categorySlug);
    setSearchParams(newParams);
  };

  const hasActiveFilters = currentPriceRange || inStockOnly;

  const getSortLabel = () => {
    const option = sortOptions.find(opt => opt.value === currentSort);
    return option ? option.label : 'Ordenar por';
  };

  const handleSearchChange = (query: string) => {
    searchParams.set('q', query);
    setSearchParams(searchParams);
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <SEO 
        title="Catálogo de Productos" 
        description="Explora nuestro catálogo completo de productos mayoristas. Encuentra peluches, tarjetas, globos y artículos para regalo al mejor precio en Guatemala."
        keywords={['catálogo', 'productos mayoristas', 'peluches', 'tarjetas', 'globos', 'regalos', 'Guatemala', 'precios mayoristas']}
        url="/catalog"
        type="website"
      />
      
      {/* Hero Banner */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white py-12">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Catálogo de Productos</h1>
          <p className="text-lg md:text-xl max-w-2xl opacity-90">
            Todos nuestros productos para tu negocio.
          </p>
        </div>
      </div>

      {/* Mobile Filters Drawer */}
      {showFilters && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 md:hidden">
          <div className="absolute right-0 top-0 h-full w-80 bg-white shadow-lg p-4 overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h3 className="font-semibold text-lg">Filtros</h3>
              <button 
                onClick={() => setShowFilters(false)}
                className="p-2 rounded-full hover:bg-gray-100"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <CategoryMenu />

            <div className="mt-6 border-t pt-4">
              <h4 className="font-medium mb-3">Rango de precios</h4>
              <div className="space-y-2">
                {priceRanges.map((range) => (
                  <label key={range.label} className="flex items-center">
                    <input
                      type="radio"
                      name="price_range"
                      value={`${range.min}-${range.max}`}
                      checked={currentPriceRange === `${range.min}-${range.max}`}
                      onChange={(e) => updatePriceRange(e.target.value)}
                      className="text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">
                      {range.label}
                    </span>
                  </label>
                ))}
              </div>
            </div>

            <div className="mt-6 border-t pt-4">
              <h4 className="font-medium mb-3">Disponibilidad</h4>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={inStockOnly}
                  onChange={toggleInStock}
                  className="text-blue-600 focus:ring-blue-500"
                />
                <span className="ml-2 text-sm text-gray-600">
                  Solo productos en stock
                </span>
              </label>
            </div>

            <div className="mt-8 flex gap-3">
              <button
                onClick={clearFilters}
                className="flex-1 py-2 border border-gray-300 rounded-lg text-sm font-medium hover:bg-gray-50"
              >
                Limpiar filtros
              </button>
              <button
                onClick={() => setShowFilters(false)}
                className="flex-1 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700"
              >
                Ver resultados
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar - Desktop */}
          <div className="hidden md:block w-64 flex-shrink-0 space-y-6">
            <CategoryMenu />

            <div className="bg-white rounded-lg shadow-sm p-5">
              <h3 className="font-semibold text-lg mb-4 flex items-center">
                <Filter className="w-5 h-5 mr-2 text-blue-600" />
                Filtros
              </h3>
              
              {hasActiveFilters && (
                <button
                  onClick={clearFilters}
                  className="mb-4 text-sm text-blue-600 hover:text-blue-800 flex items-center"
                >
                  <X className="w-4 h-4 mr-1" />
                  Limpiar filtros
                </button>
              )}
              
              <div className="space-y-6">
                <div>
                  <h4 className="font-medium mb-3 text-gray-700">Rango de precios</h4>
                  <div className="space-y-2">
                    {priceRanges.map((range) => (
                      <label key={range.label} className="flex items-center">
                        <input
                          type="radio"
                          name="price_range"
                          value={`${range.min}-${range.max}`}
                          checked={currentPriceRange === `${range.min}-${range.max}`}
                          onChange={(e) => updatePriceRange(e.target.value)}
                          className="text-blue-600 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-sm text-gray-600">
                          {range.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="font-medium mb-3 text-gray-700">Disponibilidad</h4>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={inStockOnly}
                      onChange={toggleInStock}
                      className="text-blue-600 focus:ring-blue-500"
                    />
                    <span className="ml-2 text-sm text-gray-600">
                      Solo productos en stock
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1">
            {/* Search and Filters Bar */}
            <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
              <div className="flex flex-col md:flex-row md:items-center gap-4">
                <div className="flex-1 relative">
                  <SearchBar 
                    variant="catalog"
                    initialValue={searchQuery}
                    onSearch={handleSearchChange}
                    showSuggestions={false}
                    placeholder="Buscar productos..."
                    className="w-full"
                  />
                </div>
                <div className="flex items-center gap-2 justify-between md:justify-start">
                  <button
                    onClick={() => setShowFilters(!showFilters)}
                    className="md:hidden flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
                  >
                    <Filter className="w-5 h-5" />
                    Filtros
                    {hasActiveFilters && (
                      <span className="bg-blue-600 text-white text-xs w-5 h-5 rounded-full flex items-center justify-center">
                        !
                      </span>
                    )}
                  </button>
                  
                  <div className="relative" ref={sortRef}>
                    <button
                      onClick={() => setShowSortOptions(!showSortOptions)}
                      className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
                    >
                      <ArrowUpDown className="w-5 h-5" />
                      <span className="hidden md:inline">{getSortLabel()}</span>
                      <span className="md:hidden">Ordenar</span>
                      <ChevronDown className="w-4 h-4" />
                    </button>
                    {showSortOptions && (
                      <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 z-10">
                        {sortOptions.map((option) => (
                          <button
                            key={option.value}
                            onClick={() => updateSort(option.value)}
                            className={`w-full px-4 py-2 text-left text-sm ${
                              currentSort === option.value
                                ? 'text-blue-600 bg-blue-50'
                                : 'text-gray-700 hover:bg-gray-50'
                            }`}
                          >
                            {option.label}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  
                  <div className="hidden md:flex border rounded-lg overflow-hidden">
                    <button
                      onClick={() => setView('grid')}
                      className={`p-2 ${
                        view === 'grid' ? 'bg-blue-600 text-white' : 'bg-white text-gray-600 hover:bg-gray-50'
                      }`}
                    >
                      <Grid className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => setView('list')}
                      className={`p-2 ${
                        view === 'list' ? 'bg-blue-600 text-white' : 'bg-white text-gray-600 hover:bg-gray-50'
                      }`}
                    >
                      <List className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Active Filters */}
              {hasActiveFilters && (
                <div className="mt-4 flex flex-wrap gap-2">
                  {currentPriceRange && (
                    <div className="bg-blue-50 text-blue-700 text-sm px-3 py-1 rounded-full flex items-center">
                      <Tag className="w-3 h-3 mr-1" />
                      {priceRanges.find(r => `${r.min}-${r.max}` === currentPriceRange)?.label}
                      <button 
                        onClick={() => {
                          searchParams.delete('price_range');
                          setSearchParams(searchParams);
                        }}
                        className="ml-2 text-blue-500 hover:text-blue-700"
                      >
                        <X className="w-3 h-3" />
                      </button>
                    </div>
                  )}
                  {inStockOnly && (
                    <div className="bg-blue-50 text-blue-700 text-sm px-3 py-1 rounded-full flex items-center">
                      <Tag className="w-3 h-3 mr-1" />
                      Solo en stock
                      <button 
                        onClick={toggleInStock}
                        className="ml-2 text-blue-500 hover:text-blue-700"
                      >
                        <X className="w-3 h-3" />
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* Results Count */}
            {!loading && (
              <div className="mb-4 text-sm text-gray-600">
                Mostrando {products.length} productos
              </div>
            )}

            {/* Products Grid/List */}
            {loading ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {[...Array(8)].map((_, i) => (
                  <div key={i} className="animate-pulse">
                    <div className="bg-gray-200 h-48 rounded-lg mb-4"></div>
                    <div className="space-y-3">
                      <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                      <div className="h-8 bg-gray-200 rounded w-full mt-4"></div>
                    </div>
                  </div>
                ))}
              </div>
            ) : products.length === 0 ? (
              <div className="text-center py-16">
                <div className="text-gray-400 mb-4">
                  <Search className="w-16 h-16 mx-auto" />
                </div>
                <h3 className="text-xl font-medium text-gray-700 mb-2">No se encontraron productos</h3>
                <p className="text-gray-500 mb-6">Intenta con otros términos de búsqueda o filtros</p>
                <button 
                  onClick={clearFilters}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Limpiar filtros
                </button>
              </div>
            ) : view === 'grid' ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {products.map((product) => (
                  <Link
                    key={product.id}
                    to={`/product/${product.id}`}
                    className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group flex flex-col h-full"
                  >
                    <div className="relative bg-white">
                      <img
                        src={product.main_image}
                        alt={product.name}
                        className="w-full h-48 object-contain group-hover:scale-105 transition-transform duration-300"
                      />
                     
                    
                    </div>
                    <div className="p-4 flex-1 flex flex-col">
                      <div className="text-xs text-blue-600 font-medium mb-1">
                        {product.category?.name}
                      </div>
                      <h3 className="text-sm text-gray-900 font-medium line-clamp-2 group-hover:text-blue-600 transition-colors mb-2">
                        {product.name}
                      </h3>
                      <div className="mt-auto">
                        <div className="flex items-baseline gap-2">
                          {user ? (
                            <span className="text-lg font-semibold text-blue-600">
                              Q{productPrices[product.id] || product.base_price.toFixed(2)}
                            </span>
                          ) : (
                            <span className="text-sm italic text-gray-600 bg-gray-100 px-2 py-1 rounded">
                              Inicia sesión para ver precios
                            </span>
                          )}
                         
                        </div>
                        {user ? (
                          <button 
                            onClick={(e) => {
                              e.preventDefault();
                              // Aquí iría la lógica para añadir al carrito
                            }}
                            className="w-full mt-3 bg-blue-600 text-white px-3 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
                          >
                            <ShoppingCart className="w-4 h-4" />
                            Añadir al carrito
                          </button>
                        ) : (
                          <Link
                            to="/login"
                            className="w-full mt-3 bg-gray-600 text-white px-3 py-2 rounded-lg text-sm font-medium hover:bg-gray-700 transition-colors flex items-center justify-center gap-2"
                          >
                            Iniciar sesión para comprar
                          </Link>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="space-y-4">
                {products.map((product) => (
                  <Link
                    key={product.id}
                    to={`/product/${product.id}`}
                    className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group flex flex-col md:flex-row"
                  >
                    <div className="relative md:w-48 md:h-48 bg-white">
                      <img
                        src={product.main_image}
                        alt={product.name}
                        className="w-full h-48 object-contain group-hover:scale-105 transition-transform duration-300"
                      />
                      {product.min_order > 1 && (
                        <div className="absolute top-2 right-2 bg-blue-600 text-white text-xs font-medium px-2 py-1 rounded-full">
                          Lote de {product.min_order}
                        </div>
                      )}
                    </div>
                    <div className="p-4 flex-1 flex flex-col">
                      <div>
                        <div className="text-xs text-blue-600 font-medium mb-1">
                          {product.category?.name}
                        </div>
                        <h3 className="text-lg text-gray-900 font-medium group-hover:text-blue-600 transition-colors">
                          {product.name}
                        </h3>
                      </div>
                      
                      <p className="text-sm text-gray-600 mt-2 line-clamp-2">
                        {product.description}
                      </p>
                      
                      <div className="mt-4 flex items-center justify-between">
                        <div>
                          {user ? (
                            <div className="flex items-baseline gap-2">
                              <span className="text-xl font-semibold text-blue-600">
                                Q{productPrices[product.id] || product.base_price.toFixed(2)}
                              </span>
                             
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <span className="text-sm italic text-gray-600 bg-gray-100 px-2 py-1 rounded">
                                Inicia sesión para ver precios
                              </span>
                            </div>
                          )}
                          <div className="text-sm text-green-600 mt-1">
                            {product.stock > 10 
                              ? 'En stock' 
                              : product.stock > 0 
                                ? `¡Solo quedan ${product.stock}!` 
                                : 'Agotado'}
                          </div>
                        </div>
                        <div className="flex gap-2">
                          
                          {user ? (
                            <button 
                              onClick={(e) => {
                                e.preventDefault();
                                // Aquí iría la lógica para añadir al carrito
                              }}
                              className="bg-blue-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center gap-2"
                            >
                              <ShoppingCart className="w-4 h-4" />
                              Añadir al carrito
                            </button>
                          ) : (
                            <Link
                              to="/login"
                              className="bg-gray-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-gray-700 transition-colors flex items-center gap-2"
                            >
                              Iniciar sesión
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
            
            {/* Pagination - Placeholder */}
            {!loading && products.length > 0 && (
              <div className="mt-10 flex justify-center">
                <nav className="flex items-center gap-1">
                  <button className="px-3 py-1 border rounded hover:bg-gray-50">Anterior</button>
                  <button className="px-3 py-1 bg-blue-600 text-white rounded">1</button>
                  <button className="px-3 py-1 border rounded hover:bg-gray-50">2</button>
                  <button className="px-3 py-1 border rounded hover:bg-gray-50">3</button>
                  <button className="px-3 py-1 border rounded hover:bg-gray-50">Siguiente</button>
                </nav>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}