import { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import { Package, Truck, Building2, Phone, Mail, Edit, LogOut, ShoppingBag, Clock, CheckCircle, AlertCircle, Eye, XCircle, Search, Filter, ChevronDown, ArrowLeft, ShoppingCart, Calendar, CreditCard, MapPin } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import React from 'react';
import ViewOrder from './ViewOrder';
import { useAuth } from '../contexts/AuthContext';

interface Order {
  id: string;
  created_at: string;
  total_amount: number;
  status: 'pending' | 'processing' | 'shipped' | 'delivered' | 'cancelled' | 'confirmed' | 'pending_payment' | 'paid';
  payment_status: 'pending' | 'paid' | 'failed' | 'refunded';
  payment_method: 'card' | 'cash' | 'twoCards';
  delivery_method: 'delivery' | 'pickup';
  shipping_address?: {
    first_name: string;
    last_name: string;
    address: string;
    city: string;
    region: string;
    phone: string;
  };
  items: {
    product_id: string;
    quantity: number;
    price: number;
    product: {
      name: string;
      main_image: string;
    };
  }[];
}

interface Profile {
  name: string;
  company: string;
  phone: string;
  email: string;
  address?: string;
  address_details?: string;
  department?: string;
  municipality?: string;
  postal_code?: string;
  nit?: string;
  nit_type?: 'NIT' | 'CF';
}

const orderStatusColors = {
  pending: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  confirmed: 'bg-blue-100 text-blue-800 border-blue-200',
  pending_payment: 'bg-orange-100 text-orange-800 border-orange-200',
  paid: 'bg-green-100 text-green-800 border-green-200',
  processing: 'bg-blue-100 text-blue-800 border-blue-200',
  shipped: 'bg-purple-100 text-purple-800 border-purple-200',
  delivered: 'bg-green-100 text-green-800 border-green-200',
  cancelled: 'bg-red-100 text-red-800 border-red-200',
};

const orderStatusLabels = {
  pending: 'Pendiente Revisión',
  confirmed: 'Confirmado',
  pending_payment: 'Pendiente Pago',
  paid: 'Pagado',
  processing: 'En proceso',
  shipped: 'Enviado',
  delivered: 'Entregado',
  cancelled: 'Cancelado',
};

const statusIcons = {
  pending: Clock,
  processing: Package,
  shipped: Truck,
  delivered: CheckCircle,
  cancelled: XCircle,
  confirmed: CheckCircle,
  pending_payment: AlertCircle,
  paid: CheckCircle
};

type FilterStatus = 'all' | Order['status'];

export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<'orders' | 'profile'>('orders');
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [departamentos, setDepartamentos] = useState<{ id: string; nombre: string }[]>([]);
  const [municipios, setMunicipios] = useState<{ id: string; nombre: string; departamento: string }[]>([]);
  const [municipiosFiltrados, setMunicipiosFiltrados] = useState<{ id: string; nombre: string; departamento: string }[]>([]);
  const [loadingDepartamentos, setLoadingDepartamentos] = useState(false);
  const [loadingMunicipios, setLoadingMunicipios] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState<string | null>(null);

  const { signOut } = useAuth();

  useEffect(() => {
    checkAuth();
    fetchProfile();
    fetchOrders();
    fetchDepartamentos();
  }, []);

  useEffect(() => {
    // Verificar si la URL contiene un patrón de ID de pedido
    const pathParts = location.pathname.split('/');
    const orderIdIndex = pathParts.findIndex(part => part === 'order') + 1;
    
    if (orderIdIndex > 0 && pathParts[orderIdIndex]) {
      setCurrentOrderId(pathParts[orderIdIndex]);
      setShowOrderDetails(true);
    } else {
      setShowOrderDetails(false);
      setCurrentOrderId(null);
    }
  }, [location]);

  useEffect(() => {
    // Aplicar filtros cuando cambian los criterios
    let result = [...orders];
    
    // Filtrar por estado
    if (filterStatus !== 'all') {
      result = result.filter(order => order.status === filterStatus);
    }
    
    // Filtrar por término de búsqueda
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase();
      result = result.filter(order => 
        order.id.toLowerCase().includes(term) || 
        order.items.some(item => item.product.name.toLowerCase().includes(term))
      );
    }
    
    setFilteredOrders(result);
  }, [orders, filterStatus, searchTerm]);

  const checkAuth = async () => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      navigate('/login');
    }
  };

  const fetchProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: profile, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;

      // Asegurarnos de que todos los campos estén presentes en el objeto profile
      // y mapear 'municipio' a 'municipality' en nuestro estado local
      setProfile({
        name: profile.name || '',
        company: profile.company || '',
        phone: profile.phone || '',
        email: user.email || '',
        address: profile.address || '',
        address_details: profile.address_details || '',
        department: profile.department || '',
        municipality: profile.municipio || '', // Mapear 'municipio' a 'municipality'
        postal_code: profile.postal_code || '',
        nit: profile.nit || '',
        nit_type: profile.nit_type || 'NIT',
      });

      // Si el perfil tiene un departamento, cargar los municipios correspondientes
      if (profile.department) {
        fetchMunicipiosByDepartamento(profile.department);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('orders')
        .select(`
          id,
          created_at,
          total_amount,
          status,
          payment_status,
          payment_method,
          delivery_method,
          shipping_address,
          items:order_items(
            product_id,
            quantity,
            price,
            product:products(
              name,
              main_image
            )
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transformar los datos para que coincidan con la interfaz Order
      const transformedData = data?.map(order => ({
        ...order,
        items: order.items?.map(item => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          product: {
            name: item.product?.[0]?.name || '',
            main_image: item.product?.[0]?.main_image || ''
          }
        })) || []
      })) || [];

      setOrders(transformedData);
      setFilteredOrders(transformedData);
    } catch (error) {
      console.error('Error fetching orders:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    await signOut();
  };

  const fetchDepartamentos = async () => {
    try {
      setLoadingDepartamentos(true);
      const { data, error } = await supabase
        .from('municipios')
        .select('departamento')
        .order('departamento', { ascending: true });

      if (error) throw error;
      
      // Eliminar duplicados y formatear los datos
      const departamentosUnicos = [...new Set(data.map(item => item.departamento))];
      const departamentosFormateados = departamentosUnicos.map((nombre, index) => ({
        id: index.toString(),
        nombre
      }));
      
      setDepartamentos(departamentosFormateados);
    } catch (error) {
      console.error('Error al cargar departamentos:', error);
    } finally {
      setLoadingDepartamentos(false);
    }
  };

  const fetchMunicipiosByDepartamento = async (departamento: string) => {
    try {
      setLoadingMunicipios(true);
      const { data, error } = await supabase
        .from('municipios')
        .select('*')
        .eq('departamento', departamento)
        .order('nombre', { ascending: true });

      if (error) throw error;
      
      // Formatear los datos para mantener la misma estructura
      const municipiosFormateados = data.map(item => ({
        id: item.id,
        nombre: item.nombre,
        departamento: item.departamento
      }));
      
      setMunicipios(municipiosFormateados);
      setMunicipiosFiltrados(municipiosFormateados);
    } catch (error) {
      console.error('Error al cargar municipios:', error);
    } finally {
      setLoadingMunicipios(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name === 'department' && value) {
      fetchMunicipiosByDepartamento(value);
    }
  };

  const handleUpdateProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Ahora podemos actualizar todos los campos en la tabla profiles
      const { error } = await supabase
        .from('profiles')
        .update({
          name: formData.get('name'),
          company: formData.get('company'),
          phone: formData.get('phone'),
          address: formData.get('address'),
          address_details: formData.get('address_details'),
          department: formData.get('department'),
          municipio: formData.get('municipality'),
          postal_code: formData.get('postal_code'),
          nit: formData.get('nit'),
          nit_type: formData.get('nit_type'),
        })
        .eq('id', user.id);

      if (error) throw error;

      // Actualizar el perfil en el estado local
      setProfile({
        name: formData.get('name') as string,
        company: formData.get('company') as string,
        phone: formData.get('phone') as string,
        email: profile?.email || '',
        address: formData.get('address') as string,
        address_details: formData.get('address_details') as string,
        department: formData.get('department') as string,
        municipality: formData.get('municipality') as string,
        postal_code: formData.get('postal_code') as string,
        nit: formData.get('nit') as string,
        nit_type: formData.get('nit_type') as 'NIT' | 'CF',
      });

      // Mostrar mensaje de éxito
      alert('Perfil actualizado correctamente');
      setEditMode(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error al actualizar el perfil. Por favor, intenta de nuevo.');
    }
  };

  const handlePayment = async (orderId: string) => {
    // Redirigir al usuario a la página de checkout con el ID del pedido
    navigate(`/order/${orderId}/payment`);
  };

  const getStatusCount = (status: Order['status']) => {
    return orders.filter(order => order.status === status).length;
  };

  const openOrderDetails = (order: Order) => {
    navigate(`/profile/order/${order.id}`);
  };

  const handleBackToProfile = () => {
    setShowOrderDetails(false);
    setCurrentOrderId(null);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // Si estamos mostrando los detalles del pedido, renderizar ViewOrder
  if (showOrderDetails && currentOrderId) {
    return (
      <div className="max-w-7xl mx-auto">
        <div className="mb-6">
          <button 
            onClick={handleBackToProfile} 
            className="flex items-center gap-2 text-blue-600 hover:text-blue-800 font-medium transition-colors"
          >
            <ArrowLeft className="w-4 h-4" />
            Volver a mi perfil
          </button>
        </div>
        <ViewOrder isClientView={true} />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row gap-8">
        {/* Sidebar */}
        <div className="w-full md:w-64 space-y-6">
          {/* Profile Card */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center gap-4 mb-6">
              <div className="w-16 h-16 rounded-full bg-blue-100 flex items-center justify-center">
                <span className="text-2xl font-semibold text-blue-600">
                  {profile?.name.charAt(0)}
                </span>
              </div>
              <div>
                <h2 className="font-semibold text-gray-900">{profile?.name}</h2>
                <p className="text-sm text-gray-500">{profile?.email}</p>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center gap-3 text-sm text-gray-600">
                <Building2 className="w-5 h-5" />
                <span>{profile?.company}</span>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-600">
                <Phone className="w-5 h-5" />
                <span>{profile?.phone}</span>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-600">
                <Mail className="w-5 h-5" />
                <span>{profile?.email}</span>
              </div>
            </div>

            <div className="mt-6 space-y-3">
              <button
                onClick={() => {
                  setActiveTab('profile');
                  setEditMode(true);
                }}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                <Edit className="w-4 h-4" />
                Editar Perfil
              </button>
              <button
                onClick={handleLogout}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-red-200 text-red-600 rounded-lg hover:bg-red-50 transition-colors"
              >
                <LogOut className="w-4 h-4" />
                Cerrar Sesión
              </button>
            </div>
          </div>

          {/* Navigation */}
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <button
              onClick={() => setActiveTab('orders')}
              className={`w-full flex items-center gap-3 px-6 py-3 text-sm font-medium ${
                activeTab === 'orders'
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <ShoppingBag className="w-5 h-5" />
              Mis Pedidos
            </button>
            <button
              onClick={() => setActiveTab('profile')}
              className={`w-full flex items-center gap-3 px-6 py-3 text-sm font-medium ${
                activeTab === 'profile'
                  ? 'bg-blue-50 text-blue-600'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              <Building2 className="w-5 h-5" />
              Datos de la Empresa
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1">
          {activeTab === 'orders' ? (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex flex-col md:flex-row gap-4 mb-6 justify-between items-center">
                <h2 className="text-lg font-semibold">Mis Pedidos</h2>
                
                {/* Barra de búsqueda y filtros */}
                <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
                  <div className="relative flex-grow">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Search className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      placeholder="Buscar por ID o producto..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  
                  <div className="relative">
                    <button 
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                      className="flex items-center justify-between w-full md:w-48 px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
                    >
                      <div className="flex items-center">
                        <Filter className="h-4 w-4 mr-2" />
                        <span>
                          {filterStatus === 'all' ? 'Todos los estados' : orderStatusLabels[filterStatus]}
                        </span>
                      </div>
                      <ChevronDown className="h-4 w-4" />
                    </button>
                    
                    {isFilterOpen && (
                      <div className="absolute z-10 right-0 mt-1 w-56 bg-white border border-gray-200 rounded-lg shadow-lg">
                        <ul className="py-1 max-h-60 overflow-auto">
                          <li 
                            className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center ${filterStatus === 'all' ? 'bg-blue-50 text-blue-700' : ''}`}
                            onClick={() => {
                              setFilterStatus('all');
                              setIsFilterOpen(false);
                            }}
                          >
                            <span>Todos</span>
                            <span className="bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded-full">
                              {orders.length}
                            </span>
                          </li>
                          {(Object.keys(orderStatusLabels) as Array<Order['status']>).map(status => (
                            <li 
                              key={status}
                              className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center ${filterStatus === status ? 'bg-blue-50 text-blue-700' : ''}`}
                              onClick={() => {
                                setFilterStatus(status);
                                setIsFilterOpen(false);
                              }}
                            >
                              <div className="flex items-center">
                                {React.createElement(statusIcons[status], { className: "h-4 w-4 mr-2" })}
                                <span>{orderStatusLabels[status]}</span>
                              </div>
                              <span className="bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded-full">
                                {getStatusCount(status)}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="divide-y">
                {filteredOrders.length === 0 ? (
                  <div className="p-6 text-center">
                    <ShoppingBag className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                    <h3 className="text-lg font-medium text-gray-900 mb-1">
                      {searchTerm || filterStatus !== 'all'
                        ? 'No se encontraron pedidos con los filtros seleccionados'
                        : 'No hay pedidos todavía'}
                    </h3>
                    <p className="text-gray-500">
                      {searchTerm || filterStatus !== 'all'
                        ? (
                          <button
                            onClick={() => {
                              setSearchTerm('');
                              setFilterStatus('all');
                            }}
                            className="mt-4 text-blue-600 hover:text-blue-800"
                          >
                            Limpiar filtros
                          </button>
                        )
                        : 'Tus pedidos aparecerán aquí cuando realices uno.'}
                    </p>
                  </div>
                ) : (
                  filteredOrders.map((order) => (
                    <div key={order.id} className="p-6">
                      <div className="flex items-start justify-between mb-4">
                        <div>
                          <div className="flex items-center gap-2 mb-1">
                            <h3 className="font-medium">
                              Pedido #{order.id.slice(0, 8)}
                            </h3>
                            <span className={`px-2 py-1 rounded-full text-xs font-medium ${orderStatusColors[order.status]}`}>
                              {orderStatusLabels[order.status]}
                            </span>
                          </div>
                          <p className="text-sm text-gray-500">
                            {format(new Date(order.created_at), "d 'de' MMMM, yyyy", { locale: es })}
                          </p>
                        </div>
                        <span className="text-lg font-semibold">
                          Q {order.total_amount ? order.total_amount.toFixed(2) : '0.00'}
                        </span>
                      </div>
                      
                      <div className="space-y-3 mt-4">
                        {order.items.slice(0, 2).map((item) => (
                          <div key={item.product_id} className="flex items-center space-x-3">
                            <img
                              src={item.product.main_image}
                              alt={item.product.name}
                              className="w-12 h-12 object-cover rounded"
                              onError={(e) => {
                                e.currentTarget.src = '/placeholder-image.jpg';
                              }}
                            />
                            <div className="flex-1 min-w-0">
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {item.product.name}
                              </p>
                              <p className="text-sm text-gray-500">
                                Cantidad: {item.quantity}
                              </p>
                            </div>
                            <div className="text-sm font-medium text-gray-900">
                              Q{(item.price * item.quantity).toFixed(2)}
                            </div>
                          </div>
                        ))}
                        {order.items.length > 2 && (
                          <p className="text-sm text-gray-500 pt-2">
                            Y {order.items.length - 2} productos más...
                          </p>
                        )}
                      </div>

                      <div className="flex items-center justify-end gap-3 mt-4">
                        {order.status === 'pending_payment' && (
                          <button
                            onClick={() => handlePayment(order.id)}
                            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                          >
                            Pagar ahora
                          </button>
                        )}
                        <button 
                          onClick={() => openOrderDetails(order)}
                          className="flex items-center gap-2 text-blue-600 hover:text-blue-800"
                        >
                          <Eye className="w-4 h-4" />
                          Ver detalles
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-lg font-semibold">Datos de la Empresa</h2>
                {!editMode && (
                  <button
                    onClick={() => setEditMode(true)}
                    className="flex items-center gap-2 px-4 py-2 text-sm bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  >
                    <Edit className="w-4 h-4" />
                    Editar Perfil
                  </button>
                )}
              </div>
              
              {!editMode ? (
                <div className="space-y-6">
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                      Información Básica
                    </h3>
                    <div className="bg-gray-50 rounded-lg p-4 space-y-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <span className="block text-sm text-gray-500">Nombre</span>
                          <span className="text-gray-900">{profile?.name}</span>
                        </div>
                        <div>
                          <span className="block text-sm text-gray-500">Empresa</span>
                          <span className="text-gray-900">{profile?.company}</span>
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <span className="block text-sm text-gray-500">Email</span>
                          <span className="text-gray-900">{profile?.email}</span>
                        </div>
                        <div>
                          <span className="block text-sm text-gray-500">Teléfono</span>
                          <span className="text-gray-900">{profile?.phone}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">
                      Dirección de Facturación
                    </h3>
                    <div className="bg-gray-50 rounded-lg p-4">
                      {profile?.address ? (
                        <div className="space-y-2">
                          <div className="flex items-start gap-2">
                            <span className="text-gray-500 text-sm">Dirección:</span>
                            <span className="text-gray-900">{profile.address}</span>
                          </div>
                          {profile.address_details && (
                            <div className="flex items-start gap-2">
                              <span className="text-gray-500 text-sm">Detalles:</span>
                              <span className="text-gray-900">{profile.address_details}</span>
                            </div>
                          )}
                          <div className="flex items-start gap-2">
                            <span className="text-gray-500 text-sm">Ubicación:</span>
                            <span className="text-gray-900">
                              {profile.municipality && profile.department 
                                ? `${profile.municipality}, ${profile.department}` 
                                : profile.department || 'No especificada'}
                            </span>
                          </div>
                          {profile.postal_code && (
                            <div className="flex items-start gap-2">
                              <span className="text-gray-500 text-sm">Código Postal:</span>
                              <span className="text-gray-900">{profile.postal_code}</span>
                            </div>
                          )}
                          {profile.nit && (
                            <div className="flex items-start gap-2">
                              <span className="text-gray-500 text-sm">
                                {profile.nit_type || 'NIT'}:
                              </span>
                              <span className="text-gray-900">{profile.nit}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <p className="text-gray-500 text-sm">
                          No hay dirección de envío registrada
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <form onSubmit={handleUpdateProfile} className="space-y-4">
                  <h3 className="text-md font-medium text-gray-800 mb-3">Información Básica</h3>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Nombre
                    </label>
                    <input
                      type="text"
                      name="name"
                      defaultValue={profile?.name}
                      required
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Empresa
                    </label>
                    <input
                      type="text"
                      name="company"
                      defaultValue={profile?.company}
                      required
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Teléfono
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      defaultValue={profile?.phone}
                      required
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <h3 className="text-md font-medium text-gray-800 mt-6 mb-3">Dirección de Envío</h3>

                  <div className="flex gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Tipo
                      </label>
                      <select
                        name="nit_type"
                        defaultValue={profile?.nit_type || 'NIT'}
                        className="w-24 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                      >
                        <option value="NIT">NIT</option>
                        <option value="CF">CF</option>
                      </select>
                    </div>
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Número
                      </label>
                      <input
                        type="text"
                        name="nit"
                        defaultValue={profile?.nit}
                        placeholder="NIT/CF"
                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Dirección
                    </label>
                    <input
                      type="text"
                      name="address"
                      defaultValue={profile?.address}
                      placeholder="Dirección"
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Detalles de dirección
                    </label>
                    <input
                      type="text"
                      name="address_details"
                      defaultValue={profile?.address_details}
                      placeholder="Casa, apartamento, etc. (opcional)"
                      className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Departamento
                      </label>
                      <div className="relative">
                        <select
                          name="department"
                          defaultValue={profile?.department || ''}
                          onChange={handleInputChange}
                          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                          disabled={loadingDepartamentos}
                        >
                          <option value="">Seleccione departamento</option>
                          {departamentos.map((depto) => (
                            <option key={depto.id} value={depto.nombre}>
                              {depto.nombre}
                            </option>
                          ))}
                        </select>
                        {loadingDepartamentos && (
                          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            <div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin"></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Municipio
                      </label>
                      <div className="relative">
                        <select
                          name="municipality"
                          defaultValue={profile?.municipality || ''}
                          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                          disabled={loadingMunicipios || municipiosFiltrados.length === 0}
                        >
                          <option value="">Seleccione municipio</option>
                          {municipiosFiltrados.map((municipio) => (
                            <option key={municipio.id} value={municipio.nombre}>
                              {municipio.nombre}
                            </option>
                          ))}
                        </select>
                        {loadingMunicipios && (
                          <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
                            <div className="w-5 h-5 border-t-2 border-blue-500 rounded-full animate-spin"></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Código postal
                      </label>
                      <input
                        type="text"
                        name="postal_code"
                        defaultValue={profile?.postal_code}
                        placeholder="Código postal"
                        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-3 mt-6">
                    <button
                      type="button"
                      onClick={() => setEditMode(false)}
                      className="px-4 py-2 border rounded-lg hover:bg-gray-50"
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                    >
                      Guardar Cambios
                    </button>
                  </div>
                </form>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}