import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

type FAQItem = {
  question: string;
  answer: string;
};

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqItems: FAQItem[] = [
    {
      question: "¿Cuáles son los métodos de pago aceptados?",
      answer: "Aceptamos pagos con tarjetas de crédito y débito (Visa, Mastercard, American Express), transferencias bancarias, depósitos y efectivo en caso de entrega a domicilio. Todos los pagos con tarjeta se procesan de manera segura a través de nuestra plataforma de pago."
    },
    {
      question: "¿Cuánto tiempo tarda en llegar mi pedido?",
      answer: "Los tiempos de entrega varían según su ubicación. Para la Ciudad de Guatemala y áreas metropolitanas, el tiempo estimado es de 1-2 días hábiles. Para cabeceras departamentales, 2-3 días hábiles. Para municipios y áreas rurales, 3-5 días hábiles. Estos tiempos pueden variar según la disponibilidad del producto y condiciones externas."
    },
    {
      question: "¿Puedo cambiar o cancelar mi pedido después de realizarlo?",
      answer: "Puede solicitar cambios o cancelaciones dentro de las primeras 2 horas después de realizar su pedido, siempre y cuando no haya sido procesado para envío. Para solicitar un cambio o cancelación, contáctenos inmediatamente a través de info@disolgua.com o llame al 2238-0714."
    },
    {
      question: "¿Qué hago si recibo un producto defectuoso?",
      answer: "Si recibe un producto defectuoso, contáctenos dentro de las 24 horas siguientes a la recepción. Proporcione fotos del producto y una descripción del defecto. Nuestro equipo evaluará el caso y le ofrecerá una solución, que puede incluir reemplazo del producto o reembolso."
    },
    {
      question: "¿Ofrecen garantía en sus productos?",
      answer: "Sí, todos nuestros productos cuentan con garantía. El período de garantía varía según el tipo de producto, desde 3 meses hasta 1 año. La garantía cubre defectos de fabricación y no aplica para daños causados por mal uso, accidentes o desgaste normal."
    },
    {
      question: "¿Realizan envíos internacionales?",
      answer: "Actualmente solo realizamos envíos dentro de Guatemala. Estamos trabajando para expandir nuestros servicios a otros países de Centroamérica en el futuro."
    },
    {
      question: "¿Cómo puedo hacer seguimiento de mi pedido?",
      answer: "Una vez que su pedido haya sido enviado, recibirá un correo electrónico con la información de seguimiento. También puede verificar el estado de su pedido iniciando sesión en su cuenta en nuestro sitio web y visitando la sección 'Mis Pedidos'."
    },
    {
      question: "¿Tienen tienda física?",
      answer: "Sí, contamos con una tienda física ubicada en la Ciudad de Guatemala. Puede visitarnos de lunes a viernes de 9:00 AM a 6:00 PM y sábados de 9:00 AM a 1:00 PM. Nuestra dirección completa está disponible en la sección de Contacto."
    },
    {
      question: "¿Ofrecen descuentos para compras al por mayor?",
      answer: "Sí, ofrecemos descuentos especiales para compras al por mayor. Para obtener información sobre precios mayoristas, envíe un correo electrónico a ventas@disolgua.com con detalles sobre los productos y cantidades que le interesan."
    },
    {
      question: "¿Cómo puedo crear una cuenta en el sitio web?",
      answer: "Para crear una cuenta, haga clic en el ícono de usuario en la esquina superior derecha de nuestra página web y seleccione 'Registrarse'. Complete el formulario con su información personal y cree una contraseña. Una vez registrado, podrá iniciar sesión para realizar compras, guardar productos favoritos y hacer seguimiento de sus pedidos."
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <Helmet>
        <title>Preguntas Frecuentes | Disolgua</title>
        <meta name="description" content="Preguntas frecuentes sobre productos y servicios de Disolgua" />
      </Helmet>
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Preguntas Frecuentes</h1>
      
      <div className="space-y-4">
        {faqItems.map((item, index) => (
          <div 
            key={index} 
            className="border border-gray-200 rounded-lg overflow-hidden"
          >
            <button
              className="w-full flex justify-between items-center p-5 bg-white hover:bg-gray-50 transition-colors text-left"
              onClick={() => toggleQuestion(index)}
            >
              <span className="font-medium text-gray-900">{item.question}</span>
              {openIndex === index ? (
                <ChevronUp className="w-5 h-5 text-blue-600" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-400" />
              )}
            </button>
            
            {openIndex === index && (
              <div className="p-5 bg-gray-50 border-t border-gray-200">
                <p className="text-gray-700">{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      
      <div className="mt-12 p-6 bg-blue-50 rounded-lg">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">¿No encontró respuesta a su pregunta?</h2>
        <p className="text-gray-700 mb-4">
          Si no ha encontrado la información que busca, no dude en contactarnos. 
          Nuestro equipo de atención al cliente estará encantado de ayudarle.
        </p>
        <div className="flex flex-col sm:flex-row gap-4">
          <a 
            href="mailto:info@disolgua.com" 
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Enviar correo
          </a>
          <a 
            href="tel:22380714" 
            className="inline-flex items-center justify-center px-5 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Llamar: 2238-0714
          </a>
        </div>
      </div>
    </div>
  );
} 