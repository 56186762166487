import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Package, 
  Users, 
  ShoppingCart, 
  Settings, 
  Search, 
  Bell, 
  LogOut, 
  FolderTree, 
  Layers, 
  Store, 
  Menu, 
  X, 
  ChevronDown,
  Sun,
  Moon,
  HelpCircle,
  Image,
  Home
} from 'lucide-react';
import { supabase } from '../lib/supabase';

interface AdminPermissions {
  orders: boolean;
  products: boolean;
  users: boolean;
  reports: boolean;
}

const TABS = [
  { id: 'dashboard', name: 'Dashboard', icon: LayoutDashboard, permission: 'reports' },
  { id: 'pos', name: 'Punto de Venta', icon: Store, permission: 'orders' },
  { id: 'products', name: 'Productos', icon: Package, permission: 'products' },
  { id: 'categories', name: 'Categorías', icon: FolderTree, permission: 'products' },
  { id: 'collections', name: 'Colecciones', icon: Layers, permission: 'products' },
  { id: 'sliders', name: 'Sliders', icon: Image, permission: 'products' },
  { id: 'users', name: 'Usuarios', icon: Users, permission: 'users' },
  { id: 'orders', name: 'Pedidos', icon: ShoppingCart, permission: 'orders' },
  { id: 'settings', name: 'Configuración', icon: Settings, permission: undefined },
] as const;

interface AdminLayoutProps {
  children: React.ReactNode;
  activeTab: (typeof TABS)[number]['id'];
  onTabChange: (tab: (typeof TABS)[number]['id']) => void;
}

export default function AdminLayout({ children, activeTab, onTabChange }: AdminLayoutProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [permissions, setPermissions] = useState<AdminPermissions | null>(null);
  const [adminProfile, setAdminProfile] = useState<any>(null);

  // Verificar si el usuario tiene permiso para acceder a una pestaña específica
  const hasTabPermission = (tabId: string): boolean => {
    const tab = TABS.find(t => t.id === tabId);
    if (!tab) return false;
    if (!tab.permission) return true; // Si no requiere permiso específico
    
    // Verificar si tiene el permiso específico para la pestaña
    return Boolean(permissions?.[tab.permission as keyof AdminPermissions]);
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError) throw authError;
        if (!user) {
          navigate('/login');
          return;
        }

        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();

        if (profileError) throw profileError;

        // Solo nos importan los permisos específicos, no el estado de admin
        const userPermissions = profile?.admin_permissions || {};
        
        // Mantener isAdmin solo para propósitos de UI
        const isFullAdmin = Object.values(userPermissions).every(Boolean);
        
        setIsAdmin(isFullAdmin);
        setPermissions(userPermissions);
        setAdminProfile({
          ...profile,
          email: user.email
        });

        // Verificar si el usuario tiene permiso para la pestaña actual
        if (!hasTabPermission(activeTab)) {
          // Buscar la primera pestaña permitida
          const firstAllowedTab = TABS.find(tab => {
            if (!tab.permission) return true;
            return Boolean(userPermissions[tab.permission as keyof AdminPermissions]);
          });
          
          if (firstAllowedTab) {
            onTabChange(firstAllowedTab.id);
          } else {
            navigate('/access-denied');
          }
        }
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      } finally {
        setLoading(false);
      }
    };

    checkAuth();

    // Ajustar sidebarOpen según el tamaño de la pantalla en el inicio
    const handleResize = () => {
      setSidebarOpen(window.innerWidth >= 1024);
    };
    
    // Establecer sidebar inicial basado en el tamaño de la pantalla
    handleResize();
    
    // Agregar listener de redimensionamiento
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [navigate, activeTab]);

  const handleTabChange = (tabId: (typeof TABS)[number]['id']) => {
    if (hasTabPermission(tabId)) {
      onTabChange(tabId);
    } else {
      alert('No tienes permisos para acceder a esta sección');
    }
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate('/login');
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    // Aquí se implementaría la lógica para cambiar el tema
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
          <p className="text-gray-600">Cargando panel de administración...</p>
        </div>
      </div>
    );
  }

  // Filtrar pestañas a las que el usuario tiene acceso
  const accessibleTabs = TABS.filter(tab => hasTabPermission(tab.id));

  return (
    <div className={`min-h-screen flex bg-gray-50 ${darkMode ? 'dark' : ''} prevent-overflow`}>
      {/* Sidebar para móvil - Slide-over menu */}
      <div className={`lg:hidden fixed inset-0 z-40 ${sidebarOpen ? 'block' : 'hidden'}`}>
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" onClick={toggleSidebar}></div>
        <div className="fixed inset-y-0 left-0 flex flex-col w-64 bg-white shadow-lg overflow-y-auto">
          <div className="p-4 border-b flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img src="/assets/DISOLGUA.png" alt="Disolgua Logo" className="h-8 w-auto" />
            </div>
            <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100">
              <X className="w-5 h-5" />
            </button>
          </div>
          
          {/* Información del usuario en móvil */}
          <div className="px-4 py-3 border-b">
            <div className="flex items-center gap-3">
              <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                <span className="text-blue-600 font-medium">
                  {adminProfile?.name.charAt(0)}
                </span>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">{adminProfile?.name}</p>
                <p className="text-xs text-gray-500">{adminProfile?.email}</p>
              </div>
            </div>
          </div>
          
          <nav className="flex-1 p-2 overflow-y-auto">
            <ul className="space-y-1">
              {TABS.map((tab) => {
                if (!hasTabPermission(tab.id)) return null;

                return (
                  <li key={tab.id}>
                    <button
                      onClick={() => {
                        handleTabChange(tab.id);
                        setSidebarOpen(false);
                      }}
                      className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg text-sm font-medium transition-colors ${
                        activeTab === tab.id
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:bg-gray-50'
                      }`}
                    >
                      <tab.icon className="w-5 h-5" />
                      {tab.name}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav>
          
          <div className="p-3 border-t space-y-2">
            <button
              onClick={() => navigate('/')}
              className="w-full flex items-center gap-2 px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg text-sm font-medium"
            >
              <Home className="w-5 h-5" />
              Ir a Tienda
            </button>
            
            <button
              onClick={handleLogout}
              className="w-full flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg text-sm font-medium"
            >
              <LogOut className="w-5 h-5" />
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>

      {/* Sidebar para desktop */}
      <aside className={`hidden lg:flex lg:flex-col ${sidebarOpen ? 'lg:w-64' : 'lg:w-20'} bg-white border-r transition-all duration-300 fixed top-0 left-0 h-screen z-30`}>
        <div className={`p-6 border-b flex ${sidebarOpen ? 'justify-between' : 'justify-center'}`}>
          {sidebarOpen ? (
            <div className="flex items-center gap-4">
              <a href="/" className="flex items-center">
                <img src="/assets/DISOLGUA.png" alt="Disolgua Logo" className="h-8 w-auto" />
              </a>
            </div>
          ) : (
            <a href="/" className="flex items-center justify-center">
              <img src="/assets/DISOLGUA.png" alt="Disolgua Logo" className="h-8 w-auto" />
            </a>
          )}
          <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-700">
            <Menu className="w-6 h-6" />
          </button>
        </div>
        <nav className="flex-1 p-4 overflow-y-auto">
          <ul className="space-y-2">
            {TABS.map((tab) => {
              if (!hasTabPermission(tab.id)) return null;

              return (
                <li key={tab.id}>
                  <button
                    onClick={() => handleTabChange(tab.id)}
                    className={`w-full flex items-center ${sidebarOpen ? 'justify-start gap-3 px-4' : 'justify-center'} py-3 rounded-lg text-sm font-medium transition-colors ${
                      activeTab === tab.id
                        ? 'bg-blue-50 text-blue-600'
                        : 'text-gray-600 hover:bg-gray-50'
                    }`}
                    title={!sidebarOpen ? tab.name : undefined}
                  >
                    <tab.icon className="w-5 h-5" />
                    {sidebarOpen && tab.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </nav>
        <div className="p-4 border-t">
          <button
            onClick={handleLogout}
            className={`w-full flex items-center ${sidebarOpen ? 'justify-start gap-2 px-4' : 'justify-center'} py-2 text-red-600 hover:bg-red-50 rounded-lg text-sm font-medium`}
            title={!sidebarOpen ? 'Cerrar sesión' : undefined}
          >
            <LogOut className="w-5 h-5" />
            {sidebarOpen && 'Cerrar sesión'}
          </button>
        </div>
      </aside>

      {/* Main Content */}
      <div className={`flex-1 flex flex-col ${sidebarOpen ? 'lg:ml-64' : 'lg:ml-20'}`}>
        {!hasTabPermission(activeTab) ? (
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900 mb-2">Acceso Denegado</h2>
              <p className="text-gray-600">No tienes permisos para acceder a esta sección.</p>
            </div>
          </div>
        ) : (
          <>
            {/* Header Mejorado */}
            <header className="bg-white border-b sticky top-0 z-30">
              <div className="px-4 sm:px-6 py-3">
                <div className="flex items-center justify-between h-14">
                  <div className="flex items-center">
                    <button 
                      onClick={toggleSidebar} 
                      className="lg:hidden text-gray-500 hover:text-gray-700 p-2 rounded-full hover:bg-gray-100"
                    >
                      <Menu className="w-5 h-5" />
                    </button>
                    
                    <span className="ml-2 font-semibold text-gray-900 lg:hidden">
                      {TABS.find(tab => tab.id === activeTab)?.name}
                    </span>
                  </div>
                  
                  <div className="flex items-center gap-3">
                    <button 
                      onClick={() => navigate('/')}
                      className="text-blue-600 hover:bg-blue-50 p-2 rounded-full md:hidden"
                      title="Ir a tienda"
                    >
                      <Home className="w-5 h-5" />
                    </button>
                    
                    <button 
                      onClick={() => navigate('/')}
                      className="hidden md:flex items-center gap-1 text-blue-600 hover:bg-blue-50 px-3 py-1.5 rounded-lg text-sm font-medium"
                    >
                      <Home className="w-4 h-4" />
                      <span>Ir a Tienda</span>
                    </button>
                    
                    <div className="relative">
                      <button 
                        onClick={() => setUserMenuOpen(!userMenuOpen)}
                        className="flex items-center gap-2 hover:bg-gray-50 p-2 rounded-lg"
                      >
                        <div className="h-8 w-8 rounded-full bg-blue-100 flex items-center justify-center">
                          <span className="text-blue-600 font-medium">
                            {adminProfile?.name.charAt(0)}
                          </span>
                        </div>
                        <div className="hidden md:block">
                          <p className="text-sm font-medium text-gray-900 text-left">{adminProfile?.name}</p>
                          <p className="text-xs text-gray-500 text-left">{adminProfile?.email}</p>
                        </div>
                        <ChevronDown className="w-4 h-4 text-gray-500 hidden md:block" />
                      </button>
                      {userMenuOpen && (
                        <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg py-2 z-50 border">
                          <div className="px-4 py-3 border-b">
                            <p className="text-sm font-medium text-gray-900">{adminProfile?.name}</p>
                            <p className="text-xs text-gray-500">{adminProfile?.email}</p>
                          </div>
                          <div className="py-1">
                            <button className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Mi perfil
                            </button>
                            <button className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Configuración
                            </button>
                          </div>
                          <div className="py-1 border-t">
                            <button 
                              onClick={handleLogout}
                              className="px-4 py-2 text-sm text-red-600 hover:bg-red-50 w-full text-left flex items-center gap-2"
                            >
                              <LogOut className="w-4 h-4" />
                              Cerrar sesión
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </header>

            {/* Page Content */}
            <main className="flex-1 p-3 sm:p-6 overflow-auto pb-20 md:pb-6 prevent-overflow">
              {children}
            </main>
            
            {/* Barra de navegación inferior para móvil */}
            <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t shadow-lg z-30">
              <div className="flex justify-between px-1 py-2 overflow-x-auto">
                {accessibleTabs.slice(0, 4).map(tab => (
                  <button
                    key={tab.id}
                    onClick={() => handleTabChange(tab.id)}
                    className={`flex flex-col items-center justify-center p-2 rounded-md flex-1 ${
                      activeTab === tab.id 
                        ? 'text-blue-600' 
                        : 'text-gray-500 hover:text-gray-700'
                    }`}
                  >
                    <tab.icon className="w-5 h-5" />
                    <span className="text-xs mt-1 font-medium truncate max-w-[60px]">{tab.name}</span>
                  </button>
                ))}
                
                <button
                  onClick={toggleSidebar}
                  className="flex flex-col items-center justify-center p-2 rounded-md flex-1 text-gray-500 hover:text-gray-700"
                >
                  <Menu className="w-5 h-5" />
                  <span className="text-xs mt-1 font-medium">Más</span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}