import { useState } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import DashboardTab from '../components/admin/DashboardTab';
import ProductsTab from '../components/admin/ProductsTab';
import UsersTab from '../components/admin/UsersTab';
import OrdersTab from '../components/admin/OrdersTab';
import CategoriesTab from '../components/admin/CategoriesTab';
import CollectionsTab from '../components/admin/CollectionsTab';
import POSTab from '../components/admin/POSTab';
import SlidersTab from '../components/admin/SlidersTab';

type TabId = 'dashboard' | 'pos' | 'products' | 'categories' | 'collections' | 'users' | 'orders' | 'settings' | 'sliders';

export default function Admin() {
  const [activeTab, setActiveTab] = useState<TabId>('dashboard');

  return (
    <AdminLayout activeTab={activeTab} onTabChange={setActiveTab}>
      <div className="space-y-6">
        {activeTab === 'dashboard' && <DashboardTab />}
        {activeTab === 'pos' && <POSTab />}
        {activeTab === 'products' && <ProductsTab />}
        {activeTab === 'categories' && <CategoriesTab />}
        {activeTab === 'collections' && <CollectionsTab />}
        {activeTab === 'users' && <UsersTab />}
        {activeTab === 'orders' && <OrdersTab />}
        {activeTab === 'sliders' && <SlidersTab />}
        {activeTab === 'settings' && (
          <div className="bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900">Configuración</h2>
            <p className="text-gray-500 mt-2">
              Configuración del sistema en desarrollo...
            </p>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}