import { Helmet } from 'react-helmet-async';

export default function Returns() {
  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <Helmet>
        <title>Política de Devoluciones | Disolgua</title>
        <meta name="description" content="Política de devoluciones de Disolgua" />
      </Helmet>
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Política de Devoluciones</h1>
      
      <div className="prose prose-lg max-w-none">
        <p className="mb-4">
          En Disolgua, nos esforzamos por garantizar su satisfacción con cada compra. 
          Entendemos que en ocasiones puede ser necesario devolver un producto, por lo que 
          hemos establecido la siguiente política de devoluciones para facilitar este proceso.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Plazo para devoluciones</h2>
        <p className="mb-4">
          Aceptamos devoluciones dentro de los 15 días calendario posteriores a la recepción del producto.
          Para ser elegible para una devolución, el artículo debe estar en las mismas condiciones en que lo recibió:
          sin usar, sin daños, en su empaque original y con todas las etiquetas intactas.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Motivos para devoluciones</h2>
        <p className="mb-4">
          Aceptamos devoluciones por los siguientes motivos:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Producto defectuoso o dañado</li>
          <li>Producto incorrecto (diferente al ordenado)</li>
          <li>Producto no cumple con la descripción del sitio web</li>
          <li>Cambio de opinión (sujeto a condiciones)</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Proceso de devolución</h2>
        <p className="mb-4">
          Para iniciar una devolución, siga estos pasos:
        </p>
        <ol className="list-decimal pl-6 mb-4">
          <li>Contacte a nuestro servicio al cliente a través de info@disolgua.com o llame al 2238-0714 
          dentro de los 15 días posteriores a la recepción del producto.</li>
          <li>Proporcione su número de pedido, información de contacto y el motivo de la devolución.</li>
          <li>Nuestro equipo le proporcionará un número de autorización de devolución (RMA) y las instrucciones 
          para enviar el producto.</li>
          <li>Empaque el producto de manera segura, incluyendo todos los accesorios, manuales y la factura original.</li>
          <li>Envíe el paquete a la dirección proporcionada, incluyendo el número RMA en el exterior del paquete.</li>
        </ol>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Costos de devolución</h2>
        <p className="mb-4">
          Los costos de devolución se manejan de la siguiente manera:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li><strong>Producto defectuoso, dañado o incorrecto:</strong> Disolgua cubrirá los costos de envío para la devolución.</li>
          <li><strong>Cambio de opinión:</strong> El cliente será responsable de los costos de envío para la devolución.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Reembolsos</h2>
        <p className="mb-4">
          Una vez que recibamos e inspeccionemos el producto devuelto, le notificaremos si su reembolso ha sido aprobado.
          Si es aprobado, el reembolso se procesará de la siguiente manera:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Para pagos con tarjeta de crédito o débito: el reembolso se aplicará a la misma tarjeta, 
          y puede tomar de 5 a 10 días hábiles para reflejarse en su estado de cuenta.</li>
          <li>Para pagos en efectivo o depósito: el reembolso se realizará mediante transferencia bancaria 
          a la cuenta que nos proporcione.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Productos no reembolsables</h2>
        <p className="mb-4">
          Algunos productos no son elegibles para devolución o reembolso:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Productos personalizados o fabricados según especificaciones del cliente</li>
          <li>Productos con sellos de seguridad rotos o empaques abiertos (por razones de higiene)</li>
          <li>Productos en oferta o liquidación (a menos que estén defectuosos)</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Cambios</h2>
        <p className="mb-4">
          Si desea cambiar un producto por otro, siga el mismo proceso de devolución y especifique 
          el producto que desea recibir como reemplazo. La disponibilidad del producto de reemplazo 
          está sujeta a existencias.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Contacto</h2>
        <p className="mb-4">
          Si tiene alguna pregunta sobre nuestra política de devoluciones, póngase en contacto con 
          nuestro equipo de atención al cliente:
        </p>
        <p className="mb-4">
          <strong>Correo electrónico:</strong> info@disolgua.com<br />
          <strong>Teléfono:</strong> 2238-0714
        </p>
      </div>
    </div>
  );
} 