import { Helmet } from 'react-helmet-async';

export default function Privacy() {
  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <Helmet>
        <title>Política de Privacidad | Disolgua</title>
        <meta name="description" content="Política de privacidad de Disolgua" />
      </Helmet>
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Política de Privacidad</h1>
      
      <div className="prose prose-lg max-w-none">
        <p className="mb-4">
          En Disolgua, valoramos y respetamos su privacidad. Esta Política de Privacidad describe cómo recopilamos, 
          utilizamos y compartimos su información personal cuando visita nuestro sitio web.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Información que recopilamos</h2>
        <p className="mb-4">
          Recopilamos información personal que usted nos proporciona directamente, como su nombre, dirección de correo 
          electrónico, dirección postal, número de teléfono y datos de pago cuando realiza un pedido o crea una cuenta.
        </p>
        <p className="mb-4">
          También recopilamos automáticamente cierta información sobre su dispositivo, incluida la dirección IP, 
          el tipo de navegador, las páginas de referencia/salida y el sistema operativo.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Cómo utilizamos su información</h2>
        <p className="mb-4">Utilizamos la información que recopilamos para:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>Procesar y gestionar sus pedidos</li>
          <li>Comunicarnos con usted sobre su cuenta o pedidos</li>
          <li>Enviarle información sobre productos, servicios y promociones</li>
          <li>Mejorar y personalizar su experiencia en nuestro sitio web</li>
          <li>Cumplir con nuestras obligaciones legales</li>
        </ul>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Compartir su información</h2>
        <p className="mb-4">
          No vendemos ni alquilamos su información personal a terceros. Podemos compartir su información personal con 
          proveedores de servicios que nos ayudan a operar nuestro sitio web y entregar productos y servicios.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Seguridad de los datos</h2>
        <p className="mb-4">
          Implementamos medidas de seguridad diseñadas para proteger su información personal. Sin embargo, ningún 
          sistema de seguridad es impenetrable y no podemos garantizar la seguridad de nuestros sistemas al 100%.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Sus derechos</h2>
        <p className="mb-4">
          Usted tiene derecho a acceder, corregir o eliminar su información personal. Si desea ejercer estos derechos, 
          póngase en contacto con nosotros a través de info@disolgua.com.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Cambios a esta política</h2>
        <p className="mb-4">
          Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio 
          publicando la nueva Política de Privacidad en esta página.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">Contacto</h2>
        <p className="mb-4">
          Si tiene alguna pregunta sobre esta Política de Privacidad, póngase en contacto con nosotros en:
        </p>
        <p className="mb-4">
          <strong>Correo electrónico:</strong> info@disolgua.com<br />
          <strong>Teléfono:</strong> 2238-0714
        </p>
      </div>
    </div>
  );
} 