import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ShoppingCart, Package, Truck, Shield, Star, ChevronDown, ChevronUp, Loader2, Heart, Share2, ArrowLeft, Info, Check, X, Facebook, Twitter, Linkedin, Copy, MessageCircle, Sliders, Phone, ArrowRight, Mail } from 'lucide-react';
import SEO from '../components/SEO';
import AddToCartButton from '../components/AddToCartButton';
import { supabase } from '../lib/supabase';
import { Helmet } from 'react-helmet-async';
import { useCart } from '../hooks/useCart';
import { useCartContext } from '../contexts/CartContext';

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number;
  main_image: string;
  stock: number;
  min_order: number;
  status: 'active' | 'inactive' | 'out_of_stock';
  code?: string;
  barcode?: string;
  category: {
    id: string;
    name: string;
    slug: string;
  };
  gallery?: {
    id: string;
    image_url: string;
    order: number;
  }[];
  specifications?: {
    name: string;
    value: string;
    order: number;
  }[];
  prices?: {
    price_type: 'contado' | 'credito' | 'mayorista' | 'publico';
    min_quantity: number;
    price: number;
  }[];
}

export default function ProductDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(0);
  const [showAllSpecs, setShowAllSpecs] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [addingToCart, setAddingToCart] = useState(false);
  const [activeTab, setActiveTab] = useState<'description' | 'specifications'>('description');
  const [zoomedImage, setZoomedImage] = useState(false);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [userPriceType, setUserPriceType] = useState<'contado' | 'credito' | 'mayorista' | 'publico' | null>(null);
  const [user, setUser] = useState<any>(null);
  const [currentPrice, setCurrentPrice] = useState<number | null>(null);
  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  
  // Usar el hook useCart
  const { addToCart, loading: cartLoading } = useCart({
    onSuccess: () => {
      setToastMessage(`${product?.name} agregado al carrito`);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    },
    onError: (errorMsg) => {
      setError(errorMsg);
    }
  });
  
  // Usar el contexto del carrito para acceder a refreshCart
  const { refreshCart } = useCartContext();

  useEffect(() => {
    fetchProduct();
    fetchUserPriceType();
    checkUser();
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    updateCurrentPrice();
  }, [product, user, userPriceType, quantity]);

  const checkUser = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    setUser(user);
  };

  const fetchUserPriceType = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: profile, error } = await supabase
        .from('profiles')
        .select('price_type')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      console.log('Tipo de precio del usuario:', profile?.price_type);
      setUserPriceType(profile?.price_type || null);
    } catch (err) {
      console.error('Error fetching user price type:', err);
    }
  };

  const fetchProduct = async () => {
    try {
      const { data, error } = await supabase
        .from('products')
        .select(`
          *,
          category:categories(id, name, slug),
          gallery:product_images(id, image_url, order),
          specifications:product_specifications(name, value, order),
          prices:product_prices(price_type, min_quantity, price)
        `)
        .eq('id', id)
        .single();

      if (error) throw error;

      if (!data) {
        setError('Producto no encontrado');
        return;
      }

      console.log('Producto cargado:', data);
      console.log('Precios del producto:', data.prices);

      // Ordenar la galería por el campo order
      if (data.gallery) {
        data.gallery.sort((a: any, b: any) => a.order - b.order);
      }

      // Ordenar las especificaciones por el campo order
      if (data.specifications) {
        data.specifications.sort((a: any, b: any) => a.order - b.order);
      }

      setProduct(data);
      setSelectedImage(data.main_image);
      setQuantity(data.min_order || 1);

      // Cargar productos relacionados
      fetchRelatedProducts(data.category.id, data.id);
      
      updateCurrentPrice();
    } catch (error: any) {
      console.error('Error al cargar el producto:', error);
      setError(error.message || 'Error al cargar el producto');
    } finally {
      setLoading(false);
    }
  };

  const fetchRelatedProducts = async (categoryId: string, currentProductId: string) => {
    try {
      const { data, error } = await supabase
        .from('products')
        .select(`
          id,
          name,
          description,
          base_price,
          main_image,
          stock,
          min_order,
          status,
          code,
          category_id,
          prices:product_prices(price_type, min_quantity, price)
        `)
        .eq('category_id', categoryId)
        .neq('id', currentProductId)
        .eq('status', 'active')
        .limit(4);

      if (error) throw error;

      // Convertir los datos a tipo Product
      const relatedProductsData = data?.map(item => ({
        ...item,
        category: { id: item.category_id, name: '', slug: '' }
      })) as Product[];

      setRelatedProducts(relatedProductsData || []);
    } catch (error) {
      console.error('Error al cargar productos relacionados:', error);
    }
  };

  const updateCurrentPrice = () => {
    if (!product) {
      setCurrentPrice(null);
      return;
    }

    console.log('Actualizando precio actual...');
    console.log('Tipo de precio del usuario:', userPriceType);
    console.log('Precios disponibles:', product.prices);

    // Si no hay precios definidos o no hay usuario, usar el precio base
    if (!product.prices || product.prices.length === 0) {
      console.log('No hay precios definidos, usando precio base:', product.base_price);
      setCurrentPrice(product.base_price);
      return;
    }

    // Si el usuario tiene asignado el tipo de precio "mayorista", usar el precio correspondiente
    if (userPriceType === 'mayorista') {
      console.log('Usuario con tipo de precio mayorista');
      // Buscar precios de mayorista
      const mayoristaPrice = product.prices.find(p => p.price_type === 'mayorista');
      console.log('Precio mayorista encontrado:', mayoristaPrice);
      
      if (mayoristaPrice) {
        console.log('Estableciendo precio mayorista:', mayoristaPrice.price);
        setCurrentPrice(mayoristaPrice.price);
        // Establecer la cantidad mínima según el precio de mayorista
        if (quantity < mayoristaPrice.min_quantity) {
          console.log('Ajustando cantidad mínima a:', mayoristaPrice.min_quantity);
          setQuantity(mayoristaPrice.min_quantity);
        }
      } else {
        // Si no hay precio de mayorista, usar el precio base
        console.log('No hay precio mayorista, usando precio base:', product.base_price);
        setCurrentPrice(product.base_price);
        if (quantity < product.min_order) {
          setQuantity(product.min_order);
        }
      }
      return;
    }

    // Filtrar precios por tipo si el usuario tiene un tipo asignado
    let applicablePrices: typeof product.prices = [];
    if (userPriceType) {
      applicablePrices = product.prices.filter(p => p.price_type === userPriceType);
    }

    // Si no hay precios aplicables después del filtro, usar el precio base
    if (applicablePrices.length === 0) {
      setCurrentPrice(product.base_price);
      return;
    }

    // Encontrar el precio aplicable con la menor cantidad mínima para el tipo de precio del usuario
    const minPriceForType = applicablePrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
    
    // Establecer la cantidad mínima según la lista de precios asignada al usuario
    if (quantity < minPriceForType.min_quantity) {
      setQuantity(minPriceForType.min_quantity);
    }
    
    // Establecer el precio según la lista de precios asignada al usuario
    setCurrentPrice(minPriceForType.price);
  };

  const handleAddToCart = async () => {
    if (!id || !product) return;
    
    try {
      setAddingToCart(true);
      
      // Verificar autenticación
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        navigate('/login');
        return;
      }
      
      // Usar el hook para añadir al carrito
      await addToCart(id, quantity);
      
      // Asegurar que el carrito se actualice
      await refreshCart();
      
    } catch (error) {
      console.error('Error al agregar al carrito:', error);
      setError('Error al agregar el producto al carrito');
    } finally {
      setAddingToCart(false);
    }
  };

  const handleQuantityChange = (newQuantity: number) => {
    // Obtener la cantidad mínima según la lista de precios asignada al usuario
    let minQuantity = product?.min_order || 1;
    
    // Si el usuario tiene asignado el tipo de precio "mayorista"
    if (userPriceType === 'mayorista' && product?.prices) {
      // Buscar precios de mayorista
      const mayoristaPrice = product.prices.find(p => p.price_type === 'mayorista');
      
      if (mayoristaPrice) {
        minQuantity = Math.max(minQuantity, mayoristaPrice.min_quantity);
      }
    } else if (product?.prices && product.prices.length > 0 && userPriceType) {
      // Filtrar por tipo de precio del usuario
      const userPrices = product.prices.filter(p => p.price_type === userPriceType);
      
      if (userPrices.length > 0) {
        // Obtener la cantidad mínima más baja para el tipo de precio del usuario
        const minPriceForType = userPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
        minQuantity = Math.max(minQuantity, minPriceForType.min_quantity);
      }
    }
    
    // Establecer la cantidad, respetando la cantidad mínima
    setQuantity(Math.max(minQuantity, newQuantity));
  };

  const incrementQuantity = () => {
    setQuantity(prev => prev + 1);
  };

  const decrementQuantity = () => {
    // Obtener la cantidad mínima según la lista de precios asignada al usuario
    let minQuantity = product?.min_order || 1;
    
    // Si el usuario tiene asignado el tipo de precio "mayorista"
    if (userPriceType === 'mayorista' && product?.prices) {
      // Buscar precios de mayorista
      const mayoristaPrice = product.prices.find(p => p.price_type === 'mayorista');
      
      if (mayoristaPrice) {
        minQuantity = Math.max(minQuantity, mayoristaPrice.min_quantity);
      }
    } else if (product?.prices && product.prices.length > 0 && userPriceType) {
      // Filtrar por tipo de precio del usuario
      const userPrices = product.prices.filter(p => p.price_type === userPriceType);
      
      if (userPrices.length > 0) {
        // Obtener la cantidad mínima más baja para el tipo de precio del usuario
        const minPriceForType = userPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
        minQuantity = Math.max(minQuantity, minPriceForType.min_quantity);
      }
    }
    
    // Solo decrementar si la cantidad actual es mayor que la cantidad mínima
    if (quantity > minQuantity) {
      setQuantity(prev => prev - 1);
    }
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const shareUrl = typeof window !== 'undefined' ? window.location.href : '';
  
  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
    setShowShareOptions(false);
  };
  
  const shareOnTwitter = () => {
    const text = product ? `Mira este producto: ${product.name}` : 'Mira este producto';
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(shareUrl)}`, '_blank');
    setShowShareOptions(false);
  };
  
  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`, '_blank');
    setShowShareOptions(false);
  };
  
  const shareOnWhatsApp = () => {
    const text = product ? `Mira este producto de Disolgua: ${product.name} - Precio: Q${product.base_price.toFixed(2)} ${shareUrl}` : `Mira este producto ${shareUrl}`;
    window.open(`https://wa.me/?text=${encodeURIComponent(text)}`, '_blank');
    setShowShareOptions(false);
  };
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
    setShowShareOptions(false);
  };

  const generateMetaTags = () => {
    if (!product) return null;

    const formattedPrice = currentPrice?.toFixed(2);
    const availability = product.stock > 0 ? 'in stock' : 'out of stock';
    const productUrl = typeof window !== 'undefined' ? window.location.href : '';
    
    // Generar palabras clave relevantes
    const keywords = [
      product.name,
      product.category?.name,
      'comprar',
      'Guatemala',
      'mayorista',
      'distribuidor',
      `precio ${userPriceType || ''}`,
      availability === 'in stock' ? 'en stock' : 'agotado',
      ...product.specifications?.map(spec => `${spec.name} ${spec.value}`) || []
    ].filter(Boolean);

    // Generar una descripción optimizada
    const description = `Compra ${product.name} al mejor precio en Guatemala. ${
      product.stock > 0 ? `Disponible en stock. Pedido mínimo: ${product.min_order} unidades.` : 'Temporalmente agotado.'
    } ${product.description.substring(0, 100)}...`;

    // Datos estructurados para Google (JSON-LD)
    const structuredData = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": product.name,
      "description": product.description,
      "image": [product.main_image, ...(product.gallery?.map(img => img.image_url) || [])],
      "sku": product.id,
      "mpn": product.id,
      "brand": {
        "@type": "Brand",
        "name": "Disolgua"
      },
      "offers": {
        "@type": "Offer",
        "url": productUrl,
        "priceCurrency": "GTQ",
        "price": formattedPrice,
        "priceValidUntil": new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        "itemCondition": "https://schema.org/NewCondition",
        "availability": `https://schema.org/${product.stock > 0 ? 'InStock' : 'OutOfStock'}`,
        "seller": {
          "@type": "Organization",
          "name": "Disolgua"
        }
      },
      "category": product.category?.name,
      ...(product.specifications?.length ? {
        "additionalProperty": product.specifications.map(spec => ({
          "@type": "PropertyValue",
          "name": spec.name,
          "value": spec.value
        }))
      } : {})
    };

    return (
      <Helmet>
        {/* Títulos y descripciones básicas */}
        <title>{`${product.name} | Comprar al por mayor en Disolgua Guatemala`}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords.join(', ')} />

        {/* Open Graph para redes sociales */}
        <meta property="og:title" content={`${product.name} | Disolgua`} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={product.main_image} />
        <meta property="og:url" content={productUrl} />
        <meta property="og:type" content="product" />
        <meta property="og:site_name" content="Disolgua" />
        <meta property="og:price:amount" content={formattedPrice} />
        <meta property="og:price:currency" content="GTQ" />
        <meta property="product:price:amount" content={formattedPrice} />
        <meta property="product:price:currency" content="GTQ" />
        <meta property="product:availability" content={availability} />

        {/* Twitter Card */}
        <meta name="twitter:card" content="product" />
        <meta name="twitter:title" content={product.name} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={product.main_image} />

        {/* Metadatos adicionales */}
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="canonical" href={productUrl} />

        {/* JSON-LD para datos estructurados */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
    );
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col items-center">
            <Loader2 className="w-12 h-12 text-blue-600 animate-spin" />
            <p className="mt-4 text-gray-600">Cargando producto...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error || !product) {
    return (
      <>
        <Helmet>
          <title>Producto no encontrado | Disolgua</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>
        <div className="max-w-7xl mx-auto px-4 py-12">
          <div className="bg-red-50 border border-red-200 rounded-lg p-6 text-red-600 flex flex-col items-center">
            <div className="mb-4 text-red-500">
              <Info className="w-12 h-12" />
            </div>
            <h2 className="text-xl font-semibold mb-2">No se pudo cargar el producto</h2>
            <p>{error || 'Producto no encontrado'}</p>
            <button 
              onClick={() => navigate(-1)} 
              className="mt-6 flex items-center gap-2 text-blue-600 hover:text-blue-800"
            >
              <ArrowLeft className="w-4 h-4" />
              Volver
            </button>
          </div>
        </div>
      </>
    );
  }

  const visibleSpecs = showAllSpecs
    ? product.specifications
    : product.specifications?.slice(0, 4);

  const gallery = [product.main_image, ...(product.gallery?.map(img => img.image_url) || [])];

  return (
    <>
      {generateMetaTags()}
      <div className="bg-white">
        {/* Toast notification */}
        {showToast && (
          <div className="fixed top-4 right-4 bg-green-600 text-white px-6 py-3 rounded-lg shadow-lg z-50 flex items-center animate-fade-in-right">
            <Check className="w-5 h-5 mr-3" />
            <span className="font-medium">{toastMessage}</span>
          </div>
        )}
        
        {/* Breadcrumbs */}
        <div className="max-w-7xl mx-auto px-4 py-4">
          <nav className="flex items-center text-sm">
            <button onClick={() => navigate('/')} className="text-gray-500 hover:text-blue-600 transition-colors">Inicio</button>
            <span className="mx-2 text-gray-400">/</span>
            <button onClick={() => navigate('/catalog')} className="text-gray-500 hover:text-blue-600 transition-colors">Catálogo</button>
            {product.category && (
              <>
                <span className="mx-2 text-gray-400">/</span>
                <button 
                  onClick={() => navigate(`/category/${product.category.slug}`)} 
                  className="text-gray-500 hover:text-blue-600 transition-colors"
                >
                  {product.category.name}
                </button>
              </>
            )}
            <span className="mx-2 text-gray-400">/</span>
            <span className="text-gray-900 font-medium truncate">{product.name}</span>
          </nav>
        </div>
        
        <div className="max-w-7xl mx-auto px-4 pb-16">
          <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
            {/* Galería de imágenes */}
            <div className="space-y-6">
              <div 
                className={`relative rounded-xl overflow-hidden bg-white border shadow-sm ${zoomedImage ? 'cursor-zoom-out' : 'cursor-zoom-in'}`}
                onClick={() => setZoomedImage(!zoomedImage)}
              >
                {product.status === 'out_of_stock' && (
                  <div className="absolute top-4 left-4 bg-red-500 text-white px-4 py-1 rounded-lg text-sm font-medium z-10 shadow-sm">
                    Agotado
                  </div>
                )}
                
                {/* Badges flotantes */}
                <div className="absolute top-4 right-4 flex flex-col gap-2 z-10">
                 
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShare();
                    }}
                    className="p-2 bg-white rounded-full shadow-md hover:bg-gray-50 transition-colors"
                    aria-label="Compartir producto"
                  >
                    <Share2 className="w-5 h-5 text-blue-600" />
                  </button>
                </div>
                
                <div className={`aspect-square bg-gradient-to-br from-blue-50 to-white transition-transform duration-300 ${zoomedImage ? 'scale-150' : 'scale-100'}`}>
                  <img
                    src={selectedImage || ''}
                    alt={product.name}
                    className="w-full h-full object-contain mix-blend-multiply"
                  />
                </div>
              </div>
              
              {gallery.length > 1 && (
                <div className="grid grid-cols-6 gap-2">
                  {gallery.map((image) => (
                    <button
                      key={image}
                      onClick={() => setSelectedImage(image)}
                      className={`aspect-square rounded-lg overflow-hidden bg-white border-2 transition-all hover:opacity-90 hover:shadow-md ${
                        selectedImage === image ? 'border-blue-600 shadow-md' : 'border-gray-200'
                      }`}
                    >
                      <img
                        src={image}
                        alt={product.name}
                        className="w-full h-full object-cover mix-blend-multiply"
                      />
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Información del producto */}
            <div className="space-y-6">
              <div>
                <div className="mb-4">
                  <div className="flex items-start justify-between">
                    <h1 className="text-3xl font-bold text-gray-900 mb-2">{product.name}</h1>
                  </div>
                  
                  {/* Código y disponibilidad */}
                  <div className="flex flex-wrap items-center gap-4 mb-4">
                    <div className="flex items-center gap-2 py-1 px-3 bg-gray-100 rounded-full">
                      <div className={`w-3 h-3 rounded-full ${product.stock > 0 ? 'bg-green-500' : 'bg-red-500'}`}></div>
                      <span className="text-sm font-medium text-gray-700">
                        {product.stock > 0 ? 'En stock' : 'Agotado'}
                      </span>
                    </div>
                    <div className="text-sm text-gray-600 py-1 px-3 bg-gray-100 rounded-full">
                      Código: <span className="font-medium">{product.code || product.id.substring(0, 8).toUpperCase()}</span>
                    </div>
                  
                  </div>

                  {/* Resumen de producto */}
                  <div className="bg-gray-50 rounded-xl p-4 mb-6">
                    <div className="prose prose-sm max-w-none">
                      <p className="text-gray-700 line-clamp-3">
                        {product.description.substring(0, 180)}
                        {product.description.length > 180 ? '...' : ''}
                      </p>
                    </div>
                    <button 
                      onClick={() => document.getElementById('descripcion')?.scrollIntoView({ behavior: 'smooth' })} 
                      className="text-sm text-blue-600 hover:text-blue-800 mt-2 font-medium flex items-center gap-1"
                    >
                      Ver descripción completa
                      <ArrowRight className="w-4 h-4" />
                    </button>
                  </div>
                </div>

                <h2 className="text-xl font-bold text-gray-900 mb-4 flex items-center">
                  <Sliders className="w-5 h-5 mr-2 text-blue-600" />
                  Medidas del producto
                </h2>
                {product.specifications && product.specifications.length > 0 ? (
                  <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
                    {product.specifications.map((spec, index) => (
                      <div
                        key={index}
                        className={`flex items-start p-4 ${
                          index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                        } hover:bg-blue-50 transition-colors duration-150`}
                      >
                        <div className="flex-1">
                          <h3 className="text-sm font-semibold text-gray-700 mb-1">{spec.name}</h3>
                          <p className="text-sm text-gray-600">{spec.value}</p>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <div className="w-2 h-2 rounded-full bg-blue-600"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="bg-gray-50 rounded-xl p-6 text-center">
                    <Sliders className="w-12 h-12 text-gray-400 mx-auto mb-3" />
                    <p className="text-gray-500">No hay especificaciones disponibles para este producto.</p>
                  </div>
                )}
              </div>

              {/* Precio */}
              <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl border border-blue-100 shadow-sm">
                <div className="flex items-baseline gap-2 mb-4">
                  <span className="text-4xl font-bold text-blue-700">
                    Q {currentPrice?.toFixed(2)}
                  </span>
                  {quantity >= product.min_order && (
                    <span className="text-sm text-gray-600">por unidad</span>
                  )}
                </div>
                
                {product.prices && product.prices.length > 0 && (
                  <div className="space-y-3">
                    {/* Verificar si el usuario está autenticado */}
                    {user ? (
                      <>
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-medium text-gray-700">Precios por volumen:</p>
                          <div className="text-xs px-2 py-0.5 bg-blue-100 text-blue-700 rounded-full font-medium">
                            Mejores precios
                          </div>
                        </div>
                        
                        {/* Si el usuario tiene un tipo asignado, mostrar solo esos precios */}
                        {userPriceType ? (
                          <>
                            {product.prices.some(p => p.price_type === userPriceType) ? (
                              <div className="space-y-2">
                                <div className="grid grid-cols-2 gap-3">
                                  {product.prices
                                    .filter(price => price.price_type === userPriceType)
                                    .sort((a, b) => a.min_quantity - b.min_quantity)
                                    .map((price) => (
                                      <div
                                        key={`${price.price_type}-${price.min_quantity}`}
                                        className={`bg-white p-3 rounded-lg border text-sm flex justify-between items-center ${
                                          quantity >= price.min_quantity 
                                            ? 'border-blue-400 bg-blue-50'
                                            : 'border-gray-200'
                                        }`}
                                      >
                                        <span className="font-medium">{price.min_quantity}+ unidades</span>
                                        <span className={`font-bold ${
                                          quantity >= price.min_quantity ? 'text-blue-700' : 'text-gray-700'
                                        }`}>
                                          Q {price.price}
                                          {quantity >= price.min_quantity && (
                                            <Check className="w-4 h-4 inline ml-1 text-blue-600" />
                                          )}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ) : (
                              <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 text-sm text-amber-700 flex items-center gap-2">
                                <Info className="w-4 h-4 flex-shrink-0" />
                                <span>Contacta a tu asesor de ventas para precios especiales</span>
                              </div>
                            )}
                          </>
                        ) : (
                          // Si el usuario no tiene tipo asignado, mostrar todos los precios
                          <div className="space-y-4">
                            {/* Precios de Contado */}
                            {product.prices.some(p => p.price_type === 'contado') && (
                              <div className="space-y-2">
                                <div className="grid grid-cols-2 gap-3">
                                  {product.prices
                                    .filter(price => price.price_type === 'contado')
                                    .sort((a, b) => a.min_quantity - b.min_quantity)
                                    .map((price) => (
                                      <div
                                        key={`${price.price_type}-${price.min_quantity}`}
                                        className={`bg-white p-3 rounded-lg border text-sm flex justify-between items-center ${
                                          quantity >= price.min_quantity ? 'border-blue-400 bg-blue-50' : 'border-gray-200'
                                        }`}
                                      >
                                        <span className="font-medium">{price.min_quantity}+ unidades</span>
                                        <span className={`font-bold ${quantity >= price.min_quantity ? 'text-blue-700' : 'text-gray-700'}`}>
                                          Q {price.price}
                                          {quantity >= price.min_quantity && <Check className="w-4 h-4 inline ml-1 text-blue-600" />}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Precios de Crédito */}
                            {product.prices.some(p => p.price_type === 'credito') && (
                              <div className="space-y-2">
                                <div className="grid grid-cols-2 gap-3">
                                  {product.prices
                                    .filter(price => price.price_type === 'credito')
                                    .sort((a, b) => a.min_quantity - b.min_quantity)
                                    .map((price) => (
                                      <div
                                        key={`${price.price_type}-${price.min_quantity}`}
                                        className={`bg-white p-3 rounded-lg border text-sm flex justify-between items-center ${
                                          quantity >= price.min_quantity ? 'border-blue-400 bg-blue-50' : 'border-gray-200'
                                        }`}
                                      >
                                        <span className="font-medium">{price.min_quantity}+ unidades</span>
                                        <span className={`font-bold ${quantity >= price.min_quantity ? 'text-blue-700' : 'text-gray-700'}`}>
                                          Q {price.price}
                                          {quantity >= price.min_quantity && <Check className="w-4 h-4 inline ml-1 text-blue-600" />}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Precios de Mayorista */}
                            {product.prices.some(p => p.price_type === 'mayorista') && (
                              <div className="space-y-2">
                                <div className="grid grid-cols-2 gap-3">
                                  {product.prices
                                    .filter(price => price.price_type === 'mayorista')
                                    .sort((a, b) => a.min_quantity - b.min_quantity)
                                    .map((price) => (
                                      <div
                                        key={`${price.price_type}-${price.min_quantity}`}
                                        className={`bg-white p-3 rounded-lg border text-sm flex justify-between items-center ${
                                          quantity >= price.min_quantity ? 'border-blue-400 bg-blue-50' : 'border-gray-200'
                                        }`}
                                      >
                                        <span className="font-medium">{price.min_quantity}+ unidades</span>
                                        <span className={`font-bold ${quantity >= price.min_quantity ? 'text-blue-700' : 'text-gray-700'}`}>
                                          Q {price.price}
                                          {quantity >= price.min_quantity && <Check className="w-4 h-4 inline ml-1 text-blue-600" />}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}

                            {/* Precios Público */}
                            {product.prices?.some(p => p.price_type === 'publico') && (
                              <div className="mt-4 space-y-2">
                                <div className="grid grid-cols-2 gap-3">
                                  {product.prices
                                    .filter(price => price.price_type === 'publico')
                                    .map((price) => (
                                      <div
                                        key={`${price.price_type}-${price.min_quantity}`}
                                        className={`bg-white p-3 rounded-lg border text-sm flex justify-between items-center ${
                                          quantity >= 1 ? 'border-blue-400 bg-blue-50' : 'border-gray-200'
                                        }`}
                                      >
                                        <span className="font-medium">Precio unitario</span>
                                        <span className={`font-bold ${quantity >= 1 ? 'text-blue-700' : 'text-gray-700'}`}>
                                          Q {price.price}
                                          {quantity >= 1 && <Check className="w-4 h-4 inline ml-1 text-blue-600" />}
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      // Usuario no autenticado
                      <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 text-sm text-amber-700 flex items-center gap-2">
                        <Info className="w-4 h-4 flex-shrink-0" />
                        <span>Inicia sesión para ver precios especiales por volumen</span>
                      </div>
                    )}
                  </div>
                )}

                {/* Total del pedido */}
                <div className="mt-4 pt-4 border-t border-blue-200">
                  <div className="flex justify-between items-center">
                    <span className="text-sm font-medium text-gray-700">Total del pedido:</span>
                    <span className="text-xl font-bold text-blue-700">
                      Q {((currentPrice || 0) * quantity).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>

              {/* Cantidad y Añadir al carrito */}
              <div className="space-y-4">
                <div className="flex flex-col sm:flex-row items-start sm:items-end gap-4">
                  <div className="w-full sm:w-1/3">
                    <label htmlFor="quantity" className="block text-sm font-medium text-gray-700 mb-2">
                      Cantidad
                    </label>
                    <div className="flex shadow-sm rounded-lg overflow-hidden">
                      <button 
                        onClick={decrementQuantity}
                        disabled={quantity <= (product?.min_order || 1)}
                        className="px-3 py-2 border border-r-0 rounded-l-lg bg-gray-50 text-gray-600 hover:bg-gray-100 disabled:opacity-50 transition-colors"
                      >
                        <span className="sr-only">Decrementar</span>
                        <span className="text-lg font-bold">-</span>
                      </button>
                      <input
                        type="number"
                        id="quantity"
                        min={product?.min_order || 1}
                        value={quantity}
                        onChange={(e) => handleQuantityChange(parseInt(e.target.value) || product?.min_order || 1)}
                        className="w-full px-3 py-2 border text-center focus:ring-2 focus:ring-blue-500 focus:border-blue-500 z-10"
                      />
                      <button 
                        onClick={incrementQuantity}
                        className="px-3 py-2 border border-l-0 rounded-r-lg bg-gray-50 text-gray-600 hover:bg-gray-100 transition-colors"
                      >
                        <span className="sr-only">Incrementar</span>
                        <span className="text-lg font-bold">+</span>
                      </button>
                    </div>
                  </div>
                  <div className="w-full sm:w-2/3">
                    <button
                      onClick={handleAddToCart}
                      disabled={addingToCart || product.stock <= 0 || cartLoading}
                      className={`w-full py-3 px-4 rounded-lg font-semibold text-white flex items-center justify-center gap-2 transition-all ${
                        addingToCart || cartLoading
                          ? 'bg-blue-400 cursor-not-allowed'
                          : product.stock <= 0
                          ? 'bg-gray-400 cursor-not-allowed'
                          : 'bg-blue-600 hover:bg-blue-700 shadow-md hover:shadow-lg'
                      }`}
                    >
                      {addingToCart || cartLoading ? (
                        <>
                          <Loader2 className="w-5 h-5 animate-spin" />
                          <span>Agregando...</span>
                        </>
                      ) : product.stock <= 0 ? (
                        <>
                          <X className="w-5 h-5" />
                          <span>Producto agotado</span>
                        </>
                      ) : (
                        <>
                          <ShoppingCart className="w-5 h-5" />
                          <span>Agregar al carrito</span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
                
                {/* Mensajes informativos */}
                <div className="space-y-2">
                  {product?.min_order > 1 && (
                    <div className="bg-blue-50 border border-blue-200 rounded-lg p-3 text-sm text-blue-700 flex items-center gap-2">
                      <Info className="w-4 h-4 flex-shrink-0" />
                      <span>Pedido mínimo: {product.min_order} unidades</span>
                    </div>
                  )}
                  
                  {product.stock > 0 && product.stock < 10 && (
                    <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 text-sm text-amber-700 flex items-center gap-2">
                      <Info className="w-4 h-4 flex-shrink-0" />
                      <span>¡Últimas unidades disponibles!</span>
                    </div>
                  )}
                </div>

                {/* Beneficios */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 py-6 my-4 border-t border-b">
                  <div className="flex items-center gap-3 p-3 rounded-lg bg-gradient-to-r from-gray-50 to-white border border-gray-100">
                    <Package className="w-6 h-6 text-blue-600 flex-shrink-0" />
                    <div>
                      <p className="font-medium text-gray-900">Envío gratis</p>
                      <p className="text-xs text-gray-500">En pedidos mayoristas</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 p-3 rounded-lg bg-gradient-to-r from-gray-50 to-white border border-gray-100">
                    <Truck className="w-6 h-6 text-blue-600 flex-shrink-0" />
                    <div>
                      <p className="font-medium text-gray-900">Entrega rápida</p>
                      <p className="text-xs text-gray-500">En toda Guatemala</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-3 p-3 rounded-lg bg-gradient-to-r from-gray-50 to-white border border-gray-100">
                    <Shield className="w-6 h-6 text-blue-600 flex-shrink-0" />
                    <div>
                      <p className="font-medium text-gray-900">Garantía</p>
                      <p className="text-xs text-gray-500">Productos de calidad</p>
                    </div>
                  </div>
                </div>
                
                {/* Botón de consulta */}
                <a 
                  href={`https://wa.me/50247391242?text=${encodeURIComponent(`Hola, estoy interesado en el producto: ${product?.name} (Código: ${product?.code || product?.id.substring(0, 8).toUpperCase()}) - Precio: Q${product?.base_price.toFixed(2)}. ¿Podrían darme más información?`)}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center justify-center gap-2 border border-green-500 text-green-600 hover:bg-green-50 font-medium py-2 px-4 rounded-lg transition-colors w-full"
                >
                  <MessageCircle className="w-5 h-5" />
                  <span>Consultar por WhatsApp</span>
                </a>
              </div>
            </div>
          </div>

          {/* Descripción y Especificaciones (separados) */}
          <div className="mt-12 grid md:grid-cols-3 gap-8">
            {/* Descripción */}
            <div className="md:col-span-2">
              <div id="descripcion" className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-bold text-gray-900 flex items-center">
                    <Info className="w-5 h-5 mr-2 text-blue-600" />
                    Descripción del producto
                  </h2>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Detalle completo
                  </span>
                </div>
                <div className="prose prose-blue max-w-none">
                  <div className="space-y-6">
                    <div className="bg-white rounded-lg p-4 border border-gray-100">
                      <div className="prose prose-sm md:prose-base max-w-none">
                        <p className="text-gray-700 leading-relaxed whitespace-pre-line">
                          {product.description}
                        </p>
                      </div>
                    </div>
                    
                    {/* Características principales */}
                    <div className="mt-8">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                        <Star className="w-5 h-5 mr-2 text-yellow-500" />
                        Características destacadas
                      </h3>
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                          <ul className="space-y-2">
                            <li className="flex items-start">
                              <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                              <span className="text-gray-700">Producto de alta calidad y durabilidad garantizada</span>
                            </li>
                            <li className="flex items-start">
                              <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                              <span className="text-gray-700">Disponible para entrega inmediata en toda Guatemala</span>
                            </li>
                          </ul>
                        </div>
                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                          <ul className="space-y-2">
                            <li className="flex items-start">
                              <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                              <span className="text-gray-700">Precios especiales para mayoristas y distribuidores</span>
                            </li>
                            <li className="flex items-start">
                              <Check className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                              <span className="text-gray-700">Garantía de satisfacción y calidad en cada compra</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    
                    {/* Usos del producto */}
                    <div className="mt-8">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                        <Sliders className="w-5 h-5 mr-2 text-blue-600" />
                        Usos recomendados
                      </h3>
                      <div className="bg-blue-50 border border-blue-100 rounded-lg p-4">
                        <ul className="space-y-2">
                          <li className="flex items-start">
                            <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2"></div>
                            <span className="text-gray-700">Ideal para uso comercial e industrial</span>
                          </li>
                          <li className="flex items-start">
                            <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2"></div>
                            <span className="text-gray-700">Perfecto para negocios que buscan calidad y precio</span>
                          </li>
                          <li className="flex items-start">
                            <div className="w-2 h-2 rounded-full bg-blue-600 mt-2 mr-2"></div>
                            <span className="text-gray-700">Recomendado para tiendas y distribuidores</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Información de envío */}
              <div className="bg-white rounded-xl shadow-sm p-6 mt-6 border border-gray-100">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-xl font-bold text-gray-900 flex items-center">
                    <Truck className="w-5 h-5 mr-2 text-blue-600" />
                    Información de envío
                  </h2>
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Envío a todo el país
                  </span>
                </div>
                <div className="space-y-4">
                  <p className="text-gray-700">
                    Realizamos envíos a toda Guatemala. Los tiempos de entrega pueden variar según la ubicación:
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="w-3 h-3 rounded-full bg-green-500"></div>
                        <span className="font-semibold text-gray-900">Ciudad Capital</span>
                      </div>
                      <p className="text-sm text-gray-600">Entrega en 24-48 horas hábiles</p>
                    </div>
                    <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                        <span className="font-semibold text-gray-900">Cabeceras</span>
                      </div>
                      <p className="text-sm text-gray-600">Entrega en 2-3 días hábiles</p>
                    </div>
                    <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                      <div className="flex items-center gap-2 mb-2">
                        <div className="w-3 h-3 rounded-full bg-amber-500"></div>
                        <span className="font-semibold text-gray-900">Resto del país</span>
                      </div>
                      <p className="text-sm text-gray-600">Entrega en 3-5 días hábiles</p>
                    </div>
                  </div>
                  <p className="text-sm bg-blue-50 p-3 rounded-lg text-blue-700 mt-4 border border-blue-100">
                    Los envíos se realizan a través de nuestros transportistas de confianza. Para pedidos mayoristas, consulta nuestras condiciones especiales de envío.
                  </p>
                </div>
              </div>
            </div>

           
            <div className="md:col-span-1">
              {/* Información adicional */}
              <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
                <h2 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
                  <Info className="w-5 h-5 mr-2 text-blue-600" />
                  Información adicional
                </h2>
                <div className="space-y-3">
                  <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <h3 className="text-sm font-medium text-gray-700">Categoría</h3>
                    <p className="text-sm font-semibold text-gray-900 mt-1 flex items-center">
                      <span className="w-2 h-2 rounded-full bg-blue-600 mr-2"></span>
                      {product.category?.name || 'General'}
                    </p>
                  </div>
                  <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <h3 className="text-sm font-medium text-gray-700">Stock</h3>
                    <p className={`text-sm font-semibold mt-1 flex items-center ${
                      product.stock > 0 
                        ? 'text-green-700'
                        : 'text-red-700'
                    }`}>
                      <span className={`w-2 h-2 rounded-full mr-2 ${
                        product.stock > 0 
                          ? 'bg-green-600'
                          : 'bg-red-600'
                      }`}></span>
                      {product.stock > 0 ? 'Disponible' : 'Agotado'}
                    </p>
                  </div>
                  <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <h3 className="text-sm font-medium text-gray-700">Pedido mínimo</h3>
                    <p className="text-sm font-semibold text-gray-900 mt-1 flex items-center">
                      <span className="w-2 h-2 rounded-full bg-blue-600 mr-2"></span>
                      {product.min_order} unidades
                    </p>
                  </div>
                  <div className="bg-gray-50 p-3 rounded-lg border border-gray-100">
                    <h3 className="text-sm font-medium text-gray-700">Garantía</h3>
                    <p className="text-sm font-semibold text-gray-900 mt-1 flex items-center">
                      <span className="w-2 h-2 rounded-full bg-blue-600 mr-2"></span>
                      30 días contra defectos de fabricación
                    </p>
                  </div>
                </div>
              </div>

              {/* Contacto rápido */}
              <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-xl shadow-sm p-6 mt-6 border border-blue-200">
                <div className="flex items-center gap-3 mb-4">
                  <div className="w-10 h-10 rounded-full bg-blue-600 flex items-center justify-center text-white flex-shrink-0">
                    <Phone className="w-5 h-5" />
                  </div>
                  <h2 className="text-lg font-bold text-gray-900">¿Necesitas ayuda?</h2>
                </div>
                <p className="text-sm text-gray-700 mb-4">
                  Si tienes dudas sobre este producto o necesitas información adicional, contáctanos:
                </p>
                <div className="space-y-3">
                  <a 
                    href={`https://wa.me/50247391242?text=${encodeURIComponent(`Hola, estoy interesado en el producto: ${product?.name} (Código: ${product?.code || product?.id.substring(0, 8).toUpperCase()}). ¿Podrían darme más información?`)}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-2 bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded-lg transition-colors w-full shadow-sm"
                  >
                    <MessageCircle className="w-5 h-5" />
                    <span>WhatsApp</span>
                  </a>
                  <a 
                    href="tel:+50247391242" 
                    className="flex items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded-lg transition-colors w-full shadow-sm"
                  >
                    <Phone className="w-5 h-5" />
                    <span>Llamar: 4739-1242</span>
                  </a>
                  <button 
                    onClick={() => window.open('mailto:ventas@disolgua.com')}
                    className="flex items-center justify-center gap-2 bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium py-2 px-4 rounded-lg transition-colors w-full shadow-sm"
                  >
                    <Mail className="w-5 h-5 text-gray-600" />
                    <span>Email</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Productos relacionados */}
          <div className="mt-16">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-2xl font-bold text-gray-900 flex items-center">
                <Package className="w-6 h-6 mr-2 text-blue-600" />
                Productos relacionados
              </h2>
              <a 
                href={`/category/${product.category?.slug}`}
                className="text-sm font-medium text-blue-600 hover:text-blue-800 flex items-center"
              >
                Ver categoría completa
                <ArrowRight className="w-4 h-4 ml-1" />
              </a>
            </div>
            
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
              {relatedProducts.map((item) => {
                // Calcular el precio a mostrar
                let displayPrice = item.base_price;
                if (userPriceType && item.prices) {
                  const userPrices = item.prices.filter(p => p.price_type === userPriceType);
                  if (userPrices.length > 0) {
                    // Obtener el precio más bajo para el tipo de usuario
                    displayPrice = userPrices.reduce((min, p) => p.price < min ? p.price : min, userPrices[0].price);
                  }
                }

                return (
                  <div
                    key={item.id}
                    className="group"
                  >
                    <button
                      onClick={() => navigate('/product/' + item.id)}
                      className="bg-white rounded-xl overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 text-left w-full h-full border border-gray-100 hover:border-blue-200 flex flex-col"
                    >
                      <div className="aspect-square bg-gray-100 relative overflow-hidden">
                        {item.main_image && (
                          <img 
                            src={item.main_image} 
                            alt={item.name}
                            className="w-full h-full object-contain p-2 transition-transform duration-300 group-hover:scale-105 mix-blend-multiply"
                          />
                        )}
                        {item.stock <= 0 && (
                          <div className="absolute top-2 left-2 bg-red-500 text-white px-2 py-0.5 rounded text-xs font-medium">
                            Agotado
                          </div>
                        )}
                        {item.stock > 0 && item.stock <= 10 && (
                          <div className="absolute top-2 left-2 bg-amber-500 text-white px-2 py-0.5 rounded text-xs font-medium">
                            Últimas unidades
                          </div>
                        )}
                      </div>
                      <div className="p-4 flex flex-col flex-grow">
                        <h3 className="text-sm font-medium line-clamp-2 group-hover:text-blue-600 transition-colors mb-2">
                          {item.name}
                        </h3>
                        <div className="mt-auto space-y-1">
                          <p className="text-lg font-semibold text-blue-600">
                            Q {displayPrice.toFixed(2)}
                          </p>
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <div className={`w-2 h-2 rounded-full mr-1 ${item.stock > 0 ? 'bg-green-500' : 'bg-red-500'}`}></div>
                              <span className="text-xs text-gray-500">
                                {item.stock > 0 ? 'En stock' : 'Agotado'}
                              </span>
                            </div>
                            <div className="text-blue-600 text-xs font-medium flex items-center">
                              Ver detalles
                              <ArrowRight className="w-3 h-3 ml-1 transition-transform group-hover:translate-x-1" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                );
              })}
            </div>
            
            {/* Banner de contacto */}
            <div className="mt-16 bg-gradient-to-r from-blue-600 to-blue-800 rounded-xl shadow-lg p-8 text-white relative overflow-hidden">
              <div className="absolute top-0 right-0 w-64 h-64 bg-white opacity-10 rounded-full -mr-32 -mt-32"></div>
              <div className="absolute bottom-0 left-0 w-40 h-40 bg-white opacity-10 rounded-full -ml-20 -mb-20"></div>
              
              <div className="relative z-10">
                <div className="flex flex-col md:flex-row items-center justify-between gap-6">
                  <div className="md:w-2/3">
                    <h2 className="text-2xl font-bold mb-3">¿Necesitas un pedido mayorista?</h2>
                    <p className="text-blue-100 mb-4">Contáctanos para obtener precios especiales y condiciones de entrega a medida para tu negocio.</p>
                    <div className="flex flex-wrap gap-4">
                      <a 
                        href="tel:+50247391242" 
                        className="flex items-center gap-2 bg-white text-blue-700 hover:bg-blue-50 py-2 px-4 rounded-lg font-medium transition-colors"
                      >
                        <Phone className="w-5 h-5" />
                        <span>Llamar ahora</span>
                      </a>
                      <a 
                        href="https://wa.me/50247391242" 
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 bg-green-500 text-white hover:bg-green-600 py-2 px-4 rounded-lg font-medium transition-colors"
                      >
                        <MessageCircle className="w-5 h-5" />
                        <span>WhatsApp</span>
                      </a>
                    </div>
                  </div>
                  <div className="md:w-1/3 flex justify-center">
                    <div className="w-24 h-24 bg-white bg-opacity-20 rounded-full flex items-center justify-center">
                      <Package className="w-12 h-12" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 