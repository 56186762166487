import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ArrowLeft, ShoppingCart, Star, Package, Grid, List, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import SEO from '../components/SEO';
import { useAuth } from '../contexts/AuthContext';
import { usePriceType } from '../hooks/usePriceType';

interface Product {
  id: string;
  name: string;
  description: string;
  base_price: number;
  main_image: string;
  stock: number;
  min_order: number;
  status: 'active' | 'inactive' | 'out_of_stock';
  category: {
    name: string;
    slug: string;
  };
}

interface ProductResponse {
  id: string;
  name: string;
  description: string;
  base_price: number;
  main_image: string;
  stock: number;
  min_order: number;
  status: 'active' | 'inactive' | 'out_of_stock';
  category: Array<{
    name: string;
    slug: string;
  }>;
}

interface CollectionProductResponse {
  order: number;
  product: ProductResponse;
}

interface Collection {
  id: string;
  name: string;
  description: string | null;
  type: 'category_mix' | 'new' | 'featured' | 'sale';
  image: string | null;
  order: number;
  is_active: boolean;
  category_ids: string[] | null;
  created_at: string;
  updated_at: string | null;
}

export default function SeeMoreCollection() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [collection, setCollection] = useState<Collection | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [view, setView] = useState<'grid' | 'list'>('grid');
  const { user } = useAuth();
  const { priceType } = usePriceType();
  const [productPrices, setProductPrices] = useState<Record<string, number>>({});

  useEffect(() => {
    if (id) {
      fetchCollectionAndProducts();
    }
  }, [id]);

  useEffect(() => {
    const fetchProductPrices = async () => {
      if (!user || !priceType || !products.length) return;

      try {
        const productIds = products.map(p => p.id);
        
        // Obtener los precios específicos para los productos
        const { data: prices, error: pricesError } = await supabase
          .from('product_prices')
          .select('product_id, price_type, min_quantity, price')
          .in('product_id', productIds)
          .eq('price_type', priceType);
        
        if (pricesError) throw pricesError;
        
        // Crear un objeto con los precios específicos por producto
        const pricesMap: Record<string, number> = {};
        
        if (prices && prices.length > 0) {
          // Agrupar los precios por producto_id
          const pricesByProduct: Record<string, any[]> = {};
          
          prices.forEach(price => {
            if (!pricesByProduct[price.product_id]) {
              pricesByProduct[price.product_id] = [];
            }
            pricesByProduct[price.product_id].push(price);
          });
          
          // Para cada producto, obtener el precio con la menor cantidad mínima
          Object.keys(pricesByProduct).forEach(productId => {
            const productPrices = pricesByProduct[productId];
            const minPriceForType = productPrices.sort((a, b) => a.min_quantity - b.min_quantity)[0];
            pricesMap[productId] = minPriceForType.price;
          });
        }
        
        setProductPrices(pricesMap);
      } catch (err) {
        console.error('Error fetching product prices:', err);
      }
    };

    fetchProductPrices();
  }, [user, priceType, products]);

  const fetchCollectionAndProducts = async () => {
    try {
      setLoading(true);
      setError(null);

      // Obtener la colección por ID
      const { data: collection, error: collectionError } = await supabase
        .from('product_collections')
        .select(`
          id,
          name,
          description,
          type,
          image,
          order,
          is_active,
          category_ids,
          created_at,
          updated_at
        `)
        .eq('id', id)
        .eq('is_active', true)
        .single();

      if (collectionError) {
        if (collectionError.code === 'PGRST116') {
          throw new Error('Colección no encontrada');
        }
        throw collectionError;
      }

      if (!collection) {
        throw new Error('Colección no encontrada');
      }

      setCollection(collection);

      // Obtener los productos de la colección
      const { data: productsData, error: productsError } = await supabase
        .from('collection_products')
        .select(`
          order,
          product:products!inner(
            id,
            name,
            description,
            base_price,
            main_image,
            stock,
            min_order,
            status,
            category:categories(
              name,
              slug
            )
          )
        `)
        .eq('collection_id', id)
        .eq('products.status', 'active')
        .order('order', { ascending: true });

      if (productsError) throw productsError;

      // Transformar los productos
      const transformedProducts = (productsData as CollectionProductResponse[]).map(item => ({
        ...item.product,
        category: item.product.category[0]
      })) as Product[];

      setProducts(transformedProducts.filter(product => product.status === 'active'));
    } catch (error) {
      console.error('Error fetching collection:', error);
      setError(error instanceof Error ? error.message : 'Error al cargar la colección');
      setCollection(null);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="animate-pulse space-y-6">
          <div className="flex justify-between items-center">
            <div className="h-10 bg-gray-200 rounded w-1/3"></div>
            <div className="h-8 bg-gray-200 rounded w-24"></div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <div key={i} className="bg-gray-100 rounded-xl overflow-hidden">
                <div className="h-40 bg-gray-200"></div>
                <div className="p-3 space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="h-3 bg-gray-200 rounded w-1/2"></div>
                  <div className="flex justify-between">
                    <div className="h-5 bg-gray-200 rounded w-1/4"></div>
                    <div className="h-5 bg-gray-200 rounded w-1/4"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (!collection || error) {
    return (
      <>
        <SEO
          title="Colección no encontrada | Disolgua"
          description="La colección que buscas no existe o no está disponible"
          url={`/see-more/${id}`}
          type="website"
        />
        
        <div className="max-w-7xl mx-auto px-4 py-16">
          <div className="text-center">
            <div className="inline-flex items-center justify-center w-16 h-16 bg-red-100 rounded-full mb-6">
              <AlertCircle className="w-8 h-8 text-red-600" />
            </div>
            <h2 className="text-2xl font-bold text-gray-900 mb-4">
              {error || 'Colección no encontrada'}
            </h2>
            <p className="text-gray-600 mb-8">
              La colección que buscas no existe o no está disponible en este momento.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
                Volver atrás
              </button>
              <Link
                to="/"
                className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Ir al inicio
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SEO
        title={`${collection.name} | Disolgua`}
        description={collection.description || `Explora nuestra colección ${collection.name} en Disolgua`}
        url={`/see-more/${collection.id}`}
        type="website"
      />

      <div className="max-w-7xl mx-auto px-4 py-8">
        {/* Header */}
        <div className="mb-8">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-4"
          >
            <ArrowLeft className="w-5 h-5" />
            Volver
          </button>
          <div className="flex flex-col gap-2">
            <h1 className="text-3xl font-bold text-gray-900">{collection.name}</h1>
            {collection.description && (
              <p className="text-gray-600">{collection.description}</p>
            )}
          </div>
          <div className="flex justify-end mt-4">
            <div className="flex items-center gap-2 bg-white rounded-lg border p-1">
              <button
                onClick={() => setView('grid')}
                className={`px-3 py-1.5 rounded flex items-center gap-2 ${
                  view === 'grid'
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <Grid className="w-4 h-4" />
                Cuadrícula
              </button>
              <button
                onClick={() => setView('list')}
                className={`px-3 py-1.5 rounded flex items-center gap-2 ${
                  view === 'list'
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <List className="w-4 h-4" />
                Lista
              </button>
            </div>
          </div>
        </div>

        {/* Products Grid/List */}
        {products.length === 0 ? (
          <div className="text-center py-16">
            <h3 className="text-xl font-medium text-gray-700 mb-2">No hay productos en esta colección</h3>
            <p className="text-gray-500">Vuelve más tarde para ver nuevos productos</p>
          </div>
        ) : view === 'grid' ? (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            {products.map((product) => (
              <Link
                key={product.id}
                to={`/product/${product.id}`}
                className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group"
              >
                <div className="relative">
                  <img
                    src={product.main_image}
                    alt={product.name}
                    className="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="p-4">
                  <div className="text-xs text-blue-600 font-medium mb-1">
                    {product.category?.name}
                  </div>
                  <h3 className="text-sm text-gray-900 font-medium line-clamp-2 group-hover:text-blue-600 transition-colors">
                    {product.name}
                  </h3>
                  <div className="mt-2 flex items-center justify-between">
                    {user && priceType ? (
                      <div className="bg-blue-50 p-2 rounded-lg w-full">
                        <div className="flex items-baseline justify-between">
                          <span className="font-semibold text-blue-600">
                            Q{productPrices[product.id]?.toFixed(2) || product.base_price.toFixed(2)}
                          </span>
                          <span className="text-xs font-medium text-gray-700">
                            C/U
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="bg-gray-50 p-2 rounded-lg text-center w-full">
                        <Link to="/login" className="text-xs text-blue-600 hover:text-blue-800 font-medium">
                          Regístrate para ver precios
                        </Link>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      // Aquí iría la lógica para añadir al carrito
                    }}
                    className="w-full mt-4 bg-blue-600 text-white px-3 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
                  >
                    <ShoppingCart className="w-4 h-4" />
                    Añadir al carrito
                  </button>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <div className="space-y-4">
            {products.map((product) => (
              <Link
                key={product.id}
                to={`/product/${product.id}`}
                className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300 group flex gap-6 p-4"
              >
                <div className="w-48 h-48 flex-shrink-0">
                  <img
                    src={product.main_image}
                    alt={product.name}
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
                <div className="flex-1 flex flex-col">
                  <div className="text-xs text-blue-600 font-medium mb-1">
                    {product.category?.name}
                  </div>
                  <h3 className="text-lg font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
                    {product.name}
                  </h3>
                  <p className="text-gray-600 mt-2">{product.description}</p>
                  <div className="mt-auto flex items-end justify-between">
                    {user && priceType ? (
                      <div className="bg-blue-50 p-2 rounded-lg">
                        <div className="flex items-baseline gap-2">
                          <span className="text-2xl font-semibold text-blue-600">
                            Q{productPrices[product.id]?.toFixed(2) || product.base_price.toFixed(2)}
                          </span>
                          <span className="text-sm font-medium text-gray-700">C/U</span>
                        </div>
                        {product.min_order > 1 && (
                          <p className="text-sm text-gray-500">
                            Pedido mínimo: {product.min_order} unidades
                          </p>
                        )}
                      </div>
                    ) : (
                      <div className="bg-gray-50 p-2 rounded-lg">
                        <Link to="/login" className="text-sm text-blue-600 hover:text-blue-800 font-medium">
                          Regístrate para ver precios
                        </Link>
                      </div>
                    )}
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        // Aquí iría la lógica para añadir al carrito
                      }}
                      className="bg-blue-600 text-white px-6 py-2 rounded-lg text-sm font-medium hover:bg-blue-700 transition-colors flex items-center gap-2"
                    >
                      <ShoppingCart className="w-4 h-4" />
                      Añadir al carrito
                    </button>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </>
  );
} 