import CategorySlider from '../components/CategorySlider';
import FeaturedProducts from '../components/FeaturedProducts';
import PopularProducts from '../components/NewProducts';
import AllCollections from '../components/AllCollections';
import SEO from '../components/SEO';
import Sliders from '../components/Sliders';  

export default function Home() {
  return (
    <>
      <SEO 
        title="Tienda" 
        description="Disolgua - Distribuidora del Sol Guatemala S.A. Somos tu socio mayorista de confianza. Ofrecemos peluches, tarjetas, globos y artículos para regalo a precios competitivos."
        keywords={['mayorista', 'Guatemala', 'peluches', 'tarjetas', 'globos', 'regalos', 'distribuidora', 'precios mayoristas']}
        url="/"
        type="website"
      />
     
<div className="w-full py-4 bg-yellow-400">
  <div className="max-w-7xl mx-auto px-4 space-y-8">
    <Sliders />
  </div>
</div>
      
      <CategorySlider />
      <div className="max-w-7xl mx-auto px-4 py-4 space-y-8">
        <div id="featured">
          <FeaturedProducts />
        </div>
        <div id="collections">
          <AllCollections />
        </div>
        <div id="new">
          <PopularProducts />
        </div>
      </div>
    </> 
  );
}