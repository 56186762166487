import { useState, useEffect } from 'react';
import React from 'react';
import { supabase, withRetry, withConnectionCheck } from '../lib/supabase';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { 
  Package, Truck, Clock, CheckCircle, XCircle, AlertCircle, Eye, 
  ShoppingBag, Calendar, CreditCard, MapPin, Filter, Search, ChevronDown, ArrowLeft,
  ShoppingCart
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface Order {
  id: string;
  created_at: string;
  total_amount: number;
  status: 'pending' | 'processing' | 'shipped' | 'delivered' | 'cancelled' | 'confirmed' | 'pending_payment' | 'paid';
  payment_status: 'pending' | 'paid' | 'failed' | 'refunded';
  payment_method: 'card' | 'cash' | 'twoCards';
  delivery_method: 'delivery' | 'pickup';
  shipping_address?: {
    first_name: string;
    last_name: string;
    address: string;
    city: string;
    region: string;
    phone: string;
  };
  items: {
    product_id: string;
    quantity: number;
    price: number;
    product: {
      name: string;
      main_image: string;
    };
  }[];
}

const statusColors = {
  pending: 'bg-yellow-100 text-yellow-800 border-yellow-200',
  processing: 'bg-blue-100 text-blue-800 border-blue-200',
  shipped: 'bg-purple-100 text-purple-800 border-purple-200',
  delivered: 'bg-green-100 text-green-800 border-green-200',
  cancelled: 'bg-red-100 text-red-800 border-red-200',
  confirmed: 'bg-blue-100 text-blue-800 border-blue-200',
  pending_payment: 'bg-orange-100 text-orange-800 border-orange-200',
  paid: 'bg-green-100 text-green-800 border-green-200'
};

const statusLabels = {
  pending: 'Pendiente Revisión',
  processing: 'En proceso',
  shipped: 'Enviado',
  delivered: 'Entregado',
  cancelled: 'Cancelado',
  confirmed: 'Confirmado',
  pending_payment: 'Pendiente Pago',
  paid: 'Pagado'
};

const statusIcons = {
  pending: Clock,
  processing: Package,
  shipped: Truck,
  delivered: CheckCircle,
  cancelled: XCircle,
  confirmed: CheckCircle,
  pending_payment: AlertCircle,
  paid: CheckCircle
};

type FilterStatus = 'all' | Order['status'];

export default function Orders() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState<FilterStatus>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    // Aplicar filtros cuando cambian los criterios
    let result = [...orders];
    
    // Filtrar por estado
    if (filterStatus !== 'all') {
      result = result.filter(order => order.status === filterStatus);
    }
    
    // Filtrar por término de búsqueda
    if (searchTerm.trim()) {
      const term = searchTerm.toLowerCase();
      result = result.filter(order => 
        order.id.toLowerCase().includes(term) || 
        order.items.some(item => item.product.name.toLowerCase().includes(term))
      );
    }
    
    setFilteredOrders(result);
  }, [orders, filterStatus, searchTerm]);

  const fetchOrders = async () => {
    try {
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;
      if (!user) {
        navigate('/login');
        return;
      }

      const { data, error } = await supabase
        .from('orders')
        .select(`
          id,
          created_at,
          total_amount,
          status,
          payment_status,
          payment_method,
          delivery_method,
          shipping_address,
          items:order_items(
            product_id,
            quantity,
            price,
            product:products(
              name,
              main_image
            )
          )
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      // Transformar los datos para que coincidan con la interfaz Order
      const transformedData = data?.map(order => ({
        ...order,
        items: order.items?.map(item => ({
          product_id: item.product_id,
          quantity: item.quantity,
          price: item.price,
          product: {
            name: item.product?.[0]?.name || '',
            main_image: item.product?.[0]?.main_image || ''
          }
        })) || []
      })) || [];

      setOrders(transformedData);
      setFilteredOrders(transformedData);
    } catch (err) {
      console.error('Error fetching orders:', err);
      setError('Error al cargar los pedidos');
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async (orderId: string) => {
    // Redirigir al usuario a la página de checkout con el ID del pedido
    navigate(`/order/${orderId}/payment`);
  };

  const getStatusCount = (status: Order['status']) => {
    return orders.filter(order => order.status === status).length;
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">Mis Pedidos</h1>
          <p className="text-gray-500 mb-6">Gestiona y revisa el estado de todos tus pedidos</p>
          
          {/* Barra de búsqueda y filtros */}
          <div className="flex flex-col md:flex-row gap-4 mb-6">
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Buscar por ID o producto..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-10 pr-4 py-2 w-full border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            
            <div className="relative">
              <button 
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center justify-between w-full md:w-48 px-4 py-2 bg-white border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50"
              >
                <div className="flex items-center">
                  <Filter className="h-4 w-4 mr-2" />
                  <span>
                    {filterStatus === 'all' ? 'Todos los estados' : statusLabels[filterStatus]}
                  </span>
                </div>
                <ChevronDown className="h-4 w-4" />
              </button>
              
              {isFilterOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-lg shadow-lg">
                  <ul className="py-1 max-h-60 overflow-auto">
                    <li 
                      className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center ${filterStatus === 'all' ? 'bg-blue-50 text-blue-700' : ''}`}
                      onClick={() => {
                        setFilterStatus('all');
                        setIsFilterOpen(false);
                      }}
                    >
                      <span>Todos</span>
                      <span className="bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded-full">
                        {orders.length}
                      </span>
                    </li>
                    {(Object.keys(statusLabels) as Array<Order['status']>).map(status => (
                      <li 
                        key={status}
                        className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center ${filterStatus === status ? 'bg-blue-50 text-blue-700' : ''}`}
                        onClick={() => {
                          setFilterStatus(status);
                          setIsFilterOpen(false);
                        }}
                      >
                        <div className="flex items-center">
                          {React.createElement(statusIcons[status], { className: "h-4 w-4 mr-2" })}
                          <span>{statusLabels[status]}</span>
                        </div>
                        <span className="bg-gray-100 text-gray-700 text-xs px-2 py-1 rounded-full">
                          {getStatusCount(status)}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-blue-500 mx-auto"></div>
            <p className="mt-4 text-gray-600">Cargando pedidos...</p>
          </div>
        ) : error ? (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded-lg">
            <p className="text-red-700 font-medium">Error</p>
            <p className="text-red-600">{error}</p>
            <button
              onClick={fetchOrders}
              className="mt-2 text-sm text-red-600 hover:text-red-700"
            >
              Intentar de nuevo
            </button>
          </div>
        ) : filteredOrders.length === 0 ? (
          <div className="text-center py-12">
            <ShoppingCart className="w-16 h-16 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium text-gray-900">No hay pedidos</h3>
            <p className="text-gray-500 mt-2">
              {searchTerm || filterStatus !== 'all'
                ? 'No se encontraron pedidos con los filtros seleccionados'
                : 'Aún no has realizado ningún pedido'}
            </p>
            {(searchTerm || filterStatus !== 'all') && (
              <button
                onClick={() => {
                  setSearchTerm('');
                  setFilterStatus('all');
                }}
                className="mt-4 text-blue-600 hover:text-blue-800"
              >
                Limpiar filtros
              </button>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredOrders.map((order) => (
              <div
                key={order.id}
                className="bg-white border rounded-lg shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="p-4">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        Pedido #{order.id.slice(0, 8)}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {format(new Date(order.created_at), "d 'de' MMMM, yyyy", { locale: es })}
                      </p>
                    </div>
                    <div className={`px-3 py-1 rounded-full text-sm font-medium ${statusColors[order.status]}`}>
                      {statusLabels[order.status]}
                    </div>
                  </div>

                  <div className="space-y-3">
                    {order.items.slice(0, 2).map((item) => (
                      <div key={item.product_id} className="flex items-center space-x-3">
                        <img
                          src={item.product.main_image}
                          alt={item.product.name}
                          className="w-12 h-12 object-cover rounded"
                          onError={(e) => {
                            e.currentTarget.src = '/placeholder-image.jpg';
                          }}
                        />
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {item.product.name}
                          </p>
                          <p className="text-sm text-gray-500">
                            Cantidad: {item.quantity}
                          </p>
                        </div>
                        <div className="text-sm font-medium text-gray-900">
                          Q{(item.price * item.quantity).toFixed(2)}
                        </div>
                      </div>
                    ))}
                    {order.items.length > 2 && (
                      <p className="text-sm text-gray-500 pt-2">
                        Y {order.items.length - 2} productos más...
                      </p>
                    )}
                  </div>

                  <div className="mt-4 pt-4 border-t border-gray-200">
                    <div className="flex items-center justify-between">
                      <div className="text-gray-500 text-sm">Total</div>
                      <div className="text-lg font-medium text-gray-900">
                        Q{order.total_amount.toFixed(2)}
                      </div>
                    </div>

                    <div className="mt-4 flex items-center justify-end gap-3">
                      {order.status === 'pending_payment' && (
                        <button
                          onClick={() => handlePayment(order.id)}
                          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
                        >
                          Pagar ahora
                        </button>
                      )}
                      <button
                        onClick={() => navigate(`/order/${order.id}`)}
                        className="flex items-center gap-2 text-blue-600 hover:text-blue-800 transition-colors"
                      >
                        <Eye className="w-4 h-4" />
                        Ver detalles
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modal de detalles del pedido */}
      {isModalOpen && selectedOrder && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white rounded-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white z-10 p-4 border-b flex items-center">
              <button
                onClick={() => {
                  setSelectedOrder(null);
                  setIsModalOpen(false);
                }}
                className="mr-3 text-gray-500 hover:text-gray-700"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <h3 className="text-lg font-semibold flex-1">
                Detalles del Pedido
              </h3>
              <span className={`inline-flex items-center gap-1 px-3 py-1 rounded-full text-xs font-medium border ${statusColors[selectedOrder.status]}`}>
                {React.createElement(statusIcons[selectedOrder.status], { className: "w-3.5 h-3.5" })}
                {statusLabels[selectedOrder.status]}
              </span>
            </div>
            
            <div className="p-6">
              <div className="bg-gray-50 rounded-xl p-4 mb-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 mb-1">Número de Pedido</h4>
                    <p className="font-medium">{selectedOrder.id}</p>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 mb-1">Fecha</h4>
                    <p>{format(new Date(selectedOrder.created_at), "d 'de' MMMM, yyyy", { locale: es })}</p>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 mb-1">Método de Pago</h4>
                    <p>{selectedOrder.payment_method === 'card' ? 'Tarjeta' : 
                        selectedOrder.payment_method === 'cash' ? 'Efectivo' : 'Dos tarjetas'}</p>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-500 mb-1">Estado de Pago</h4>
                    <p>{selectedOrder.payment_status === 'pending' ? 'Pendiente' : 
                        selectedOrder.payment_status === 'paid' ? 'Pagado' : 
                        selectedOrder.payment_status === 'failed' ? 'Fallido' : 'Reembolsado'}</p>
                  </div>
                </div>
              </div>

              {selectedOrder.shipping_address && (
                <div className="bg-gray-50 rounded-xl p-4 mb-6">
                  <h4 className="font-medium mb-3 flex items-center">
                    <MapPin className="w-4 h-4 mr-2" />
                    Dirección de envío
                  </h4>
                  <div className="text-sm text-gray-700">
                    <p className="font-medium">{selectedOrder.shipping_address.first_name} {selectedOrder.shipping_address.last_name}</p>
                    <p className="mt-1">{selectedOrder.shipping_address.address}</p>
                    <p>{selectedOrder.shipping_address.city}, {selectedOrder.shipping_address.region}</p>
                    <p className="mt-1">{selectedOrder.shipping_address.phone}</p>
                  </div>
                </div>
              )}

              <div className="mb-6">
                <h4 className="font-medium mb-4 flex items-center">
                  <ShoppingBag className="w-4 h-4 mr-2" />
                  Productos ({selectedOrder.items.length})
                </h4>
                <div className="bg-gray-50 rounded-xl overflow-hidden">
                  <div className="divide-y divide-gray-200">
                    {selectedOrder.items.map((item) => (
                      <div key={item.product_id} className="flex items-center gap-4 p-4">
                        <img
                          src={item.product.main_image}
                          alt={item.product.name}
                          className="w-16 h-16 object-cover rounded-lg"
                        />
                        <div className="flex-1">
                          <h5 className="font-medium">{item.product.name}</h5>
                          <div className="flex justify-between mt-1">
                            <p className="text-sm text-gray-600">
                              {item.quantity} x Q {item.price.toFixed(2)}
                            </p>
                            <p className="font-medium">
                              Q {(item.price * item.quantity).toFixed(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="bg-white p-4 border-t">
                    <div className="flex justify-between items-center">
                      <span className="font-medium">Total</span>
                      <span className="text-xl font-bold">Q {selectedOrder.total_amount.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
              </div>

              {selectedOrder.status === 'confirmed' && selectedOrder.payment_status === 'pending' && (
                <div className="bg-yellow-50 border border-yellow-200 rounded-xl p-4 mb-6">
                  <div className="flex items-start">
                    <AlertCircle className="w-5 h-5 text-yellow-600 mr-3 mt-0.5" />
                    <div>
                      <h4 className="font-medium text-yellow-800">Pendiente de pago</h4>
                      <p className="text-yellow-700 text-sm mt-1">
                        Tu pedido ha sido confirmado y está listo para pago. Una vez realizado el pago, comenzaremos a procesar tu pedido.
                      </p>
                      <button
                        onClick={() => handlePayment(selectedOrder.id)}
                        className="mt-4 w-full bg-yellow-600 text-white py-3 rounded-lg font-medium hover:bg-yellow-700 transition-colors"
                      >
                        <div className="flex items-center justify-center">
                          <ShoppingCart className="w-5 h-5 mr-2" />
                          Proceder al Checkout - Q{selectedOrder.total_amount.toFixed(2)}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setSelectedOrder(null);
                    setIsModalOpen(false);
                  }}
                  className="px-6 py-2.5 border border-gray-300 rounded-lg hover:bg-gray-50 transition-colors"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 