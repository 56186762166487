import { Helmet } from 'react-helmet-async';
import { Clock, Facebook, Phone, MessageCircle, ShoppingBag } from 'lucide-react';

export default function ComingSoon() {
  return (
    <>
      <Helmet>
        <title>Disolgua - Distribuidora de Soluciones de Guatemala</title>
        <meta name="description" content="Disolgua es tu socio mayorista de confianza en Guatemala. Estamos trabajando en nuestro nuevo sitio web. Contáctanos por WhatsApp al 4739-1242 para realizar tus pedidos." />
        <meta name="keywords" content="disolgua, distribuidora, mayorista, guatemala, productos, pedidos, whatsapp" />
        <meta name="robots" content="index, follow" />
        
        {/* Metadatos estructurados para Google */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Disolgua",
              "description": "Distribuidora del Sol Guatemala S.A - Los mejores productos para tu negocio",
              "url": "https://disolgua.com",
              "logo": "https://disolgua.com/assets/DISOLGUA.png",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+50247391242",
                "contactType": "customer service",
                "availableLanguage": "Spanish"
              },
              "sameAs": [
                "https://facebook.com/disolgua"
               
              ]
            }
          `}
        </script>
      </Helmet>
      
      <div className="min-h-screen flex flex-col">
        <div className="flex-1 flex flex-col md:flex-row">
          {/* Sección izquierda para móvil */}
          <div className="md:hidden h-64 bg-blue-600 relative overflow-hidden">
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="bg-white p-4 rounded-xl shadow-lg">
                <img src="/assets/DISOLGUA.png" alt="Disolgua - Distribuidora de Soluciones de Guatemala" className="h-16 object-contain" />
              </div>
            </div>
          </div>
          
          {/* Sección izquierda para desktop */}
          <div className="hidden md:flex md:w-1/2 bg-blue-600 relative items-center justify-center">
            <div className="bg-white p-8 rounded-xl shadow-lg">
              <img src="/assets/DISOLGUA.png" alt="Disolgua - Distribuidora de Soluciones de Guatemala" className="h-32 object-contain" />
            </div>
          </div>
          
          {/* Sección derecha (contenido) */}
          <div className="md:w-1/2 flex flex-col justify-center px-6 py-12 md:py-0 md:px-12 lg:px-24">
            <div className="max-w-md mx-auto">
              <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                Estamos trabajando en algo increíble
              </h1>
              
              <p className="text-lg text-gray-600 mb-8">
                Nuestro nuevo sitio está en desarrollo. Pronto tendremos novedades para ti.
              </p>
              
              <div className="flex items-center gap-2 text-gray-600 mb-6">
                <Clock className="w-5 h-5 text-blue-600" />
                <span>Lanzamiento estimado: Próximamente</span>
              </div>
              
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-3 flex items-center">
                  <MessageCircle className="w-5 h-5 text-blue-600 mr-2" />
                  ¿Necesitas hacer un pedido?
                </h2>
                <p className="text-gray-600 mb-4">
                  Mientras nuestro sitio está en construcción, puedes contactarnos directamente para realizar tus pedidos:
                </p>
                <a 
                  href="https://wa.me/50247391242" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 bg-green-500 hover:bg-green-600 text-white font-medium py-3 px-6 rounded-lg transition-colors w-full justify-center mb-4"
                  aria-label="Contactar por WhatsApp al 4739-1242"
                >
                  <Phone className="w-5 h-5" />
                  Contáctanos por WhatsApp: 4739-1242
                </a>
              </div>
              
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 mb-8">
                <h2 className="text-xl font-semibold text-gray-900 mb-3 flex items-center">
                  <ShoppingBag className="w-5 h-5 text-blue-600 mr-2" />
                  Explora nuestros catálogos
                </h2>
                <p className="text-gray-600 mb-4">
                  Visita nuestra página de Facebook para ver nuestros productos y catálogos:
                </p>
                <a 
                  href="https://facebook.com/disolgua" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 bg-[#1877F2] hover:bg-[#0e6eeb] text-white font-medium py-3 px-6 rounded-lg transition-all transform hover:scale-105 w-full justify-center shadow-md"
                  aria-label="Visitar nuestra página de Facebook"
                >
                  <Facebook className="w-6 h-6" />
                  <span className="text-lg">Ver catálogos en Facebook</span>
                </a>
              </div>
              
              
            </div>
          </div>
        </div>
        
        <footer className="bg-white py-6 border-t">
          <div className="container mx-auto px-6">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <p className="text-gray-600 text-sm">
                &copy; {new Date().getFullYear()} Distribuidora del Sol Guatemala S.A. Todos los derechos reservados.
              </p>
              <div className="mt-4 md:mt-0">
                <div className="flex items-center gap-4">
                  <a href="mailto:info@disolgua.com" className="text-sm text-gray-600 hover:text-blue-600" aria-label="Enviar correo a info@disolgua.com">
                    info@disolgua.com
                  </a>
                  <a href="https://wa.me/50247391242" target="_blank" rel="noopener noreferrer" className="text-sm text-gray-600 hover:text-green-600 flex items-center" aria-label="Contactar por WhatsApp al 4739-1242">
                    <Phone className="w-4 h-4 mr-1" />
                    4739-1242
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
} 