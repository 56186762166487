import { Helmet } from 'react-helmet-async';

export default function Terms() {
  return (
    <div className="container mx-auto px-4 py-12 max-w-4xl">
      <Helmet>
        <title>Términos y Condiciones | Disolgua</title>
        <meta name="description" content="Términos y condiciones de Disolgua" />
      </Helmet>
      
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Términos y Condiciones</h1>
      
      <div className="prose prose-lg max-w-none">
        <p className="mb-4">
          Bienvenido a Disolgua. Al acceder y utilizar nuestro sitio web, usted acepta cumplir con estos términos y condiciones.
          Por favor, léalos cuidadosamente antes de utilizar nuestros servicios.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">1. Aceptación de los términos</h2>
        <p className="mb-4">
          Al acceder y utilizar este sitio web, usted acepta estar legalmente vinculado por estos términos y condiciones.
          Si no está de acuerdo con alguno de estos términos, no debe utilizar este sitio.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">2. Uso del sitio</h2>
        <p className="mb-4">
          Usted acepta utilizar este sitio solo para fines legales y de una manera que no infrinja los derechos de otros
          o restrinja o inhiba el uso y disfrute del sitio por parte de cualquier tercero.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">3. Cuentas de usuario</h2>
        <p className="mb-4">
          Para realizar compras en nuestro sitio, es posible que deba crear una cuenta. Usted es responsable de mantener
          la confidencialidad de su cuenta y contraseña, y acepta la responsabilidad de todas las actividades que ocurran
          bajo su cuenta.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">4. Productos y precios</h2>
        <p className="mb-4">
          Nos esforzamos por mostrar con precisión los colores y características de nuestros productos. Sin embargo, no
          podemos garantizar que la visualización de cualquier color en su monitor sea precisa.
        </p>
        <p className="mb-4">
          Todos los precios están sujetos a cambios sin previo aviso. Nos reservamos el derecho de modificar o descontinuar
          cualquier producto sin previo aviso.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">5. Pedidos y pagos</h2>
        <p className="mb-4">
          Al realizar un pedido, usted declara que toda la información proporcionada es precisa y completa. Nos reservamos
          el derecho de rechazar o cancelar cualquier pedido por cualquier motivo.
        </p>
        <p className="mb-4">
          Aceptamos varios métodos de pago, como se indica en nuestro sitio web. Todos los pagos deben realizarse en la
          moneda especificada en el momento de la compra.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">6. Envíos y entregas</h2>
        <p className="mb-4">
          Los tiempos de entrega son estimados y no están garantizados. No somos responsables de los retrasos causados
          por circunstancias fuera de nuestro control.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">7. Devoluciones y reembolsos</h2>
        <p className="mb-4">
          Consulte nuestra Política de Devoluciones para obtener información sobre cómo devolver productos y recibir reembolsos.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">8. Propiedad intelectual</h2>
        <p className="mb-4">
          Todo el contenido de este sitio, incluyendo texto, gráficos, logotipos, imágenes y software, está protegido por
          derechos de autor y es propiedad de Disolgua o de sus proveedores de contenido.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">9. Limitación de responsabilidad</h2>
        <p className="mb-4">
          En ningún caso Disolgua será responsable por daños directos, indirectos, incidentales, especiales o consecuentes
          que resulten del uso o la imposibilidad de usar nuestros productos o servicios.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">10. Cambios a estos términos</h2>
        <p className="mb-4">
          Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Los cambios entrarán
          en vigor inmediatamente después de su publicación en el sitio.
        </p>

        <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-4">11. Contacto</h2>
        <p className="mb-4">
          Si tiene alguna pregunta sobre estos Términos y Condiciones, póngase en contacto con nosotros en:
        </p>
        <p className="mb-4">
          <strong>Correo electrónico:</strong> info@disolgua.com<br />
          <strong>Teléfono:</strong> 2238-0714
        </p>
      </div>
    </div>
  );
} 