import { useState } from 'react';
import AdminLayout from '../layouts/AdminLayout';
import ViewOrder from './ViewOrder';

// Definimos el tipo de las pestañas disponibles en el AdminLayout
type TabId = 'dashboard' | 'pos' | 'products' | 'categories' | 'collections' | 'users' | 'orders' | 'settings' | 'sliders';

export default function AdminOrderView() {
  const [activeTab, setActiveTab] = useState<TabId>('orders');
  
  return (
    <AdminLayout activeTab={activeTab} onTabChange={setActiveTab}>
      <ViewOrder />
    </AdminLayout>
  );
} 